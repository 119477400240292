export const FieldsCharacterMonster = [
    {name: 'Name', value: '', order: 0, heading: '', custom: false, simple: true},
    {name: 'Monster Type', value: '', order: 0, heading: 'Basic', simple:true,custom: false, 
      dropDownOptions: ['Beast','Elemental','Spectral','Undead','Aquatic','Insectoid','Reptilian','Avian','Mammalian','Plant-like','Fungal','Crystalline','Gaseous','Rock Formation','Amorphous','Arachnid','Mechanical','Energy Being','Parasitic','Symbiotic','Hybrid','Microscopic','Shapeshifter','Illusionary','Construct','Magical Entity','Alien','Interdimensional','Celestial','Infernal']
    },
    {name: 'Monster Function', value: '', order: 0, heading: 'Basic',simple:true, custom: false, 
      dropDownOptions: ['Guardian','Predator','Scavenger','Symbol','Terrorizer','Lurker','Wanderer','Collector','Destroyer','Seeker','Servant','Trickster','Builder','Symbiont','Parasite','Harbinger','Shapechanger','Migrator','Hibernator','Cleaner','Healer','Illusionist','Watcher','Weaver','Dancer','Controller','Sower','Reaper','Portal Keeper','Puzzle']
    },
    {name: 'Behavior Pattern', value: '', order: 0, heading: 'Basic', custom: false,simple:true, 
      dropDownOptions: ['Aggressive','Passive','Erratic','Predictable','Reclusive','Social','Territorial','Nomadic','Curious','Fearful','Playful','Cunning','Ruthless','Defensive','Obsessive','Cooperative','Sedentary','Manipulative','Mournful','Regenerative','Camouflaging','Mimicking','Ritualistic','Stalking','Frenzied','Patient','Greedy','Proud','Vengeful','Honorable']
    },
    {name: 'Form', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Quadruped','Bipedal','Serpentine','Radial Symmetry','Asymmetrical','Winged','Multi-limbed','Single-limbed','No-limbed','Tentacled','Tailed','Finned','Scaled','Segmented','Coiled','Compact','Long and Slender','Bulbous','Flat','Oval','Spherical','Cuboidal','Spiral','Helical','Folded','Layered','Branched','Caged ','Fractal','Complex']
    },
    {name: 'Size', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Microscopic','Minuscule','Tiny','Petite','Small','Compact','Medium','Moderate','Average','Large','Grand','Huge','Giant','Massive','Enormous','Colossal','Monumental','Gigantic','Titanic','Immense','Vast','Broad','Wide','Bulky','Thick','Spacious','Expansive','Infinite','Miniature','Substantial']
    },
    {name: 'Appearance', value: '', order: 0, heading: 'Basic', custom: false, simple: true, 
      dropDownOptions: ['Spiny','Feathered','Slimy','Translucent','Striped','Polka-Dotted','Bioluminescent','Metallic','Hairy','Furless','Shaggy','Patchy','Horned','Tusked','One-Eyed','Multicolored','Vibrant','Dull','Iridescent','Scaled','Wrinkled','Smooth','Jagged','Cracked','Barbed','Tentacled','Beaked','Glowing','Pockmarked','Winged']
    },
    {name: 'Abilities', value: '', order: 0, heading: 'Traits', custom: false, simple: true, 
      dropDownOptions: ['Fire Breathing','Invisibility','Teleportation','Acidic Spit','Regeneration','Mind Control','Paralyzing Stare','Sonic Scream','Poisonous Bite','Electromagnetic Pulse','Shape-shifting','Time Manipulation','Gravity Control','Illusion Casting','Ice Generation','Healing Others','Energy Absorption','Phasing through Objects','Reflecting Attacks','Water Manipulation','Earthquake Generation','Wind Control','Radiation Emission','Rapid Burrowing','Hypnosis','Necromancy','Mimicry','Light Manipulation','Petrifying Gaze','Summoning Other Creatures']
    },
    {name: 'Weakness', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Sunlight','Fire','Cold/Ice','Silver','Iron','Water','Specific Sound Frequencies','Salt','Garlic','Holy Symbols','Electricity','Magic','Certain Herbs or Spices','Gold','Reflective Surfaces','Bright Lights','Specific Animal Presence','Sonic Vibrations','Acid','Specific Gemstones','Color Spectrum','High Pressure','Enchanted Weapons','Specific Time of Day or Year','Poison','Specific Animal Attacks','Illusion','Specific Moon Phases','Specific Plants','Certain Rituals or Spells']
    },
    {name: 'Hunting Habits', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Stalks Prey Silently','Hunts in Packs','Uses Camouflage','Ambushes from Above','Digs Traps','Lures Prey with Sounds or Lights','Mimics Prey\'s Mating Call','Paralyzes Prey with Venom','Uses Tidal Waves or Water Currents','Creates Illusions to Confuse Prey','Hunts Only at Night','Hunts Only During Specific Seasons','Follows Migratory Patterns of Prey','Employs Psychic Manipulation','Uses Wind or Air Currents to Attack','Builds Intricate Webs or Structures','Relies on Teamwork with Other Species','Drives Prey into Predetermined Traps','Only Hunts Specific Types of Prey','Employs Tools or Weapons','Relies on Speed and Agility','Relies on Brute Strength and Size','Uses Tail or Tentacles to Grasp Prey','Uses Symbiotic Relationships to Hunt','Adapts to Prey\'s Tactics Over Time','Infects Prey with Parasites','Utilizes Environmental Hazards','Creates Barriers to Corner Prey','Utilizes Extreme Temperatures','Manipulates Prey\'s Emotions or Fear']
    },
    {name: 'Feeding Method', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Swallows Prey Whole','Drains Liquids ','Consumes Energy or Emotions','Chews with Powerful Jaws','Filters Small Organisms from Water','Absorbs Nutrients through Skin','Sucks Prey into a Vortex','Uses Tentacles to Grasp and Eat','Dissolves Prey with Acidic Secretions','Detaches Body Parts to Consume','Eats Only Specific Organs or Parts','Consumes Through Osmosis','Uses Long Tongue to Snatch Prey','Envelops and Crushes Prey','Eats Prey\'s Memories or Thoughts','Consumes Prey\'s Soul or Essence','Feeds on Microscopic Organisms','Uses Probing Beak or Proboscis','Breaks Prey Down into Slurry','Ferments Prey Before Eating','Stores Prey for Later Consumption','Shares Prey with Symbiotic Partner','Utilizes Photosynthesis','Feeds on Raw Elements or Minerals','Only Consumes Decaying Matter','Uses Heat to Cook Prey','Freezes Prey Before Consuming','Rips Prey Apart with Claws','Feeds Off Prey\'s Fear or Pain','Eats Through Regurgitation and Reconsumption']
    },
    {name: 'Origin', value: '', order: 0, heading: 'Background', custom: false, simple: true, 
      dropDownOptions: ['Born from a Dark Ritual','Created by a Mad Scientist','Result of a Failed Experiment','Spawned from a Cosmic Event','Manifestation of Collective Fear','Formed from Industrial Pollution','Cursed by a Vengeful Spirit','A Product of Alien Technology','Birthed from an Ancient Prophecy','Constructed as a Magical Guardian','Result of a Genetic Mutation','Emerged from a Dream or Nightmare','Created as a Divine Punishment','Formed from Uncontrolled Magic','Evolved in Isolated Ecosystem','Born from the Fusion of Entities','A Consequence of Time Travel','Spawned from a Meteorite Impact','Created for Gladiatorial Combat','Resurrected by Necromantic Powers','A Byproduct of Planar Convergence','Manifested from Art or Literature','Result of a Natural Disaster','Born from Chaos or Entropy','Constructed as a Military Weapon','Emerged from Virtual Reality','A Reflection of Societal Ills','Formed by a Celestial Alignment','Created as a Philosophical Concept','Spawned from the Depths of the Mind']
    },
    {name: 'Habitat', value: '', order: 0, heading: 'Background', custom: false , simple: true,
      dropDownOptions: ['Mountain Range','River','Lake','Valley','Canyon','Plateau','Fjord','Ocean','Sea','Bay','Peninsula','Isthmus','Volcano','Glacier','Waterfall','Delta','Cave','Archipelago','Island','Oasis','Cliff','Hill','Mesa','Butte','Gorge','Marine Trench','Atoll','Marshland','Sand Dunes','Lagoon','Rainforest','Prairie','Steppe','Moor','Foothills','Taiga','Tundra','Savanna','Wetlands','Coral Reef','Alpine Meadows','Ice Fields','Badlands','Geothermal Springs','Salt Flat','Karst Landscape','Moraine','Mangrove','Terrace','Highland','Peatland','Chaparral','Swampland','Fen','Pampas','Sandstone Formations','Grasslands','Bog','Tide Pools','Estuary','Mudflat','Crag','Ravine','Jungle','Forest','Pine Barrens','Bamboo Forest','Quarry','Desert','Woodland','Scrubland','Subterranean Network','Plateau Basin','Wind-Scarred Plains','Floodplain','Hot Springs','Crater Lake','Meandering River','Redwood Forest','Arctic Ice Shelf','Cloud Forest','Sulphur Springs','Sinkhole','Limestone Caves','Underground Lake','Sunken City','Old Growth Forest','Volcanic Island','Scree Slope','Crevasse']
    },
    {name: 'Species interactions', value: '', order: 0, heading: 'Additional Details', custom: false, 
      dropDownOptions: ['Predatory','Symbiotic','Parasitic','Mutualistic','Territorial Aggression','Mimicry','Competitive','Commensalistic','Scavenging','Migratory Following','Interspecies Breeding','Opportunistic Feeding','Alarm Signaling','Indifferent','Alluring','Deceptive','Co-hunting','Nest Parasitism','Cooperative Sheltering','Resource Guarding','Disease Spreading','Protective Mimicry','Submissive','Dominant','Cleansing ','Nocturnal Avoidance','Signal Jamming','Trophic Cascade Influencer','Social Parasitism','Epiphytic']
    },
    {name: 'Known Encounters', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Village Raid','Forest Ambush','Mountain Sighting','Desert Stalk','Ocean Attack','Cave Discovery','River Confrontation','Shipwreck Inducing','Abandoned Structure Haunting','Urban Encounter','Marshland Lurking','Farmland Infestation','Highway Hijacking','Tundra Encounter','Jungle Pursuit','Battlefield Appearance','Volcanic Activity','Sky Soaring Sighting','Lake Emergence','Island Isolation','Catacombs Exploration','Caravan Assault','Sacred Ground Desecration','Festive Event Disturbance','Mine Collapsing','Space Station Invasion','Virtual Reality Intrusion','Iceberg Dwelling','Sewer Labyrinth Encounter','Laboratory Escaping Incident']
    },
    {name: 'Restrictions', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Blindness','Flightless','Mute','Solitary','Burrowing-Only','Deafness','Vulnerable Without Pack','Cannot Cross Running Water','Fixed Lifespan','Night-Blindness','Needs Regular Shedding','Temperature-Sensitive Skin','Sensitive to Specific Frequencies','Requires Frequent Molting','Sedentary','Territorial','Averse to Open Spaces','Confined to Specific Depth','Diurnal','Requires Hibernation','Weak or Ineffective Bite','Lethargic After Feeding','Non-Aggressive During Breeding Season','Susceptible to Certain Pheromones','Sluggish in Cold Temperatures','Limited Diet','Can\'t Swim','Ineffective Claws','Requires Specific Breeding Ground','No Natural Weapons']
    },
    {name: 'Unusual Behaviors', value: '', order: 0, heading: 'Additional Details', custom: false, 
      dropDownOptions: ['Mimics Human Voices','Constructs Intricate Nests','Produces Bioluminescent Signals','Hibernates in Unlikely Places','Creates Artistic Markings','Forms Symbiotic Relationships','Uses Tools','Engages in Ritualistic Dancing','Sings or Humming Melodies','Absorbs Environmental Elements','Releases Spores for Communication','Exhibits Compassionate Behavior','Changes Color Unexpectedly','Forms Unusual Mating Bonds','Develops Temporary Wings','Moves in Geometric Paths','Builds Traps for Predators','Thrives in Extreme Conditions','Displays Symbols on Body','Can Voluntarily Shed Parts','Aligns to Celestial Bodies','Communicates Through Echolocation','Stores Objects','Creates Illusions','Engages in Playful Behavior','Exhibits Seasonal Transformations','Follows Human-Made Roads','Repels Specific Materials','Forms Bonds with Humans','Possesses Telepathic Abilities']
    },
    {name: 'Surrounding Impact', value: '', order: 0, heading: 'Additional Details', custom: false, 
      dropDownOptions: ['Corrodes Metal Surfaces','Causes Vegetation to Wither','Alters Weather Patterns','Generates Electromagnetic Field','Warps Light','Induces Fear in Animals','Stimulates Plant Growth','Affects Gravity','Emits Radioactive Energy','Produces Sound','Leaves Glowing Marks','Distorts Water Reflections','Changes Soil Composition','Alters Temperature','Creates Fog','Causes Erosion','Forms Crystals','Leaves Unique Footprints','Emits Odor','Creates Illusions','Induces Hallucinations','Alters Water Currents','Causes Rocks to Float','Dampens Sound','Draws Insects','Generates Wind','Influences Dreams','Changes Color of Surfaces','Induces Decay','Forms Ice in Warm Areas']
    },
    {name: 'Signs of Presence', value: '', order: 0, heading: 'Additional Details', custom: false, simple: true, 
      dropDownOptions: ['Unusual Sounds','Foul Odor','Unnatural Cold','Distorted Shadows','Mysterious Footprints','Dead Vegetation','Glowing Marks','Abnormal Animal Behavior','Unexpected Fog','Sudden Temperature Change','Discolored Water','Strange Reflections','Unexplained Erosion','Residual Magical Energy','Air Pressure Fluctuations','Intense Dread','Small Animals Disappearance','Vivid Nightmares','Electromagnetic Disturbances','Unusual Silence','Crystalline Formations','Weather Patterns Changes','Altered Gravity','Static Electricity','Unusual Ice Formation','Visible Aura','Sudden Wind Gusts','Muted Colors','Repeating Echoes','Frequent Lightning']
    },
    {name: 'Cultural Importance', value: '', order: 0, heading: 'Additional Details', custom: false, simple: true, 
      dropDownOptions: ['Symbol of Fear','Religious Icon','Guardian of Sacred Places','Emblem of a Clan or Tribe','Harbinger of Doom','Representation of Virtue','National Symbol','Metaphor for Evil','Symbol of Rebellion','Source of Artistic Inspiration','Ancestral Spirit','Subject of Legend and Myth','Warning or Moral Tale','Fertility Symbol','Seasonal Festival Figure','Tourist Attraction','Patron of a Specific Occupation','Symbol of Good Luck','Educational Tool','Subject of Rituals','Mascot for Sports Teams','Children\'s Story Character','Representation of Natural Forces','Part of Traditional Medicine','Military Symbol','Figure in Astrology','Object of Superstitions','Cult Worship Focus','Sign of Political Power','Figure of Local Folklore']
    },
    {name: 'Additional Details', value: '', order: 0, heading: 'Additional Details', custom: false},
  ];
