export const FieldsLocationMicroWrestlingArena = [
    {name: 'Name', value: '', order: 0, simple:true, heading: '', custom: false},
    {name: 'Arena Location', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ['City','Small Town','Suburban Area','Coastal Resort','College Campus','Entertainment District','Sports Complex','Convention Center','Rural Community','Amusement Park','Casino Resort','Shopping Mall','Stadium','Industrial Area','Historic District','Tourist Destination','Waterfront Pier','Mountain Resort','Military Base','Theme Park','Fairgrounds','Underground Bunker','Warehouse District','Island Retreat','Resort Town','Ski Resort','Desolate Wasteland','Underground Sewer System','Abandoned Building','Forest Clearing']
    },
    {name: 'Arena Size', value: '', simple:true, order: 0, heading: 'Basic', custom: false,
      dropDownOptions: ['Small Club Venue','Intimate Hall','Medium-sized Arena','School Gymnasium','Community Center','Convention Center Ballroom','Amphitheater','Theater Venue','Stadium Annex','Sports Complex Arena','Entertainment Venue','Casino Ballroom','Exhibition Hall','Warehouse Space','Underground Arena','Outdoor Arena','College Auditorium','Fairgrounds Arena','Multi-Purpose Arena','Civic Center Arena','Arena with Upper Deck Seating','Resort Ballroom','Studio Soundstage','Historic Theater','Ice Rink Arena','Stadium Field with Temporary Seating','Converted Hangar','School Cafeteria','Nightclub Venue','Open-air Amphitheater']
    },
    {name: 'Ring Style', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ['Classic Canvas Ring','Raised Platform Ring','Custom Logo Mat Ring','LED Ring','Enclosed Cage Ring','Open-Air Ring','Elevated Stage Ring','Themed Ring ','Padded Floor Ring','Glass Floor Ring','Floating Ring','Multi-Level Ring','Soft Foam Ring','Interactive LED Ring','Trampoline Ring','Elevated Ramp Ring','Octagonal Ring','Tug-of-War Ring','Inflatable Ring','Rock \'n\' Roll Ring ','All-Steel Ring','Miniature Ring','Futuristic Ring Design','Water Ring ','Electric Ring','Jungle Ring','Steampunk Ring ','Ice Ring ','Digital Holographic Ring','Virtual Reality Ring']
    },
    {name: 'Arena Lighting', value: '', simple:false, order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: ['Overhead Spotlighting','Colored LED Wash Lighting','Strobe Lights','Laser Light Show','Follow Spotlights','Ring-Side Floor Lighting','Ring-Corner Spotlights','Dimmed Ambient Lighting','Programmable Light Panels','Moving Head Lights','Backlighting Effects','Lighted Entrance Ramp','Flashing Lights','Spotlight Rings','Sequential Lighting Patterns','Black Lights (UV)','Truss Lighting Rig','Fog and Haze Effects','Interactive Light Displays','Interactive Audience Lighting','Flame Effects Lighting','Projection Mapping','Chaser Lights','Fireworks Lighting Effects','Neon Glow Lighting','Remote-Controlled Lighting','Gobo Lighting Patterns','Color Changing Lights','Fiber Optic Lighting','Wall Wash Lighting']
    },
    {name: 'Ring Appearance', value: '', order: 0, heading: 'Structural & Design Aspects', custom: false, 
      dropDownOptions: ['Sleek and Metallic','Classic Red, White, and Blue','Futuristic Neon Grid','Mystical Enchanted Forest','Fiery Inferno Theme','Ice-Cold Glacier Setting','Urban Graffiti Style','Underwater Atlantis Theme','Electric Circuit Pattern','Skulls and Bones Motif','Heavenly Cloudscape Backdrop','Camouflage Jungle Design','Starry Night Sky','Tribal Warrior Artwork','Industrial Steampunk Vibe','Retro Arcade Pixel Art','Egyptian Hieroglyphic Symbols','Space Odyssey Cosmic Theme','Medieval Castle Aesthetic','Cyberpunk Neon Cityscape','Haunted Cemetery Setting','Rainbow-Colored Bliss','Wild West Frontier Theme','Sci-Fi Spaceship Interior','Pop Art Explosion','Galactic Universe Backdrop','Comic Book Superhero Illustrations','Ancient Mayan Ruins','Pixelated Video Game Graphics','Glittering Disco Ball Effect']
    },
    {name: 'Entrance Ramp', value: '', simple:false, order: 0, heading: 'Structural & Design Aspects', custom: false, 
      dropDownOptions: ['Epic Fireworks-Lined Catwalk','Glowing Neon Archway','Smoke-Filled Runway','Sparkling Crystal Staircase','Laser-Lit Pathway','Vibrant LED Tunnel','Floating Cloud Walkway','Steampunk Gear-Powered Ramp','Mystical Fog-Covered Bridge','Dazzling Mirror-Lined Ramp','Jungle Vine-Covered Stairway','Retro Arcade-Style Entrance Path','Glistening Waterfall Bridge','Cybernetic Lighted Walkway','Gothic Cathedral-Inspired Ramp','Celestial Stairway to Stardom','Futuristic Hovering Platform','Glittering Disco Dance Floor Ramp','Ancient Egyptian Obelisk Path','Winding Serpentine Ramp','Martial Arts Dojo-Style Walkway','Rainbow-Colored Moving Sidewalk','Virtual Reality Portal Ramp','Haunted Mansion Staircase','Extraterrestrial Spaceship Gangway','Film Strip Roll Entrance Path','Jungle Canopy Suspension Bridge','Grand Marble Staircase','Graffiti-Covered Urban Alleyway','Pixelated Video Game Runway']
    },
    {name: 'Audience Seating', value: '', order: 0, heading: 'Structural & Design Aspects', custom: false, 
      dropDownOptions: ['Stadium-Style Tiered Seating','Circular Arena with Ringside Pods','Balcony Overlooking the Ring','U-Shaped Arena with Central Ring','Box Seating with VIP Enclosures','Elevated Bleacher-Style Rows','Lounge-Style Couches and Sofas','Circular Bench Seating around the Ring','Cabaret-Style Tables and Chairs','Stadium-Style Bench Seating','Arena with Individual Swivel Chairs','Pod-Style Seating with Personal Screens','Booth-Style Seating with Tables','Raised Platform with Cushioned Seats','Theater-Style Row Seating','Circular Pod Seating with Adjustable Backrests','Stadium-Style Seats with Cup Holders','Retro Diner-Style Booth Seating','Bar-Style Countertop Seating','Lounge-Style Bean Bag Chairs','Reclining Seats with Footrests','Circular Pods with Personal Bar Service','Outdoor Bleacher-Style Wooden Benches','Cushioned Floor Seating with Backrests','Stadium-Style Bleachers with Armrests','Private Box Seating with Panoramic View','Lounge-Style Ottomans and Poufs','Stadium-Style Folding Chair Rows','Circular Seating Pods with Built-In Tables','Elevated Pod Seating with Personal Headsets']
    },
    {name: 'Backstage Area', value: '', order: 0, heading: 'Structural & Design Aspects', custom: false, 
      dropDownOptions: ['State-of-the-Art Athlete Lounge','Spacious and Luxurious Green Room','Multi-Level Hangout Area with Private Pods','Catering Station and Refreshment Bar','Fitness and Training Facility','Costume and Wardrobe Room','Makeup and Hair Styling Area','Relaxation Room with Massage Chairs','Media and Press Conference Room','Secret Strategy Meeting Room','Tech Control Room with Live Feed Monitors','Private Changing Rooms with Personal Lockers','Wellness Center with Therapeutic Treatments','Production Office with Command Center','Props and Equipment Storage Area','Customized Entrance Ramp Design Studio','Custom Outfit Design Studio','Interactive Video Game Lounge','Music Recording and DJ Booth','Private Meeting Room for Promoters and Agents','Podcast and Broadcasting Studio','Social Media and Content Creation Area','High-Tech Surveillance and Security Room','Backstage Entrance with VIP Access','Personal Assistant and Concierge Services','Interactive Autograph and Merchandise Area','Hall of Fame Tribute Wall','Relaxing Zen Garden Retreat','On-Site Physical Therapy and Medical Clinic','Custom Art Gallery Showcasing Wrestler Portraits']
    },
    {name: 'Technical Equipment', value: '', order: 0, heading: 'Structural & Design Aspects', custom: false, 
      dropDownOptions: ['Professional Lighting Rig with Truss System','High-Definition Video Screens and Displays','State-of-the-Art Sound System with Surround Sound','Pyrotechnics and Special Effects Equipment','Wrestling Ring with Hydraulic Lifting System','Camera Drones and Aerial Filming Equipment','Instant Replay and Slow-Motion Playback System','Wireless Microphones for Announcers and Commentators','LED Video Walls and Videoboards','Full-Body Motion Capture System for Animation','Digital Scoreboards and Timer Displays','Custom Entrance Music Playback System','Production Control Room with Video Switching and Editing','Broadcasting and Live Streaming Equipment','Advanced Projection Mapping Technology','Augmented Reality Effects for Enhanced Visuals','Wireless Communication Systems for Crew and Staff','Rigging Equipment for Aerial Performances','Instant Translation Systems for International Events','Video Conferencing Equipment for Remote Interviews','Satellite Uplink and Broadcast Transmission Systems','Interactive Fan Engagement Apps and Platforms','Customizable LED Ring Apron and Turnbuckle Covers','Motion Sensor Technology for Audience Interaction','Robotic Camera Systems for Dynamic Shots','Instantaneous Scoring and Statistic Tracking Systems','Digital Signage and Advertising Displays','Real-Time Voting and Polling Systems','Virtual Reality Live Streaming Capabilities','Digital Ticketing and Access Control Systems']
    },
    {name: 'Regular Events', value: '', simple:false, order: 0, heading: 'Cultural & Audience Aspects', custom: false, 
      dropDownOptions: ['Weekly Wrestling Matches','Pay-Per-View Events','Championship Title Matches','Tag Team Tournaments','Royal Rumble-style Battle Royals','Hardcore Wrestling Showcases','Women\'s Wrestling Showdowns','High-Flying Lucha Libre Matches','Grudge Matches and Rivalries','Celebrity Guest Appearances','Special Showcase Matches for Upcoming Talent','Stipulation Matches ','Championship Belt Unveilings','Wrestler Contract Signings and Promos','Tribute Nights for Wrestling Legends','Faction and Stable Showdowns','Interpromotional Events ','International Wrestling Showcases','Independent Wrestler Spotlights','Tag Team Partner Selection Matches','Open Challenge Matches','Championship Rematches and Title Defenses','Loser Leaves Town Matches','Career vs. Career Matches','Hall of Fame Induction Ceremonies','Wrestler Retirement Farewell Matches','Tournament Finals and Grand Championships','Intergender Wrestling Showcases','Feud of the Year Contender Matches','Specialty Themed Events ']
    },
    {name: 'Notable Past Events', value: '', simple:true, order: 0, heading: 'Cultural & Audience Aspects', custom: false,
      dropDownOptions: ['Legendary Title Change Match','Historic Debut of a Wrestling Icon','Record-Breaking Attendance Event','Memorable Retirement Match','Infamous Heel Turn Incident','Unforgettable Street Fight Showdown','Iconic Ladder Match for a Championship','Landmark Hell in a Cell Match','Unpredictable Money in the Bank Cash-In','Shocking Streak Breaker Match','Historic Iron Man Match','Controversial Screwjob Finish','Epic Tables, Ladders, and Chairs Encounter','Unbelievable High-Flying Spotfest','Heart-Stopping Submission Match','Emotional Return of a Beloved Superstar','Unforgettable Buried Alive Match','Jaw-Dropping First-Ever Women\'s Main Event','Iconic Tag Team Breakup and Feud','Electrifying Guest Host Appearance','Monumental Interpromotional Showdown','Unforgettable Championship Moment','Terrifying Inferno Match','Classic "I Quit" Match','Unforgettable Mask vs. Hair Match','Notorious Stipulation-Driven Match','Epic King of the Ring Tournament','Legendary Tables Match','Iconic Submission Only Match','Historic Royal Rumble Winner']
    },
    {name: 'Signature Traditions', value: '', order: 0, heading: 'Cultural & Audience Aspects', simple:true, custom: false, 
      dropDownOptions: ['Ring Announcer\'s Dramatic Introduction','Enthusiastic "Fight Forever!" Chant during intense matches','Thunderous Applause for Surprise Returns or Entrances','Colorful Streamers Thrown to Celebrate Victories','Unison Clapping to Show Appreciation for Performers','Signature "Woo!" Chant in Unison with the Crowd','Resounding "Yes!" or "No!" Chant for Fan Engagement','Singing Along to Wrestlers\' Entrance Themes','Memorable Catchphrases Repeated by the Crowd','One Fall! Chant in Sync with Ring Announcements','Tossing of Memorabilia or Souvenirs into the Crowd','Audience Illuminating the Arena with Cellphone Lights','Creative and Coordinated Chants to Support Favorites','Thank You! Chants to Honor Departing Wrestlers','Standing Ovation for Extraordinary Performances','Unified Crowd Participation to Build Energy and Atmosphere','Signature Song or Anthem Sung by the Crowd','Themed Chants or Cheers for Specific Matches','Enthusiastic "We Want Tables!" Chant for Hardcore Matches','Please Retire! Chant for Long-tenured Wrestlers','Emotional Farewell Salute to Retiring Legends','Fans Air-Guitaring along with Wrestlers\' Entrance Themes','Chants of "Respect!" for Display of Sportsmanship','Distinctive Ring Bells to Mark Notable Moments','Explosive Pops for Surprise Entrants or Returns','Unique Chants for Heel Wrestlers ','Roars of Excitement for High-Flying Maneuvers','Energetic Clapping Patterns to Heighten the Atmosphere','Customized Chants or Cheers for Specific Storylines']
    },
    {name: 'Regular Attendees', value: '', order: 0, heading: 'Cultural & Audience Aspects', simple:true, custom: false, 
      dropDownOptions: ['Die-Hard Fans','Families and Children','Casual Spectators','Enthusiastic Cosplayers','Longtime Wrestling Enthusiasts','Ringside Ticket Holders','Fan Club Members','Celebrity Guests','Local Sports Teams and Athletes','Hardcore Wrestling Memorabilia Collectors','Fantasy Wrestling League Participants','Social Media Influencers and Content Creators','Wrestling Podcast Hosts and Contributors','Wrestling Merchandise Vendors','Autograph Seekers','Professional Wrestlers in Training','International Wrestling Tourists','Local Wrestling Promotion Owners and Staff','Ringside Photographers and Journalists','Wrestling Memorabilia Traders and Sellers','Ringside Security Personnel','Wrestling Historians and Archivists','Fan Artists and Illustrators','Ringside Announcers and Commentators','Stadium Ushers and Staff','Wrestling Referees and Officials','Volunteer Event Staff','Wrestling Fanfiction Writers and Roleplayers','Amateur Wrestlers and Grapplers','Wrestling Podcast Live Show Attendees']
    },
    {name: 'Historic Significance', value: '', order: 0, heading: 'Cultural & Audience Aspects', custom: false, 
      dropDownOptions: ['Hosting the Inaugural Wrestling Event in the Region','Historic Championship Matches Held at the Arena','Hosting Legendary Wrestlers\' Final Matches or Appearances','Groundbreaking Matches that Revolutionized Wrestling','Hosting the Birthplace of a Prominent Wrestling Promotion','Historic Rivalries and Feuds Played Out at the Arena','Hosting Iconic Pay-Per-View Events','Historic Wrestling Moments Captured in the Arena','Hosting the First-Ever Women\'s Wrestling Main Event','Monumental Retirements or Farewell Matches','Hosting the Debut of Influential Wrestling Innovations','Historic Title Changes and Defenses','The Arena Serving as a Wrestling Training Ground','Hosting Record-Breaking Attendance Events','Iconic Wrestlers Establishing Their Legacies at the Arena','Hosting Historic International Wrestling Showdowns','The Arena as a Wrestling Cultural Hub for the Community','Hosting Wrestling Matches with Influential Stipulations','Significant In-Ring Promos and Speeches Delivered at the Arena','Hosting the Longest or Most Memorable Matches in Wrestling History','The Arena\'s Role in Pioneering Wrestling Broadcasts or TV Tapings','Hosting Historic Interpromotional Events or Collaborations','The Arena Serving as a Wrestling Training Center for Future Stars','Hosting Tribute or Memorial Events for Wrestling Legends','The Arena\'s Architecture or Design Influencing Wrestling Venues','Historic Moments of Fan Interaction and Engagement','The Arena\'s Connection to a Legendary Wrestling Family or Dynasty','Hosting Historic Wrestling Tournaments or King of the Ring Events','The Arena as a Regular Stop on Wrestling Tours and Circuits','The Arena\'s Role in Preserving and Showcasing Wrestling History']
    },
    {name: 'Relationship with Local Community', value: '', order: 0, heading: 'Surrounding Context', custom: false, 
      dropDownOptions: ['Hosting Charity Events and Fundraisers for Local Causes','Providing Volunteer Opportunities for Community Members','Collaborating with Local Businesses for Sponsorship and Promotion','Offering Wrestling Training Programs for Local Youth','Partnering with Local Schools for Wrestling Scholarships','Organizing Meet-and-Greet Sessions with Wrestlers for Fans','Supporting Local Wrestling Promotions and Independent Wrestlers','Offering Venue Rental for Community Events and Functions','Providing Employment Opportunities for Local Staff and Crew','Hosting Local Wrestling Tournaments and Championships','Collaborating with Local Media for Event Coverage and Promotion','Participating in Community Parades and Festivals','Supporting Local Sports Teams and Athletes','Offering Discounted Tickets for Community Organizations','Collaborating with Local Law Enforcement for Event Security','Providing Wrestling Outreach Programs for At-Risk Youth','Hosting Wrestling-themed Workshops and Seminars for Community Members','Collaborating with Local Artists for Unique Merchandise Designs','Supporting Local Nonprofit Organizations with Donations','Offering Facility Tours for School Field Trips and Community Groups','Providing Wrestling-themed Scholarships for Local Students','Hosting Job Fairs and Career Development Events for the Community','Supporting Local Food Banks and Pantries with Donations','Collaborating with Local Hospitals for Charity Initiatives','Offering Wrestling-related Educational Programs for Schools','Engaging in Environmental Sustainability Initiatives within the Community','Collaborating with Local Gyms and Fitness Centers for Training Partnerships','Supporting Local Community Centers and Recreational Programs','Hosting Annual Community Events, such as Fan Appreciation Day','Collaborating with Local Tourism Boards to Attract Visitors to the Area']
    },
    {name: 'Nearby Landmarks', value: '', order: 0, heading: 'Surrounding Context', custom: false, 
      dropDownOptions: ['Historic City Monument','Local Sports Stadium','Famous Theater or Performing Arts Center','Major Shopping Mall or Retail Complex','Landmark Hotel or Convention Center','Renowned Art Gallery or Museum','Historic Landmark or Heritage Site','Prominent City Park or Botanical Garden','Iconic City Bridge or Overpass','Popular Restaurant or Food District','Nearby University or College Campus','City Hall or Government Building','Historic Church or Cathedral','Famous Music Venue or Concert Hall','Local Amusement Park or Fairground','Waterfront Promenade or Boardwalk','Landmark Skyscraper or Observation Deck','Local Zoo or Wildlife Sanctuary','Cultural Center or Community Hub','Historic Train Station or Transportation Hub','Nearby Beach or Coastal Area','Sports Hall of Fame or Museum','Landmark Movie Theater or Entertainment Complex','Historic Battlefield or Memorial Site','Local Landmark Bridge or Archway','Nature Reserve or Scenic Trail','Iconic City Clock Tower or Clock Monument','Local Sports Hall of Fame or Museum','Landmark Shopping Street or District','Nearby Stadium or Arena of Another Sport']
    },
    {name: 'Unique Aspects', value: '', simple:true,order: 0, heading: 'Surrounding Context', custom: false, 
      dropDownOptions: ['Impressive Video Wall Display for Enhanced Visuals','Interactive Fan Engagement Zones','State-of-the-Art Sound System for Immersive Audio Experience','Unique Customizable LED Lighting System','Suspended Entrance Tunnel for Wrestler Grand Entrances','Outdoor Seating Area with Giant LED Screen for Outdoor Viewing','Wrestling Memorabilia Museum on-site','Custom-built Giant Titantron Display','Interactive Augmented Reality Experiences for Fans','Multi-level Seating for Dynamic Viewing Angles','On-site Wrestling Training Facility for Athletes','Themed Specialty Concession Stands ','Dedicated VIP Lounge with Exclusive Amenities','Special Effects such as Pyrotechnics and Fireworks','Elevated Platform for Commentators and Announcers','Unique Wrestling Ring Design ','Themed Sections for Different Fan Groups ','On-site Photo Booths with Wrestling-themed Props','Custom-designed Wrestling Arena Merchandise','Giant Jumbotron Screens for Close-up Action Shots','Dedicated Area for Autograph Signings and Meet-and-Greets','Live Streaming and Video On-Demand Services for Remote Viewing','On-site Wrestling Hall of Fame Display','Interactive Wrestling Trivia and Gaming Stations','Dedicated Kids\' Zone with Family-friendly Activities','Themed Entrance Music and Sound Effects for Each Wrestler','Themed Decor and Props to Enhance the Wrestling Atmosphere','Specialized Wrestling Ring Padding or Mat Design','Unique Wrestler Introductions or Ring Announcer Style','Interactive Fan Voting for Match Stipulations or Outcomes']
    }
  ];
