import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Sections/Header.js';
import CharacterComposer from './Components/Composer/CharacterComposer.js';
import RecentCreations from './Components/Sections/RecentCreations.js';
import Welcome from './Components/Sections/Welcome.js';
import TryAgain from './Components/Sections/TryAgain.js';
import FAQ from './Components/Sections/FAQ.js';
import Footer from './Components/Sections/Footer.js';
import Signup from './Components/Sections/Signup.js';
import SignupPlus from './Components/Sections/SignupPlus.js';
import Sales from './Components/Sections/Sales.js';
import SaveSuccessModal from './Components/Composer/SaveSuccessModal.js';
import DashboardCharacters from './Components/Composer/DashboardCharacters.js';
import Chat from './Components/Composer/Chat.js';
import Terms from './Components/Composer/Terms.js';
import Eula from './Components/Composer/Eula.js';
import Cookies from './Components/Composer/Cookies.js';
import Privacy from './Components/Composer/Privacy.js';
import DnDLanding from './Components/Composer/DnDLanding.js';
import RPGCharacterLanding from './Components/Composer/RPGCharacterLanding.js';
import { FaDiscord } from 'react-icons/fa';
import { Helmet } from 'react-helmet';



const CharacterCreator = () => {
  const socialTitle = "Character Composer | Inspiration Tool";
  const socialDescription = "Flesh out your fictional characters, interact and visualize them with a generated portrait and chat.";
  const socialImage = "https://charactercomposer.com/saved/images/FUv26YzXrL0M3D.png.png.png.png"; // Absolute path to the image you want to share


  const [showLoginModal, setShowLoginModal] = useState(false);
  const toggleLoginModal = () => {
    console.log('toggling login to : ' + showLoginModal);
    setShowLoginModal(!showLoginModal)
  };
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);
  let myCreationsMode = false;
  let paramss = new URLSearchParams(window.location.search);
    if (paramss.get('view') === 'mine') {
      myCreationsMode = true;
    }

   const [recentCreationsMode, setRecentCreationsMode] = useState(!myCreationsMode);
   useEffect(() => {
    // This effect runs on component mount
    const params = new URLSearchParams(window.location.search);
    if (params.get('view') === 'mine') {
      setRecentCreationsMode(false);
    }
  }, []);
   const toggleRecentCreationsMode = () => {
    setRecentCreationsMode(prevMode => !prevMode);
  };

  const [upsellVisible, setUpsellVisible] = useState(false);
   const toggleUpsellVisible = (force = 'not set') => {
    if(force == 'not set'){
      setUpsellVisible(!upsellVisible);
    }
    else{
      console.log('setting is visible to : ' + force);
      setUpsellVisible(force);
    }
  };

  useEffect(() => {

  }, [upsellVisible]);

  useEffect(() => {

  }, [showLoginModal]);

  const [errorMessage, setErrorMessage] = useState('');
  const [genericMessage, setGenericMessage] = useState('');
  const toggleSaveSuccessModal = () => {
    console.log('toggling save modal');
    if (!errorMessage) {
      setShowSaveSuccessModal(!showSaveSuccessModal);
    } else {
      setShowSaveSuccessModal(false); // Close the modal without triggering the success message
    }
    setErrorMessage(''); // Clear the errorMessage when toggling the modal
  };
  
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    setShowCookiePopup(cookiesAccepted || window.location.pathname === '/terms' || window.location.pathname === '/cookies' ? false : true );
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookiePopup(false);
  };

  

  useEffect(() => {
    document.title = 'Character Composer';
  }, []);
    const isLoggedIn = () => {
    return localStorage.getItem('token') !== null;
  };

  const [isPlusMember, setIsPlusMember] = useState(false);
  const stripeCustomerId = localStorage.getItem('stripeCustomerId');
  const plusMember = localStorage.getItem('isPlusMember');
  useEffect(() => {
    if (plusMember == 'true') {
      setIsPlusMember(true);
    }
  }, []);

  const handleSetPlus = (val) => {
    localStorage.setItem('isPlusMember', val);
    setIsPlusMember(val);
  };

  return (
    <>
    <Helmet>
        <title key="title">{socialTitle}</title>
        <meta key="description" name="description" content={socialDescription} />
        {/* Open Graph / Facebook meta tags */}
        <meta key="og:title" property="og:title" content={socialTitle} />
        <meta key="og:description" property="og:description" content={socialDescription} />
        <meta key="og:image" property="og:image" content={socialImage} />

        {/* Twitter Card meta tags */}
        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
        <meta key="twitter:title" name="twitter:title" content={socialTitle} />
        <meta key="twitter:description" name="twitter:description" content={socialDescription} />
        <meta key="twitter:image" name="twitter:image" content={socialImage} />
      </Helmet>
    <BrowserRouter>
      <Header
        showLoginModal={showLoginModal}
        toggleLoginModal={toggleLoginModal}
        genericMessage={genericMessage}
        isPlusMember={isPlusMember}
        upsellVisible={upsellVisible}
        toggleUpsellVisible={toggleUpsellVisible}
      />
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="container">
                <CharacterComposer
                  showLoginModal={showLoginModal}
                  toggleLoginModal={toggleLoginModal}
                  showSaveSuccessModal={showSaveSuccessModal}
                  toggleSaveSuccessModal={toggleSaveSuccessModal}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  genericMessage={genericMessage}
                  setGenericMessage={setGenericMessage}
                  toggleUpsellVisible={toggleUpsellVisible}
                 />
                <RecentCreations mode={'recent'}/>
              </div>
              <SaveSuccessModal
                showSaveSuccessModal={showSaveSuccessModal}
                toggleSaveSuccessModal={toggleSaveSuccessModal}
                errorMessage={errorMessage}
              />
            </div>
          }
        />
        <Route
          path="/DnD-Character-Creator"
          element={
            <div>
              <Helmet>
                <title key="title">Begin Your Epic Journey with Our DnD Character Creator - Perfect for Players of All Levels!</title>
                <meta key="description" key="description" name="description" content="Embark on Your Greatest Adventure with the Ultimate DnD Character Creator!" />
                {/* Open Graph / Facebook meta tags */}
                <meta key="og:title" property="og:title" content="Begin Your Epic Journey with Our DnD Character Creator - Perfect for Players of All Levels!" />
                <meta key="og:description" property="og:description" content="Embark on Your Greatest Adventure with the Ultimate DnD Character Creator!" />
                <meta key="og:image" property="og:image" content={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`} />

                {/* Twitter Card meta tags */}
                <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
                <meta key="twitter:title" name="twitter:title" content="Begin Your Epic Journey with Our DnD Character Creator - Perfect for Players of All Levels!" />
                <meta key="twitter:description" name="twitter:description" content="Embark on Your Greatest Adventure with the Ultimate DnD Character Creator!" />
                <meta key="twitter:image" name="twitter:image" content={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`} />
              </Helmet>
              <DnDLanding/>
            </div>
          }
        />
        <Route
          path="/Character-Designer"
          element={
            <div>
              <Helmet>
                <title key="title">Bring Your Stories to Life with the Ultimate Character Designer</title>
                <meta key="description" key="description" name="description" content="Explore a world of creativity with our Character Designer. Develop characters with unique traits, histories, and motivations, enhancing the depth of your narratives." />
                {/* Open Graph / Facebook meta tags */}
                <meta key="og:title" property="og:title" content="Bring Your Stories to Life with the Ultimate Character Designer" />
                <meta key="og:description" property="og:description" content="Explore a world of creativity with our Character Designer. Develop characters with unique traits, histories, and motivations, enhancing the depth of your narratives." />
                <meta key="og:image" property="og:image" content={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`} />

                {/* Twitter Card meta tags */}
                <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
                <meta key="twitter:title" name="twitter:title" content="Bring Your Stories to Life with the Ultimate Character Designer" />
                <meta key="twitter:description" name="twitter:description" content="Explore a world of creativity with our Character Designer. Develop characters with unique traits, histories, and motivations, enhancing the depth of your narratives." />
                <meta key="twitter:image" name="twitter:image" content={`${process.env.PUBLIC_URL}/sales/8CkypYadlFCML2BoKBPctTbQ3D.png`} />
              </Helmet>
              <RPGCharacterLanding/>
            </div>
          }
        />
        <Route
          path="/chat"
          element={
              <div className="container chat">
                <Chat 
                  genericMessage={genericMessage}
                  setGenericMessage={setGenericMessage}
                  toggleLoginModal={toggleLoginModal}
                  toggleUpsellVisible={toggleUpsellVisible}
                />
              </div>
          }
        />
        <Route
          path="/characters"
          element={
              <div className="container characters">
                <DashboardCharacters
                recentCreationsMode={recentCreationsMode}
                toggleRecentCreationsMode={toggleRecentCreationsMode}
                toggleLoginModal={toggleLoginModal} />
              </div>
          }
        />
        <Route
          path="/terms"
          element={
              <div className="container terms">
                <Terms/>
              </div>
          }
        />
        <Route
          path="/cookies"
          element={
              <div className="container terms">
                <Cookies/>
              </div>
          }
        />
        <Route
          path="/eula"
          element={
              <div className="container terms">
                <Eula/>
              </div>
          }
        />
        <Route
          path="/privacy"
          element={
              <div className="container terms">
                <Privacy/>
              </div>
          }
        />
        {isLoggedIn() && (
        <Route
          path="/dashboard/characters"
          element={
            <div className="container dashboard-characters">
              <DashboardCharacters
              recentCreationsMode={recentCreationsMode}
                toggleRecentCreationsMode={toggleRecentCreationsMode}
                 />
            </div>
          }
        />
        )}
        {isLoggedIn() && (
          <>
        <Route
          path="/welcome"
          element={
              <Welcome/>
          }
        />
        <Route
          path="/try-again"
          element={
            <div className="container try-again">
              <TryAgain/>
            </div>
          }
        />
        </>
          )}
      </Routes>
      {!isLoggedIn() && (
                <Signup
                  showLoginModal={showLoginModal}
                  toggleLoginModal={toggleLoginModal}
                />
              )}
      {isLoggedIn() && !isPlusMember && (
                <Sales
                  className="onPage"
                  handleSetPlus={handleSetPlus}
                />
              )}
      

          <div className="footer">
              <a
          className="social-link"
          href="https://discord.gg/bQhMUFf4cD"
          target="_blank"
        >
          Join our Discord community for lively discussions, valuable tips, and
          exciting updates
          <br />
          <FaDiscord />
        </a>
        </div>
        <div className="bottom-footer">
          <a href="/terms">terms &amp; conditions</a>
          <a href="/eula">EULA</a>
          <a href="/privacy">privacy</a>
          <a href="/cookies">cookies</a>
        </div>
        {showCookiePopup && (
        <div className="cookiePopup">
          <div className="cookieModalContent">
            <h1>👋 Welcome!</h1>
            <p>🧪 Character Composer lets you <b>create</b> and <b>chat</b> to characters with the click of a button.</p>
            <p>🎛️ Provide a brief description then fine tune the details</p>
            <p>🔆 Not sure where to start?<br/>Click the <b>Compose</b> button!</p>
            <div className="cookie-button-container">
              <p>We use <a href="/cookies">cookies</a> to enhance the user experience.</p>
              <button onClick={handleAcceptCookies}>Accept</button>
            </div>
          </div>
        </div>
        )}
    </BrowserRouter>
    </>
  );
};

export default CharacterCreator;
