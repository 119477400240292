import React, { useState, useEffect, useRef } from 'react';
import styles from '../Sections/Characters.module.css';
import AttributeList from './AttributeList.js';
import { FaFilter, FaHeart, FaBorderAll, FaAlignJustify, FaAngleDown, FaAngleUp, FaShare, FaSearch } from 'react-icons/fa';
import CardComponent from './CardComponent';
import LifePathDisplay from './LifePathDisplay.js';



function getBaseURL() {
  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/saved/images/';
    if(url.indexOf('charactercomposer.com') > -1)
       apiUrl = 'https://charactercomposer.com/saved/images/';
  return apiUrl;
}

function cleanAttributes(attributes) {
  if (!attributes || typeof attributes !== 'object' || Array.isArray(attributes)) {
    return {};
  }

  const cleanedAttributes = {};

  for (const key in attributes) {
    const value = attributes[key];
    if (value !== null && typeof value !== 'undefined' && typeof value !== 'object') {
      cleanedAttributes[key] = value;
    }
  }
  return cleanedAttributes;
}

function formatDescription(description) {
  if (!description || typeof description !== 'object' || Array.isArray(description)) {
    return '';
  }

  let formattedDescription = '';
  for (const key in description) {
    const value = description[key];
    if (value) {
      formattedDescription += `<div class="character-paragraph"><h3>${key}</h3><p>${value}</p></div>`;
    }
  }

  return formattedDescription;
}
function getTitleFromViewMode(viewMode) {
  if (viewMode === 'recent') {
    return 'Recent Creations';
  } else if (viewMode === 'popular') {
    return 'Popular Creations';
  }
  return 'Your Creations';
}

function debounce(func, delay) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
      timerId = null;
    }, delay);
  };
}

const DashboardCharacters = ({recentCreationsMode = false, toggleRecentCreationsMode, toggleLoginModal, viewModeInit = 'recent'}) => {
  const [characters, setCharacters] = useState([]);
  const [cards, setCards] = useState([]);
  const [revealFullHeight, setRevealFullHeight] = useState({});
  const [likedCards, setLikedCards] = useState([]);
  const page = useRef(1);
  const isMounted = useRef(false);
  const [viewMode, setViewMode] = useState(viewModeInit);
  useEffect(() => {
    // This effect runs on component mount
    const params = new URLSearchParams(window.location.search);
    if (params.get('view') === 'mine') {
      setTimeout(function(){handleViewModeChange(false, 'myCreations');}, 10);
      const url = new URL(window.location);
      params.delete("view"); // "view" is the parameter you want to remove
      window.history.replaceState({}, '', `${url.pathname}?${params}`);
      
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const [singleView, setSingleView] = useState(false);
  const [genres, setGenres] = useState([]);
  const [currentGenre, setCurrentGenre] = useState('All');
  const [currentMode, setCurrentMode] = useState('All');
  const [currentModeKey, setCurrentModeKey] = useState('All');
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTextInput, setSearchTextInput] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);

  const [searchErrorMsg, setSearchErrorMsg] = useState(''); 

  const [hasLoadedCharacters, setHasLoadedCharacters] = useState(false); 

  const searchWithText = () => {
    page.current=1;
    console.log('Searching for:', searchText);
    setHasSearched(true);
    setSearchText(searchTextInput);
    fetchCharacters(true, viewMode, currentGenre, currentMode, currentModeKey,searchTextInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchWithText();
    }
  };
  const handleInputChange = (event) => {
    setSearchTextInput(event.target.value);
    if (hasSearched) 
      setHasSearched(false);
  };

  const handleGenreClick = (genre) => {
    setCurrentGenre(genre); // Set the current genre
    setSearchText('');
    setSearchTextInput('');
  };

  // Filter function to get relevant genres based on mode
const getGenresForMode = (mode) => {
  if (mode === 'All') {
    // If mode is 'All', return all genres or handle it as per your logic for 'All'
    return []; // Or your logic for 'All' selection
  }

  // Find the correct mode item and get its genres
  const modeItem = genres.find(item => Object.values(item.mode)[0] === mode);

  if (modeItem) {

    // Sort the genres by count in descending order before returning their names
    const sortedGenres = modeItem.values.sort((a, b) => b.count - a.count);

    return sortedGenres.map(val => val.Genre);
  }

  // If no matching mode item is found, return null
  return null;
};


// Now in your render method, use the above function to get relevant genres
const relevantGenres = getGenresForMode(currentMode);
  // Handler for clicking on a mode/type button
  const handleModeClick = (mode) => {
    setCurrentMode(mode);
    setCurrentGenre('All');
    setSearchText('');
    setSearchTextInput('');
  };
  const handleModeKeyClick = (modeKey) => {
    setCurrentModeKey(modeKey);
    setCurrentMode('All');
    setCurrentGenre('All');
    setSearchText('');
    setSearchTextInput('');
  };

  const handleEditClick = (character) => {
    localStorage.setItem('characterToEdit', JSON.stringify(character));
    window.location.href = '/';
  };

const handleChatClick = (character) => {
    localStorage.setItem('currentChatCharacter', JSON.stringify(character));
    window.location.href = '/chat';
  };
const clearFilters = () => {
  setSearchText('');
  setSearchTextInput('');
  setCurrentModeKey('All');
  setCurrentMode('All');
  setCurrentGenre('All');
};

  const handleGetCharacters = async () => {
  var url = new URL(window.location.href);
  var apiUrl = 'http://localhost:3001/get_my_characters/';
  
  if (url.hostname === 'charactercomposer.com') {
    apiUrl = 'https://charactercomposer.com/get_my_characters/';
  }
  const requestBody = {
      limit: 7,
      page: page.current
    };
  const res = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-Key': "2(yY62.mG#6UI324324",
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(requestBody),
  });

  const result = await res.json();

  // Check if the response contains a characters array and return it
  if (result && Array.isArray(result)){
    setHasLoadedCharacters(true);
    return result;
  }
};

  useEffect(() => {
    page.current = 1;
    fetchCharacters(true, viewMode, currentGenre, currentMode, currentModeKey,searchText);
  }, [currentGenre, currentMode, currentModeKey]);



  const handleGetRecentCharacters = async (viewMode = 'recent', searchText = '', modeFilter = '', genreFilter = '', currentModeKey = '') => {
    console.log('genreFilter ' + genreFilter);
    // You should create a URL object from the window.location.href string
    var url = new URL(window.location.href);
    var apiUrl = 'http://localhost:3001/characters_endpoint/';
    
    if (url.hostname === 'charactercomposer.com') {
      apiUrl = 'https://charactercomposer.com/characters_endpoint/';
    }
    if(genreFilter == 'All')
      genreFilter = '';
    if(currentModeKey == 'All')
      currentModeKey = '';
    if(modeFilter == 'All')
      modeFilter = '';
    const requestBody = {
      limit: 7,
      page: page.current,
      orderBy: viewMode,
      text: searchText,  // adding the new filter
      mode: modeFilter,  // adding the new filter
      modeKey:currentModeKey,
      genre: genreFilter  // adding the new filter
    };

    // Check if there is an "id" URL parameter
    const id = url.searchParams.get("id");

    // If an ID exists, modify the request body to include the characterId
    if (id) {
      requestBody.characterId = id;
    }

    const res = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324"
      },
      body: JSON.stringify(requestBody),
    });
    if (id) {
      setSingleView(true);
    }
    if (res.status !== 200) {
      url.searchParams.delete("id");
      window.history.replaceState({}, document.title, url.toString());
      window.location.reload();
      setSingleView(false);
    }
    const result = await res.json();
    // Check if the response contains a characters array and return it
    if (result && Array.isArray(result)) {
      return result;
    } else if(result){
      // handle the scenario where a single character object is returned
      // when "id" parameter was used
      return id && result ? [result] : [];
    }
    else{

    }
};



  const fetchCharacters = async (clearCurrentCards = false, mode = 'recent', genre = currentGenre, type, currentModeKey, searchTerm = '') => {
       if (loading) return;
      let result = false;
      if(recentCreationsMode)
        result = await handleGetRecentCharacters(mode, searchTerm, type, genre,currentModeKey);
      else
        result = await handleGetCharacters();
      setLoading(false);
      if (result.length === 0  ) {
        if(searchTerm.length > 0 && clearCurrentCards){
          setSearchErrorMsg('No creations found with search term: ' + searchTerm);
          setCards([]);
      }
      } else if (isMounted.current) {
        setSearchErrorMsg('');
        var url = new URL(window.location.href);
        var shareUrl = 'http://localhost:3000/characters';
        
        if (url.hostname === 'charactercomposer.com') {
          shareUrl = 'https://charactercomposer.com/characters';
        }
        var newCards = Array.isArray(result)
        ? result
        .filter(character => character.imageUrl !== undefined)
        .map((character, index) => {
            return {
                id: character._id,
                image: getBaseURL() + character.imageUrl,
                title: character.formValues.Name || character.formValues.name,
                html: formatDescription(character.description),
                description: character.description,
                lifePath: character.lifePath ? character.lifePath : false,
                attributes: character.formValues,
                imagePrompt: character.imagePrompt,
                userId: character.userId,
                mode: character.mode,
                tags:character.tags,
                simpleMode: character.simpleMode,
                upVotes: character.upVotes,
                shareUrl: shareUrl + "?id=" + character._id,
              };
          })
        : [];
          if (clearCurrentCards) {
              setCards(newCards);
          } else {
            setCards((prevCards) => {
              const cardIds = new Set(prevCards.map(card => card.id));  // create a Set of existing card IDs for quick lookup
              const uniqueNewCards = newCards.filter(card => !cardIds.has(card.id));  // filter out any new cards that already exist
              return [...prevCards, ...uniqueNewCards];
            });
          }
        }
        setLoading(false);
    };


useEffect(() => {
  isMounted.current = true;
  
  const handleScroll = () => {
    if(singleView)
      return false;
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      page.current += 1;
      if (!loading && !singleView) {
        fetchCharacters(false, viewMode, currentGenre,currentMode, currentModeKey,searchText);
      }
    }
  };

  window.addEventListener('scroll', handleScroll);
  
  if (!loading && !singleView) {
    fetchCharacters(true, viewMode, currentGenre,currentMode, currentModeKey,searchText); 
  }

  return () => {
    isMounted.current = false;
    window.removeEventListener('scroll', handleScroll);
  };
}, [recentCreationsMode, viewMode, loading, singleView, currentGenre, currentMode, currentModeKey,searchText]);

const handleViewModeChange = (event, mode = false) => {
  
  var newMode = false;
  if(mode)
    newMode = mode;
  if(event)
    newMode = event.target.value;
  setViewMode(newMode);
  page.current = 1;  // Reset the page to 1
  if(newMode == 'myCreations' && recentCreationsMode == false || newMode !== 'myCreations' && recentCreationsMode == true){
    return false;
  }
  if(newMode == 'myCreations' && recentCreationsMode == true){
      if(!localStorage.getItem('token')){
        setViewMode('recent');
        toggleLoginModal();
        return;
      }
      toggleRecentCreationsMode();
    }
  if(newMode !== 'myCreations' && recentCreationsMode == false)
    toggleRecentCreationsMode();
  
  setCards([]);  // Clear the current cards
  
};
useEffect(() => {
  page.current = 1;
}, [viewMode]);

const handleDeleteClick = async (characterId) => {
  // Show a confirmation dialog before proceeding with the deletion
  if (window.confirm("Are you sure you want to delete this creation?")) {
    var url = window.location.href;
    const apiUrl = url.indexOf('charactercomposer.com') > -1
      ? 'https://charactercomposer.com/delete/'
      : 'http://localhost:3001/delete/';
    const res = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ id: characterId }),
    });

    if (res.status === 200) {
      // Remove the deleted character from the characters state
      setCharacters(characters.filter((character) => character._id !== characterId));
      window.location.reload();
    } else {
      console.error('Failed to delete character', characterId);
    }
  }
};

const handleOverlayClick = (event) => {
    // Check if the click is directly on the overlay (and not on the modal content)
    if (event.target.classList.contains("cardModalOverlay")) {
      closeModal();  // if it's outside the modal content, close the modal
    }
  };


const handleRevealFullHeightClick = (card) => {
    setCurrentCard(card);
    setIsModalVisible(true);
    setLastScrollHeight(window.scrollY);
    window.scrollTo(0,24);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setCurrentCard(null);
    window.scrollTo(0,lastScrollHeight);
  };

const toggleFullHeight = id => {
    setRevealFullHeight(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

function getModeTitle(card) {
  try {
    //let parsed = JSON.stringify(card.mode);
    return Object.values(card.mode)[0];
  } catch (e) {
    return null;  // or some default value
  }
}

async function toggleLike(card) {
  
  if(!localStorage.getItem('token')){
    toggleLoginModal();
    return;
  }
  // Increment card upVotes and update server data
  var url = window.location.href;
  var apiUrl = url.indexOf('charactercomposer.com') > -1
    ? 'https://charactercomposer.com/increment_vote/'
    : 'http://localhost:3001/increment_vote/';
  const res = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'API-Key': "2(yY62.mG#6UI324324",
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ id: card.id }),
  });

  if (res.status === 200) {
    // Increment the upVotes of the card in the local state
    setCards(prevCards => prevCards.map(c => {
      if(c.id === card.id) {
        if(c.upVotes === undefined)
          c.upVotes = 0;
        return {...c, upVotes: c.upVotes + 1};
      }
      return c;
    }));
    setLikedCards(prevLikedCards => [...prevLikedCards, card.id])
  } else {
    console.error('Failed to increment vote for card', card.id);
  }
}

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('currentCharacter');
    localStorage.removeItem('viewType');
    localStorage.removeItem('customFields');
    window.location.href = "/";
  };

  function copyToClipboard(text) {
  // Create a temporary input element
  var tempInput = document.createElement("input");
  tempInput.value = text;

  // Append the input element to the body
  document.body.appendChild(tempInput);

  // Select the input element's text
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the temporary input element
  document.body.removeChild(tempInput);
  alert('URL has been copied to clipboard ready for sharing');
}

 // This is a helper function to determine whether we need to fetch new data.
  const shouldFetchNewData = () => {
    const lastFetch = localStorage.getItem('lastFetch');
    const ONE_HOUR = 10 * 60 * 1000; // Milliseconds

    // If data doesn't exist or timestamp is older than an hour, return true.
    return !lastFetch || (new Date() - new Date(parseInt(lastFetch))) > ONE_HOUR;
  };

const fetchGenres = async () => {
    try {
      if (!shouldFetchNewData()) {
        // If we don't need to fetch new data, use the cached data.
        const cachedGenres = JSON.parse(localStorage.getItem('cachedGenres'));
        if (cachedGenres) {
          setGenres(cachedGenres);
          return; // Data is still fresh, no need to re-fetch.
        }
      }

      // Determine the API URL based on the current location.
      const url = new URL(window.location.href);
      let apiUrl = 'http://localhost:3001/characters_endpoint/';

      if (url.hostname === 'charactercomposer.com') {
        apiUrl = 'https://charactercomposer.com/characters_endpoint/';
      }

      // Make the API request.
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324"
        },
        body: JSON.stringify({ getCountField: 'Genre' }),
      });

      // Extract the JSON body content from the response.
      const result = await res.json();

      if (result && Array.isArray(result)) {
        console.log(result);
        setGenres(result);

        // Cache the data in localStorage, along with the current time.
        localStorage.setItem('cachedGenres', JSON.stringify(result));
        localStorage.setItem('lastFetch', new Date().getTime().toString());
      }
    } catch (error) {
      console.error('Failed to fetch genres', error);
    }
  };


  // Use the useEffect hook to fetch genre data once the component is mounted
  useEffect(() => {
    fetchGenres();
  }, []);
  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };
  return (
  <div className={`section dashboard ${singleView ? styles.singleView : ''}`}>
    <div className="center-text">
    {!singleView && 
    <>
      <h2>Browse Creations</h2>

          <div className="topButtonContainer">
          
            <div className="modeSelectionContainer">
              
              <div className="topContainer">
                  <button
                    key={0}
                    className={viewMode === 'recent' ? 'active' : ''}
                    onClick={() => handleViewModeChange(false,'recent')}
                  >
                    Recent
                  </button>
                  <button
                    key={1}
                    className={viewMode === 'popular' ? 'active' : ''}
                    onClick={() => handleViewModeChange(false,'popular')}
                  >
                    Popular
                  </button>
                  <button
                    key={2}
                    className={viewMode === 'myCreations' ? 'active' : ''}
                    onClick={() => handleViewModeChange(false, 'myCreations')}
                  >
                    My Creations
                  </button>

                </div>
              </div>
              {recentCreationsMode && 
              <div className="filter-container">
                <button className="filter-toggle" onClick={toggleShowFilters}>
                  filters{showFilters ? <FaAngleUp/>:<FaAngleDown/>}  
                </button>
                {showFilters &&
                <div className="filters">
                  <div className="typeSelectionContainer">
                    <div className="typeSelectionScrollContainer">
                      <button 
                          className={currentModeKey === 'All' ? 'active' : ''}
                          onClick={() => handleModeKeyClick('All')}
                        >
                        All
                      </button>
                      <button 
                          key={0}
                          className={currentModeKey === 'Character' ? 'active' : ''}
                          onClick={() => handleModeKeyClick('Character')}
                        >
                          Character
                      </button>
                      <button 
                          key={1}
                          className={currentModeKey === 'Object' ? 'active' : ''}
                          onClick={() => handleModeKeyClick('Object')}
                        >
                          Object
                      </button>
                      <button 
                          key={2}
                          className={currentModeKey === 'Location' ? 'active' : ''}
                          onClick={() => handleModeKeyClick('Location')}
                        >
                          Location
                      </button>
                      <button 
                          key={3}
                          className={currentModeKey === 'Narrative' ? 'active' : ''}
                          onClick={() => handleModeKeyClick('Narrative')}
                        >
                          Narrative
                      </button>
                    </div>
                  </div>
                  {currentModeKey !== 'All' && 
                    <div className="typeSelectionContainer">
                      <div className="typeSelectionScrollContainer">
                        <button 
                            className={currentMode === 'All' ? 'active' : ''}
                            onClick={() => handleModeClick('All')}
                          >
                          All
                        </button>
                        {genres.map((modeItem, index) => {
                            // Get the key and value of modeItem.mode
                            const modeKeys = Object.keys(modeItem.mode);
                            const modeValues = Object.values(modeItem.mode);
                            // Check if modeKeys has at least one element, and the first element equals currentModeKey
                            if  (currentModeKey == 'All'|| (modeKeys.length > 0 && currentModeKey === modeKeys[0] )){
                              return (
                                <button 
                                  key={index}
                                  className={currentMode === modeValues[0] ? 'active' : ''}
                                  onClick={() => handleModeClick(modeValues[0])}
                                >
                                  {modeValues[0]}
                                </button>
                              );
                            }

                            return null;
                          })}

                      </div>
                    </div>
                  }
                  {currentMode !== 'All' && relevantGenres.length > 0 &&
                  <div className="typeSelectionContainer genre">
                      <div className="typeSelectionScrollContainer">
                        <button 
                          className={currentGenre === 'All' ? 'active' : ''}
                          onClick={() => handleGenreClick('All')}
                        >
                          All
                        </button>
                        {relevantGenres
                          .filter(genre => genre)
                          .slice(0,20)
                          .map((genre, index) => (
                            <button 
                              key={index} 
                              className={currentGenre === genre ? 'active' : ''} 
                              onClick={() => handleGenreClick(genre)}
                            >
                              {genre}
                            </button>
                        ))}
                      </div>
                    </div>
                  }
                  <div className="textSearch">
                    <input type="text" 
                           placeholder="Search creations..." 
                           onChange={handleInputChange} 
                           onKeyDown={handleKeyDown}
                           className={hasSearched ? 'searched' : ''}
                           value={searchTextInput}
                    />
                    <FaSearch onClick={searchWithText} />
                  </div>
                  <button className="clear-filters" onClick={clearFilters}>clear filters</button>
                </div>
              }
              </div>
              }
              {searchErrorMsg && <p className="error">{searchErrorMsg}</p>}
              
            </div>
            </>
      }
      {cards.length === 0 && hasLoadedCharacters ? (
        <div className="center-me">
          <div className="noCreationsMessage">
            <p>
              You have no creations yet
            </p>
              <a href="/">
                <button className="std-btn">
                  Create a new character
                </button>
              </a>
              <br/>
                or
              <br/>
              <a href="/characters">
                <button className="std-btn">
                  Browse other people's creations
                </button>
              </a>
          </div>
        </div>  
      ) : (
      <div className={styles.gridContainer}>
        {cards.map((card) => (
        <CardComponent
          key={card.id}
          card={card}
          recentCreationsMode={recentCreationsMode}
          likedCards={likedCards}
          revealFullHeight={revealFullHeight}
          singleView={singleView}
          onCopy={copyToClipboard}
          onDelete={handleDeleteClick}
          onEdit={handleEditClick}
          onToggleLike={toggleLike}
          onChat={handleChatClick}
          onRevealFullHeight={handleRevealFullHeightClick}
          getModeTitle={getModeTitle}
          cleanAttributes={cleanAttributes}
          AttributeList={AttributeList}
        />
      ))}
      </div>
       )}
       <p>
           <b>Scroll down to see more creations</b>
      </p>
       {singleView &&
              <>
                <br/>
                <a className="viewAllButton" href="/characters">View all Creations</a>
              </>
              }
    </div>
    {isModalVisible && currentCard && (
      <div className="cardModalOverlay" onClick={handleOverlayClick}>
        <div className="cardModalContent">
          <button className="outputModalCloseButton" onClick={closeModal}>Close</button>
          <CardComponent
            card={currentCard}
            recentCreationsMode={recentCreationsMode}
            likedCards={likedCards}
            revealFullHeight={revealFullHeight}
            singleView={true}
            onCopy={copyToClipboard}
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
            onToggleLike={toggleLike}
            onChat={handleChatClick}
            onRevealFullHeight={handleRevealFullHeightClick}
            getModeTitle={getModeTitle}
            cleanAttributes={cleanAttributes}
            AttributeList={AttributeList}
          />
         {currentCard?.lifePath?.lifePath && <div className="lifePathDisplayContainer"><h3>{currentCard.attributes.Name}'s' Life Path</h3><LifePathDisplay userChoices={currentCard.lifePath.userChoices} lifePath={currentCard.lifePath.lifePath}/></div> }
        </div>
        <span className="viewAllButton" onClick={closeModal}>Return to Creations</span>
      </div>
    )}
    </div>
    
);


};

export default DashboardCharacters;
