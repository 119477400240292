export const FieldsLocationMicro = [
    {name: 'Name', value: '', order: 0, simple:true, heading: '', custom: false},
    {name: 'Genre', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ['Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric']
    },
    {name: 'Type of Micro Location', value: '', simple:true, order: 0, heading: 'Basic', custom: false,
      dropDownOptions: ['Room','Cave','Dungeon','Alleyway','Courtyard','Bridge','Plaza','Cabin','Treehouse','Garden','Rooftop','Balcony','Attic','Basement','Secret Passage','Shrine','Classroom','Gallery','Library','Laboratory','Observatory','Art Studio','Workshop','Tavern','Shop','Cathedral','Crypt','Courthouse','Museum','Theatre']
    },
    {name: 'Atmosphere', value: '', order: 0, simple:true, heading: 'Traits', custom: false, 
      dropDownOptions: ['Serene','Mystical','Enchanting','Mysterious','Vibrant','Tranquil','Lively','Whimsical','Eerie','Magical','Romantic','Rustic','Industrial','Ancient','Futuristic','Harmonious','Chaotic','Peaceful','Dramatic','Dreamy','Majestic','Intimate','Welcoming','Thrilling','Foreboding','Joyful','Nostalgic','Spiritual','Adventurous','Cosy']
    },
    {name: 'Physical Features', value: '', simple:true, order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Staircase','Pillars','Archway','Windows','Doors','Balustrade','Ceiling Beams','Fireplaces','Mosaic Floor','Wall Murals','Carvings','Chandeliers','Skylights','Glass Domes','Secret Compartments','Hidden Passageways','Trapdoors','Spiral Staircase','Mezzanine','Atrium','Raised Platform','Pergola','Water Feature ','Wall Niches','Mirrors','Wall Sconces','Mantelpiece','Stained Glass Windows','Rooftop Terrace','Portico','Column','Sculptures','Moss-covered Rocks','Wildflowers','Babbling Brook','Miniature Waterfall','Ferns','Fallen Tree Trunk','Mossy Pathway','Sunlit Clearing','Tiny Caves','Miniature Cliffs','Delicate Mushrooms','Lichen-covered Branches','Crystal Clear Pools','Miniature Sand Dunes','Vibrant Insect Life','Sun-dappled Canopy','Rocky Overhangs','Tiny Mossy Hills','Trickling Stream','Lush Undergrowth','Miniature Ravine','Exposed Tree Roots','Patches of Soft Moss','Tranquil Ponds','Small Natural Springs','Miniature Lagoons','Sun-kissed Boulders','Dense Thicket of Plants',"Chirping Birds' Nests",'Crisp Mountain Air']
    },
    {name: 'Architectural Style', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Gothic','Classical','Renaissance','Baroque','Victorian','Tudor','Art Deco','Art Nouveau','Modernist','Postmodern','Brutalist','Neoclassical','Colonial','Greek Revival','Romanesque','Gothic Revival','Bauhaus','Federal','Georgian','Italianate','Queen Anne','Prairie','Craftsman','International','Spanish Colonial','Cape Cod','Mid-century Modern','Shingle','Ranch','Contemporary']
    },
    {name: 'Unique Features', value: '', simple:true, order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Gargoyles','Turrets','Minarets','Cupolas','Oculus','Flying Buttresses','Rooftop Gardens','Solar Panels','Green Walls','Water Features','Atriums','Grand Staircases','Skybridges','Cantilevered Balconies','Stained Glass Windows','Porthole Windows','Verandas',"Trompe-l'oeil Paintings",'Living Walls','Geodesic Domes','Turbine Ventilators','Domed Ceilings','Perforated Screens','Secret Passageways','Spiral Staircases','Mosaic Floors','Transparent Floors','Suspended Walkways','Sunken Courtyards','Recycled Materials']
    },
    {name: 'Materials', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Brick','Stone','Wood','Concrete','Steel','Glass','Marble','Adobe','Straw','Thatch','Bamboo','Copper','Aluminum','Granite','Slate','Mud','Rammed Earth','Coral','Wattle and Daub','Limestone','Timber','Iron','Sandstone','Stucco','Terracotta','Bamboo','Glass Reinforced Concrete ','Palm Leaves','Cob','Ice']
    },
    {name: 'Condition', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Pristine','Well-maintained','Dilapidated','Abandoned','Renovated','Ruined','Neglected','Restored','Under Construction','Weathered','Crumbling','Decaying','Uninhabited','In Use','Occupied','Deserted','Derelict','Repurposed','Intact','Run-down','Modernized','Overgrown','Preserved','Shabby','Contemporary','Immaculate','Unfinished','Reclaimed','Inaccessible','Demolished']
    },
    {name: 'Lighting', value: '', order: 0, heading: 'Traits', custom: false, 
      dropDownOptions: ['Brightly Lit','Dimly Lit','Natural Sunlight','Soft Lighting','Harsh Lighting','Candlelit','Spotlight','Flickering Lights','Neon Lighting','Moonlit','Shadowy','Daylight-Filled','Twilight','Sunrise/Sunset Glow','Firelight','Starlit','Dappled Light','Backlit','Underlit','Overhead Lighting','Lantern-lit','Strobe Lights','Flashing Lights','Muted Lighting','Colored Lighting','Basked in Golden Light','Silhouetted','Striking Contrast of Light and Shadow','Glowing Luminescence','Ambient Lighting']
    },
    {name: 'Associated Activities', value: '', simple:true, order: 0, heading: 'Basic', custom: false, 
      dropDownOptions: ['Prayer','Meditation','Artistic Creation','Music Performance','Reading','Writing','Martial Training','Cooking','Baking','Gardening','Crafting','Artisan Work','Study','Research','Yoga','Pilates','Gaming','Entertainment','Socializing','Gathering','Healing','Therapy','Teaching','Tutoring','Ritual','Ceremony','Storytelling','Experimenting','Scientific Research','Observation','Contemplation','Discussion','Debate','Problem-Solving','Negotiation','Diplomacy','Physical Fitness','Exercise','Exploration','Discovery','Performance Art','Drama','Worship','Religious Practices','Design','Architecture','Reflection','Mindfulness','Mental Well-being Practices','Preservation','Conservation','Creative Collaboration']
    },
    {name: 'Notable Objects', value: '', simple:true, order: 0, heading: 'Traits', custom: false,
      dropDownOptions: ['Altar','Sculpture','Tapestry','Painting','Musical Instrument','Antique Furniture','Relic','Ritual Artifact','Sacred Text','Decorative Rug','Ornate Mirror','Curio Cabinet','Crystal Chandelier','Intricately Carved Chest','Vintage Clock','Handcrafted Jewelry','Ceremonial Dagger','Manuscript','Exquisite Vase','Illuminated Manuscript','Antique Books','Treasure Chest','Engraved Goblet','Ancient Coins','Unique Amulet','Precious Gemstone','Elaborate Candelabra','Hand-Painted Pottery','Rare Artwork','Enigmatic Reliquary']
    },
    {name: 'Historic Significance', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Birthplace of a Famous Figure','Site of a Notable Battle','Place of Historic Treaty Signing','Home to an Influential Artist/Writer','Key Location in a Historical Event','Connection to an Important Invention','Residence of a Prominent Political Figure','Cultural or Artistic Renaissance','Center of Religious Pilgrimage','Role in a Significant Scientific Discovery','Memorial Site','Monument to Historical Heroes','Preservation of Ancient Traditions','Architectural Marvel','Cultural Exchange Hub','Hub of Innovation and Industry','Witness to Social Movements','Repository of Ancient Artifacts','Site of an Archaeological Discovery','Origin of an Indigenous Civilization','Birthplace of a Literary Movement','Significance in the History of Medicine','Notable Educational Institution','Historic Trade Route Junction','Center of Cultural Exchange','Site of Rebellion or Revolution','Connection to Ancient Mythology','Monumental Symbol of National Identity','Evidence of Ancient Human Settlement','Historical Landmark of Remarkable Architecture']
    },
    {name: 'Cultural Significance', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Birthplace of a Prominent Cultural Figure','Center for Traditional Arts and Crafts','Site of Cultural Festivals and Celebrations','Preserves Traditional Music and Dance','Historical Center for Education and Learning','Connection to Indigenous Cultural Heritage','Religious Pilgrimage Site','Sacred or Ritual Space','Symbol of Cultural Identity','Hub for Cultural Exchange and Collaboration','Repository of Cultural Artifacts and Artefacts','Significant in the History of Literature','Fosters Linguistic and Cultural Diversity','Represents an Ethnic or Immigrant Community','Cultural Revival or Preservation Efforts','Showcases Traditional Culinary Practices','Demonstrates Local Traditional Clothing and Fashion','Living Museum of Local Traditions and Customs','Promotes Traditional Healing Practices','Cultural Hub for Performing Arts and Theater','Symbolizes Historical Social Movements','Reflects Unique Architectural Styles','Showcases Traditional Games and Sports','Exhibits Unique Traditional Ceremonies and Rituals','Historical Center for Traditional Medicine','Home to Historic Cultural Institutions ','Keeps Traditional Storytelling and Oral Histories Alive','Honors and Preserves Indigenous Knowledge Systems','Historic Gathering Place for Intellectual and Artistic Communities','Center for Cultural Education and Awareness']
    },
    {name: 'Within Location', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Forest','Desert','City','Country','Kingdom','Empire','Mountain Range','Island','Archipelago','Peninsula','Rainforest','Tundra','Savanna','Marshland','Prairie','Steppe','Lake','River Delta','Volcanic Region','Glacier','Canyon','Plateau','Ocean','Underwater City','Space Station','Lunar Colony','Asteroid Belt','Exoplanet','Parallel Universe','Dimensional Rift']
    },
    {name: 'Unique Aspects', value: '', order: 0, simple:true, heading: 'Basic', custom: false, 
      dropDownOptions: ['Hidden Passage','Secret Room','Ethereal Glow','Whispering Walls','Timeless Ambiance','Enchanted Artwork','Shifting Architecture','Luminous Foliage','Mirror Maze','Gravity-Defying Structures','Living Walls','Holographic Displays','Mystical Symbols','Energy Vortex','Illusory Landscapes','Interactive Art Installations','Gravity-Nullifying Zone','Invisible Pathways','Pervasive Aroma','Echoing Halls','Kinetic Sculptures','Levitating Objects','Mind-Altering Acoustics','Animated Statues','Perception-Altering Lighting','Elemental Manipulation','Illusionary Illnesses','Thought-Provoking Riddles','Shape-Shifting Structures','Time Dilation Effect']
    },
    {name: 'Current Status', value: '', order: 0, heading: 'Background', custom: false, 
      dropDownOptions: ['Occupied','Vacant','Under Renovation','Closed for Maintenance','Open for Business','Temporarily Closed','Restricted Access','Reserved for Special Event','Fully Booked','Sold Out','Operational','Not in Use','Abandoned','Inactive','Restricted Entry','Limited Capacity','Popular Destination','Emerging Trend','Renowned Attraction','Seasonal Operation','Temporary Exhibition','Active Construction','Pending Approval','In Transition','Scheduled Demolition','Future Development','Historic Preservation','Listed for Sale','Preparing for Opening','Operational Expansion']
    },
    {name: 'Additional Details', value: '', order: 0, heading: 'Background', custom: false},
  ];
