import React, { useState } from 'react';
import './AttributeList.css';

const AttributeList = ({ attributes, header = true }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const attributeMapping = {
    baseTheme: 'Genre',
    origin: 'Background',
    skillsAndAbilities: 'Skills',
    additionalDetails: 'Details',
    physicalAppearance: 'Appearance'
  };

  const getAttributeName = (originalName) => {
    return attributeMapping[originalName] || originalName;
  };

  return (
    <div className="attribute-list">
    {header &&(
      <div className="accordion-header" onClick={handleClick}>
        <h3>Attributes</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      )}
      {isOpen && (
        <div className="accordion-content">
          <div className="column">
            {Object.entries(attributes)
              .filter(([key, value]) => key !== 'name' && key !== 'Name' && key !== 'visualTheme')
              .slice(0, Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                  <span className="attribute-value">{value}</span>
                </div>
              ))}
          </div>
          <div className="column">
            {Object.entries(attributes)
              .filter(([key, value]) => key !== 'name')
              .slice(Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                  <span className="attribute-value">{value}</span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributeList;
