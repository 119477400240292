import React, { useState, useEffect, useRef, useCallback  } from 'react';
import FormSection from './FormInputs/FormSection.js';
import SimpleInput from './FormInputs/SimpleInput.js';
import TextAreaInput from './FormInputs/TextAreaInput.js';
import { FaShare, FaMinus, FaPause, FaStream, FaDice, FaDiceFive, FaRedoAlt, FaTrash, FaCog, FaPaintBrush, FaAngleDown, FaAngleUp,FaCheckCircle, FaCircle } from 'react-icons/fa';
import LoadingBox from './LoadingBox.js';
import AttributeList from './AttributeList.js';
import Accordion from './Accordion.js';
import LifePathComponent from './LifePathComponent.js';
import TagsModal from './TagsModal.js';
import TagsDisplay from './TagsDisplay';
import CheckBoxForm from './CheckBoxForm.js';
import RadioButtonForm from './RadioButtonForm.js';
import ImageStyleOptionSelect from './ImageStyleOptionSelect.js';
import CharacterParagraph from './CharacterParagraph.js';
import ImageComponent from './ImageComponent.js';
import Spinner from './Spinner';
import { FaDiscord } from 'react-icons/fa';

import { FieldsCharacterDetailed } from './FormInputs/FieldsCharacterDetailed';
import { FieldsCharacterMonster } from './FormInputs/FieldsCharacterMonster';
import { FieldsCharacterNPC } from './FormInputs/FieldsCharacterNPC';
import { FieldsCharacterHenchman } from './FormInputs/FieldsCharacterHenchman';
import { FieldsCharacterPet } from './FormInputs/FieldsCharacterPet';
import { FieldsCharacterWrestler } from './FormInputs/FieldsCharacterWrestler';

import { FieldsItemDetailed } from './FormInputs/FieldsItemDetailed';
import { FieldsItemWeapon } from './FormInputs/FieldsItemWeapon';
import { FieldsItemEquipment } from './FormInputs/FieldsItemEquipment';
import { FieldsItemClothing } from './FormInputs/FieldsItemClothing';
import { FieldsItemArmor } from './FormInputs/FieldsItemArmor';
import { FieldsItemVehicle } from './FormInputs/FieldsItemVehicle';

import { FieldsLocationMacro } from './FormInputs/FieldsLocationMacro';
import { FieldsLocationMicroWrestlingArena } from './FormInputs/FieldsLocationMicroWrestlingArena';
import { FieldsLocationMicro } from './FormInputs/FieldsLocationMicro';
import { FieldsLocationWorld } from './FormInputs/FieldsLocationWorld';

import { FieldsNarrativeLifePath } from './FormInputs/FieldsNarrativeLifePath';
import { FieldsNarrativeFaction } from './FormInputs/FieldsNarrativeFaction';
import { FieldsNarrativeMcGuffin } from './FormInputs/FieldsNarrativeMcGuffin';

var CharacterComposer = ({ showLoginModal, toggleLoginModal, toggleSaveSuccessModal, errorMessage, setErrorMessage, genericMessage, setGenericMessage, toggleUpsellVisible  }) => {
  const CURRENT_VERSION = 6;
  const savedVersion = localStorage.getItem('version');
  if (savedVersion === null || parseInt(savedVersion, 10) < CURRENT_VERSION) {
    const keysToReset = ['version', 'imageStyle', 'simpleMode','viewType','inputMode', 'currentCharacter']; // Replace these with the keys you want to reset.
    keysToReset.forEach(key => localStorage.removeItem(key));
    localStorage.setItem('version', CURRENT_VERSION.toString());
  }

  const [forceRender, setForceRender] = useState(false);

  const [characterInfo, setCharacterInfo] = useState({});
  const [activeTitle, setActiveTitle] = useState(null);

  const [response, setResponse] = useState('');
  var [formFilled, setFormFilled] = useState(false);
  const [imagePrompt, setImagePrompt] = useState('');
  const [descriptionPrompt, setDescriptionPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [randomLoading, setRandomLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const savedData = JSON.parse(localStorage.getItem('lifePath')) || { attributes: false, lifePath: false, userChoices:false, lifePathId: false };
  const [lifePathId, setLifePathId] = useState(savedData.lifePathId);
  const [lifePath, setLifePath] = useState(savedData.lifePath);
  const [userChoices, setUserChoices] = useState(savedData.userChoices);
  const [lifePathInputs, setLifePathInputs] = useState(savedData.attributes);
  useEffect(() => {
    const lifePathData = {
      attributes: lifePathInputs,
      lifePath: lifePath,
      userChoices: userChoices,
      lifePathId: lifePathId
    };
    localStorage.setItem('lifePath', JSON.stringify(lifePathData));
  }, [lifePath, lifePathInputs, userChoices, lifePathId]);
  const [imageUrl, setImageUrl] = useState("");
  const [visualOptions, setVisualOptions] = useState([
      'Digital Art',
      'Portrait',
      'Manga',
      'Oil Painting',
      'Woodcut',
      'Graphic Novel',
      'Photograph',
      'Cartoon',
      'Wild West',
      'Cyberpunk',
      'Steampunk',
      'Warhammer 40K',
      'Warhammer Fantasy',
      'Lego',
      'Pixar',
      'Magic the Gathering',
      'The Simpsons',
      'South Park',
      'Pokemon',
      'Power Puff Girls',
      'World of Warcraft',
      'Charcoal Drawing',
      'Graffiti',
      '3D Render',
      'Watercolor',
      'Pencil Drawing',
      'Ink Drawing',
      'Pop Art',
      'Concept Art',
      'Art Deco',
      'Pixel Art',
      'Gothic',
      'Stencil',
      'Etching',
      'Hologram',
      'Paper Cutout',
      'Tapestry',
      'Toy Land',
      'Retro 8-bit',
      'Retro 16-bit'
  ]);
  var tImageStyle = visualOptions[0];
  if(localStorage.imageStyle === undefined)
    localStorage.setItem('imageStyle', tImageStyle);
  else
    tImageStyle = localStorage.imageStyle;
  
  const [imageStyle, setImageStyle] = useState(tImageStyle);

  const [dalle3, setDalle3] = useState(false);
  
  const [output, setOutput] = useState('');
  const [outputOptions, setOutputOptions] = useState({
      'Memorable Quote': true,
      'First Person Description': true,
      'Third Person Description': true,
      'Battle Cry': false,
      'Past Experiences': false,
      'Relationships and Social Interactions': false,
      'Reputation and Influence': false,
      'Strengths and Weaknesses': false,
      'Taunts and Threats': false,
      'Goals and Aspirations': false,
      'Important Items': true,
      'Interests and Lifestyle': false, 
      'DnD Stats': false
  });

  const [formLayouts, setFormLayouts] = useState({
    "Character":[
      'Character',
      'NPC',
      'Monster',
      'Henchman',
      'Pet',
      'Wrestler'
    ],
    "Object":[
      'RPG Item',
      'Vehicle',
      'Weapon',
      'Equipment',
      'Clothing',
      'Armor'
    ],
    "Location":[
      'Micro Location',
      'Macro Location',
      'World',
      'Wrestling Arena'
    ],
    "Narrative":[
      'Faction',
      'McGuffin',
      'Life Path',
      
    ],
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('');

  const [outputModalMaxOutputs, setOutputModalMaxOutputs] = useState(4);

  var [characterId, setCharacterId] = useState('');
  var [editingSavedCharacterId, setEditingSavedCharacterId] = useState(false);

  useEffect(() => {

  }, [showLoginModal]);

  const [isSaving, setIsSaving] = useState(false);


  const [inputMode, setInputMode] = useState('');
  const [c_inputfields, setCInputFields] = useState(FieldsCharacterDetailed);

  const [descriptionInputText, setDescriptionInputText] = useState('');

  const [shownInputWarning, setShownInputWarning] = useState(false);

  const [attributeSorting, setAttributeSorting] = useState('');
  const [attributeDefault, setAttributeDefault] = useState('');

  const [shownAttributesTip, setShownAttributesTip] = useState(true);

  const savedTags = JSON.parse(localStorage.getItem('tags')) || [];
  const [tags, setTags] = useState(savedTags);
  const [activeTags, setActiveTags] = useState([]);

  useEffect(() => {

    }, [tags]);
  useEffect(() => {
      // Load tags from localStorage initially
      const savedTags = JSON.parse(localStorage.getItem('tags')) || [];
      setTags(savedTags);

      // Function to load tags from the database
      const loadTagsFromDB = async () => {
        console.log('loading tags');
        let apiUrl = 'http://localhost:3001/my_tags/';
        
        if (window.location.hostname === 'charactercomposer.com') {
          apiUrl = 'https://charactercomposer.com/my_tags/';
        }

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'API-Key': "2(yY62.mG#6UI324324",
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({  type: 'get' }),
          });

          if (response.ok) {
            const fetchedTags = await response.json();
            localStorage.setItem('tags', JSON.stringify(fetchedTags));
            setTags(fetchedTags);
            // Update the last fetch time
            localStorage.setItem('lastTagsFetchTime', Date.now().toString());
          }
        } catch (error) {
          console.error('Error loading tags:', error);
        }
      };
      
      // Check if it's been more than an hour since the last fetch
      const lastFetchTime = parseInt(localStorage.getItem('lastTagsFetchTime'), 10);
      if (!lastFetchTime || Date.now() - lastFetchTime > 3600000) { // 3600000 ms = 1 hour
        loadTagsFromDB();
      }
    }, []);

    




  var tSimpleMode = false;
if(localStorage.simpleMode === undefined)
    localStorage.setItem('simpleMode', tSimpleMode);
else
    tSimpleMode = JSON.parse(localStorage.simpleMode); // parse string to boolean
  const [isSimpleMode, setSimpleMode] = useState(tSimpleMode);

  useEffect(() => {
    var simpleMode = true;
    if(localStorage.getItem('simpleMode') !== undefined)
        simpleMode = JSON.parse(localStorage.getItem('simpleMode'));
    setSimpleMode(simpleMode);
}, []);

  useEffect(() => {
    // Load initial state from localStorage when the component mounts
    const savedTip = localStorage.getItem('shownAttributesTip');
    if (savedTip === false || !savedTip) {
      setShownAttributesTip(false);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('shownAttributesTip', shownAttributesTip);
  }, [shownAttributesTip]);

useEffect(() => {
    // Load initial state from localStorage when the component mounts
    const savedSorting = localStorage.getItem('attributeSorting');
    const savedDefault = localStorage.getItem('attributeDefault');

    if (savedSorting) {
      setAttributeSorting(savedSorting);
    }

    if (savedDefault) {
      setAttributeDefault(savedDefault);
    }
  }, []);

  useEffect(() => {
    // Save to localStorage whenever the state changes
    localStorage.setItem('attributeSorting', attributeSorting);
    localStorage.setItem('attributeDefault', attributeDefault);
  }, [attributeSorting, attributeDefault]);

const toggleSimpleMode = (e) => {
  if(e){
    e.preventDefault();
    e.stopPropagation();
  }
  setSimpleMode(prevState => !prevState);
   
};

const toggleLayout = () => {
    setSingleColumn(prevValue => !prevValue);
};

useEffect(() => {
    localStorage.setItem('simpleMode', JSON.stringify(isSimpleMode));

}, [isSimpleMode]);

useEffect(()=>{
  //setModalMode('tags');
    //setModalOpen(true);
},[]);
  
  useEffect(() => {
    const savedCustomFields = localStorage.getItem('c_customfields');
    setTimeout(function(){
      if(savedCustomFields){
        const parsedCustomFields = JSON.parse(savedCustomFields);
        parsedCustomFields.forEach(field => {
          setInputField(field.name, '', true);
        });
      }
    },200);
  }, []);

const handleModeClick = (mode) => {
    setInputMode({[mode]: formLayouts[mode][0]}); // reset the active type when the mode changes
  }
  const handleTypeClick = (type) => {
    setInputMode({[Object.keys(inputMode)[0]]: type});
  }
  
  function isPlusMemberCheck(){
  var isPlusMember = localStorage.getItem('isPlusMember');
  if(isPlusMember == 'true')
    return true;
  else 
    return false;
};


  

  function getQueryParam(param) {
    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    var paramValue = params.get(param);
    // Remove the 'mode' parameter
    setTimeout(function(){
    params.delete(param);
    // Change the url without reloading the page
    window.history.replaceState({}, '', `${url.pathname}?${params}`);
    },500);
    return paramValue;
}

useEffect(() => {
    let t = '{"Character": "Character"}';
    var mode = getQueryParam('mode');
    if(mode){
      t = mode;
    }
    else{
      if(localStorage.inputMode !== undefined)
        t = localStorage.getItem('inputMode');
    }
    if(t){
        setInputMode(JSON.parse(t));
        setFieldsFromInputMode(JSON.parse(t));
        setOutputsBasedOnLayout(JSON.parse(t));
      }
  }, []);
useEffect(() => {
  if(inputMode){

    setFieldsFromInputMode(inputMode);
    
    const storedCharacter = JSON.parse(localStorage.getItem('currentCharacter'));
    const storedMode = storedCharacter ? Object.values(storedCharacter.mode)[0] : null;
    console.log('stored mode is: ' + storedMode);
    console.log('current mode is: ' + Object.values(inputMode)[0]);
    if (storedMode !== Object.values(inputMode)[0]) {
      console.log('clearing character');
      setDescriptionInputText('');
      setActiveTags([]);
      setCharacterInfo(false);
    }else{
      console.log('loading character');
      setCharacterInfo(JSON.parse(localStorage.getItem('currentCharacter')));
    }

    localStorage.setItem('inputMode', JSON.stringify(inputMode));
    setOutputsBasedOnLayout(inputMode);

    setPlaceholderText("Describe its unique features or leave blank to generate a random creation...");
  }
  if(Object.values(inputMode)[0] == 'Life Path')
    setSimpleMode(false);
}, [inputMode]);

function getPlaceHolderTextsForMode(){

switch(Object.values(inputMode)[0]){
    case "Pet":
        return shuffleArray([
            "Majestic dragon-cat, with shimmering scales and a purr that sounds like soft roars...",
    "Robot owl with LED feather tips, can record and playback messages, eyes glow in the dark...",
    "Aquatic pupper, with webbed paws and glistening fur, blows playful water bubbles when barking...",
    "Enchanted parrot, with rainbow-colored feathers, sings songs from other realms and brings good dreams...",
    "Miniature elephant, size of a house cat, with tusks made of crystal and a playful, mischievous spirit...",
        ]);
      break;
    case "Monster":
        return shuffleArray([
    "Ancient sea leviathan, with scales that refract sunlight, creating illusions of lost cities beneath the waves...",
    "Gargantuan forest spirit, moss-covered and tree-tall, eyes that harbor bioluminescent creatures...",
    "Desert mirage beast, sand-swirling form, can vanish in a gust, leaving only echoing roars behind...",
    "Ethereal wraith, made of Northern Lights, moves through tundras leaving frost in its wake...",
    "Volcanic chimera, with wings of molten lava and obsidian claws, sleeps in magma chambers..."
]);
      break;
    case "Henchman":
        return shuffleArray([
    "Royal guard, armored in rune-etched steel, commanding spectral hounds with an iron fist...",
    "Cavern scout, clad in spider silk cloaks, eyes gleaming in darkness, whispering to the winds...",
    "Swamp marauder, in vine armor, a master of poisons, lurking beneath foggy waters...",
    "Mountain sentinel, beard of icicles, wielding a star-forged hammer, voice booming like an avalanche...",
    "Urban agent, master of disguise, moving silently, loyal only to the hidden cabal..."
]);
      break;
    case "Wrestler":
        return shuffleArray([
    "Luchador with a mask adorned in gold, known for his gravity-defying aerial moves...",
    "Mammoth-sized fighter, tattoos of past victories, master of devastating power slams...",
    "Technician in sleek trunks, methodically breaks down foes with precision holds...",
    "Outlaw biker with a leather vest, infamous for brawling outside the ring...",
    "Mystical warrior draped in cloaks, entrances the crowd with arcane rituals before each match..."
]);
      break;
      case "RPG Item":
        return shuffleArray([
    "A gemstone that pulses with the heartbeat of a long-forgotten dragon...",
    "Ancient scroll sealed with a phantom wax seal, whispers its contents only under moonlight...",
    "Ornate dagger, its hilt entwined with vines, leaves a trail of blooming flowers with each swing...",
    "Mystical amulet, crafted from a star fragment, grants the wearer visions of distant galaxies...",
    "Enchanted flask filled with a shifting liquid, each sip offers a different magical effect..."
]);
      break;
      case "Weapon":
        return shuffleArray([
    "Sword forged from a fallen star, glowing with an ethereal light, said to control time...",
    "Bow crafted from ancient dragon bone, its arrows whisper secrets of their target upon release...",
    "Staff imbued with the soul of a forgotten forest, allowing the wielder to command nature's wrath...",
    "Dagger with a blade made of shadow, becoming intangible, capable of piercing through any defense...",
    "Hammer with a core of a captured thunderstorm, every strike echoing with the roar of the skies..."
]);
      break;
      case "Vehicle":
        return shuffleArray([
    "Airship built from the wood of sky-towering trees, sails catching the winds of destiny, capable of navigating through the stars...",
    "Carriage drawn by spectral steeds, shimmering with ghostly flames, traversing realms seen and unseen at the whim of its master...",
    "Submarine wrought from the scales of a leviathan, diving into the abyss where light fears to tread, guided by the whispers of the deep...",
    "Motorcycle infused with lightning, its wheels a blur of speed and sparks, racing ahead of time, leaving trails of fire in its wake...",
    "Sled carved from eternal ice, pulled by wolves born from the northern lights, gliding across snow as if moving through dreams..."
]);
break;
    case "Clothing":
    return shuffleArray([
        "Robe woven from moonlit silk, shimmering in the darkness, rumored to be imbued with the night's secrets...",
        "Cloak made from the feathers of a phoenix, offering warmth and protection, said to rejuvenate its wearer...",
        "Boots formed from the hide of a mountain beast, granting unmatched stability and a bond to the earth...",
        "Tiara with a gem birthed from a tear of the sky, radiating a soft glow, said to bestow clarity and wisdom...",
        "Tunic dyed with the colors of the dawn, soft to touch and said to fill the wearer with hope and energy..."
    ]);
    break;

    case "Armor":
    return shuffleArray([
        "Plate armor forged in dragon's flame, its metal impenetrable and said to shield its bearer from the fiercest of attacks...",
        "Armor crafted from the scales of an ancient sea serpent, providing unparalleled defense and a connection to the deep...",
        "Breastplate made from the hide of a legendary behemoth, its sheer strength intimidating foes on sight...",
        "Greaves imbued with the essence of a thunderstorm, granting its wearer the swiftness of lightning and the roar of thunder...",
        "Helmet adorned with the horns of a dire wolf, enhancing the senses and instilling fear in the hearts of enemies..."
    ]);
    break;

  case "Faction":
    return shuffleArray([
        "A guild of shadowy infiltrators, known for their silent footfalls and whispers in the dark, masters of espionage and sabotage...",
        "A coven of ancient sorcerers, wielding arcane magics forgotten by time, their knowledge of the mystical arts both a blessing and a curse...",
        "A brotherhood of celestial knights, sworn to uphold the cosmic balance, their blades drawn only for the cause of justice and order...",
        "A clan of desert nomads, survivors of the harshest sands, their endurance is unrivaled in the wastelands they call home...",
        "A society of alchemical experimenters, ever in pursuit of the transmutation of base elements into gold..."
    ]);
    break;

  case "McGuffin":
    return shuffleArray([
      "An ancient tome that holds the secret to eternal life, but its pages are scattered across the world...",
      "A small, unassuming stone that pulses with the heartbeat of a forgotten god...",
      "A crystal shard that contains the last fragment of a dying star's light...",
      "A mysterious amulet that can alter the fabric of reality, but only at a great cost...",
      "A relic from a lost civilization that is said to unlock the gateway to other dimensions...",
      "A blackened feather said to be from an angel, capable of restoring a fallen hero's soul...",
      "A key made of pure shadow, which can open any door, but only in the realm of dreams...",
      "A golden apple that grants the wisdom of the ancients to whoever takes the first bite...",
      "A mirror that shows not the reflection, but the true essence of anyone who gazes into it...",
      "An hourglass filled with sand from the beginning of time, rumored to have the power to rewind the past..."
    ]);


    case "Equipment":
        return shuffleArray([
      "A golden compass that always points to the holder's deepest desire...",
    "Crystal-tipped staff that can harness the ambient magic of its surroundings...",
    "Leather boots imbued with the essence of wind, allowing the wearer to tread lightly over any terrain...",
    "Silver locket that captures and stores the light of different moon phases...",
    "A gauntlet forged from dragon scales, granting the wielder command over flame..."

]);
      break;
      case "Micro Location":
        return shuffleArray([
    "A hidden alcove behind a waterfall, where moonlight casts runes on the walls...",
    "An ancient oak tree with a door carved into its trunk, emitting soft, melodic hums...",
    "A derelict subway car, overgrown with luminous mushrooms, where forgotten memories linger...",
    "A rooftop garden in a bustling city, where rare, magical herbs sway to an unseen wind...",
    "A floating market stall on a misty lake, selling dreams captured in tiny glass jars..."
]);
      break;
      case "Macro Location":
        return shuffleArray([
    "A floating archipelago, where islands drift on clouds, casting ever-shifting shadows on the land below...",
    "Endless desert of crystalline sand, with oasis towns built inside giant, luminescent geodes...",
    "Interstellar nebula, where civilizations thrive on drifting asteroids, connected by bridges of stardust...",
    "A vast underground kingdom, illuminated by glowing fungi forests and rivers of molten gold...",
    "Enchanted rainforest, with trees tall as mountains and ancient spirits guarding sacred groves..."
]);
      break;
    case "Wrestling Arena":
        return shuffleArray([
    "A grand coliseum, golden statues of legendary wrestlers at each entrance, roaring crowds echo through time...",
    "Underwater dome, where wrestlers grapple amidst floating jellyfish and the weightless sensation adds an extra challenge...",
    "Post-apocalyptic wasteland, surrounded by remnants of old structures, with a ring made from scavenged metal and ropes of braided vines...",
    "Floating platform above a volcano, where the heat intensifies the drama, and every slam echoes with the volcano's rumble...",
    "Enchanted forest clearing, illuminated by bioluminescent plants, with mythical creatures as the audience and a ring made of intertwined branches..."
]);
      break;
    case "World":
    return shuffleArray([
        "An oceanic world, where colossal sea creatures roam beneath the waves and cities float on the surface, interconnected by webbed networks of boats and bridges...",
        "A realm of floating islands in the sky, each with its own unique ecosystem and culture, connected by airships and teleportation portals...",
        "A planet enveloped in a perpetual winter, with civilizations built inside warm geothermal caves beneath the icy surface, and auroras lighting up the night sky...",
        "A world of dense jungle canopies, teeming with vibrant and exotic wildlife, where ancient ruins hide secrets of a long-forgotten civilization...",
        "A vast expanse of interconnecting biomes, each governed by its own natural laws, where magic and technology coexist and evolve in harmonious synchrony...",
        "A realm where time flows differently, featuring ancient futuristic cities, medieval villages, and primordial landscapes all coexisting simultaneously...",
        "An ever-dark world, illuminated only by bioluminescent flora and fauna, with cultures and creatures adapted to life in near-perpetual night...",
        "A celestial realm of floating cosmic bodies, where inhabitants live on the surfaces of comets, planets, and stars, connected by mystical energy streams..."
    ]);

      break;
    case "NPC":
    return shuffleArray([
        "Desert alchemist, brews sand into glass with a touch, whispers to the wind for secrets, travels by sandstorm...",
        "Clockwork librarian, caretaker of an ancient library, keys for fingers to unlock any knowledge, eyes gleam with the light of forgotten lore...",
        "Shadow tailor, stitches darkness into cloaks and gowns, moves unseen in the night, collects nightmares for thread...",
        "Mountain bard, voice echoes like thunder, strums a lyre that causes avalanches, keeps company with eagles and spirits...",
        "Underwater explorer, rides currents with a seahorse steed, speaks with the fish, maps the ocean's mysteries with ink that flows like water...",
        "Village blacksmith, forges weapons imbued with magical runes, rumored to have made a sword that can cut through darkness itself...",
        "Wandering minstrel, carries tales and songs from town to town, plays a lute with strings that shimmer like moonlight, believed to soothe even the most troubled hearts...",
        "Herbalist healer, dwells in a cottage surrounded by enchanted plants, brews potions and salves that can heal or harm, guarded by a wise old owl..."
    ]);

      break;
    default:
      return placeholders;
      break;
    }
}
useEffect(() => {
  if(imageStyle && imageStyle.length > 0){
    localStorage.setItem('imageStyle', imageStyle);
  }
}, [imageStyle]);


function setFieldsFromInputMode(inputMode){
  switch(Object.values(inputMode)[0]){
      case "NPC":
        setCInputFields(FieldsCharacterNPC);
      break;
    case "Pet":
        setCInputFields(FieldsCharacterPet);
      break;
      case "Character":
        setCInputFields(FieldsCharacterDetailed);
      break;
    case "Monster":
        setCInputFields(FieldsCharacterMonster);
      break;
    case "Henchman":
        setCInputFields(FieldsCharacterHenchman);
      break;
    case "Wrestler":
        setCInputFields(FieldsCharacterWrestler);
      break;

      case "RPG Item":
        setCInputFields(FieldsItemDetailed);
      break;
      case "Weapon":
        setCInputFields(FieldsItemWeapon);
      break;
    case "Equipment":
        setCInputFields(FieldsItemEquipment);
      break;
    case "Clothing":
        setCInputFields(FieldsItemClothing);
      break;
    case "Armor":
        setCInputFields(FieldsItemArmor);
      break;
    case "Vehicle":
        setCInputFields(FieldsItemVehicle);
      break;


      case "Micro Location":
        setCInputFields(FieldsLocationMicro);
      break;
      case "Macro Location":
        setCInputFields(FieldsLocationMacro);
      break;
      case "Wrestling Arena":
        setCInputFields(FieldsLocationMicroWrestlingArena);
      break;
    case "World":
        setCInputFields(FieldsLocationWorld);
      break;
      

      case "Life Path":
        setCInputFields(FieldsNarrativeLifePath);
      break;
      case "Faction":
        setCInputFields(FieldsNarrativeFaction);
      break;
      case "McGuffin":
       setCInputFields(FieldsNarrativeMcGuffin);
      break;
    }
}

function setOutputsBasedOnLayout(inputMode){

  switch(Object.values(inputMode)[0]){
      case "World":
        setOutputOptions({
          'Communication and Information': false,
          'Conflict and Cooperation': true,
          'Cultural and Societal Convergence': false,
          'Economic Systems and Resource Management': true,
          'Ethical and Philosophical Perspectives': false,
          'Historical and Future Trajectories': true,
          'Influential Figures and Leadership': false,
          'Interplay of Power and Governance': true,
          'Mysteries and Discoveries': false,
          'Technology and Magic': true,
          'Transport and Logistics': false,
      });
      break;
      case "Monster":
        setOutputOptions({
          'Physical Characteristics': true,
          'Psychological Profile': true,
          'Legends and Sightings': true, 
          'Mythology and Symbolism': false,
          'Relationship with Other Species': false,
          'Special Phenomena': false,
          'Threat Assessment': true,
          'Artistic Representations': false,
          'Combat and Survival': false,
          'Conservation Status': false,
          'Containment and Control Measures': false,
          'Cultural and Historical Context': false,
          'Ecological Impact': false,
      });
      break;
      case "Henchman":
        setOutputOptions({
          'Purpose/History': true,
          'Leadership/Hierarchy': true,
          'Equipment/Tactics': true,
          'Operational Framework': false, 
          'Headquarters/Hangouts': false,
          'Appearance': false,
          'Training/Conditioning': false,
          'Communication/Symbology': false,
      });
      break;
    case "Faction":
        setOutputOptions({
          'Ideological Overview': true,
          'Faction Origins': true,
          'Purpose': true,
          'Culture': true,
          'Internal Conflicts': false,
          'Membership Demographics': false,
          'Alliances': false,
          'Current Leader': false, 
          'Daily Life': false,
          'Faction Quirks': false,
          'Feuds': false,
      });
      break;
    case "McGuffin":
        setOutputOptions({
          "Appearance/Value":true,
          "Consequences of Use":true,
          "Purpose/Function":true,
          "Quest to Find Item":true,
          "Quest to Use Item":false,
          "Quest to Destroy Item":false,
          "Creation/History":false,
          "Cultural Significance":false,
          "Mystery/Intrigue":false,
          "Negative Effects on User":false,
          "Positive Effects on User":false,
      });
      break;
    case "Pet":
        setOutputOptions({
          'Appearance and Aesthetics': true,
          'Communication and Behavior': true,
          'Description': true, 
          'Function and Role': false,
          'Important Accessories': false,
          'Interaction with Owner': false,
          'Memorable Experiences': false,
          'Origins and Background': false,
          'Preferences': true,
          'Restrictions and Limitations': false,
          'Social Behavior and Relationships': false,
          'Special Skills and Abilities': false,
          'Training and Abilities': false,
      });
      break;
      case "NPC":
        setOutputOptions({
          'Backstory and History': true,
          'Cultural Background': false,
          'Daily Routine': true,
          'Favorite Locations': false,
          'Fears and Phobias': false,
          'Goals and Aspirations': false,
          'Hobbies and Interests': false,
          'Likes and Dislikes': false,
          'Personal Challenges and Conflicts': false,
          'Political Affiliations': false,
          'Religious Beliefs': false,
          'Reputation and Influence': false,
          'Role Within Faction': true,
          'Secrets and Mysteries': false,
          'Services on Offer': true,
          'Skills and Services': false,
          'Types of Items for Sale': false,
          'Types of Quest OfferedWealth and Status': false,
      });
      break;
      case "Character":
        setOutputOptions({
          'Memorable Quote': true,
          'First Person Description': false,
          'Third Person Description': true,
          'Battle Cry': false,
          'Past Experiences': true,
          'Relationships and Social Interactions': false,
          'Reputation and Influence': false,
          'Strengths and Weaknesses': false,
          'Taunts and Threats': false,
          'Goals and Aspirations': false,
          'Important Items': true,
          'Interests and Lifestyle': false, 
          'DnD Stats': false
        });
      break;
    case "Wrestler":
        setOutputOptions({
          'Describe Appearance': true,
          'Entrance and Promos': true,
          'Goals and Aspirations': true,
          'Introduce Yourself': false,
          'Introduction by Ring Official': true,
          'Promos and Catchphrases': false,
          'Reputation and Influence': false,
          'Wrestling Journey': false
        });
      break;

      case "RPG Item":
        setOutputOptions({
          'Object Description': true,
          'Appearance and Craftsmanship': true,
          'Beliefs and Superstitions': false,
          'Cultural Significance': false,
          'Curses and Side Effects': false,
          'Effects on Owner': false,
          'Famous Past Owners': true,
          'Give Immersive Response': false,
          'Hidden Powers': false,
          'Historical Events': true,
          'Legends and Myths': false,
          'Materials and Function': false,
          'Origin and Creation Process': false,
          'Requirements and Rituals': false,
          'Rumours and Legends': false,
          'Sentience, Personality and Quirks': false,
          'Special Abilities and Features': false,
          'Special Interactions': false,
          'Value and Acquisition Methods': false
        });
      break;

    case "Weapon":
        setOutputOptions({
          
          'Materials and Forge Method': true,
          'Weapon Type and Function': true,
          'Combat Tactics': true,
          'Weapon-Specific Abilities': false,
          'Weapon Maintenance': false,
          'Weapon Craftsmanship': false,
          'Value, Rarity, and Acquisition': false,
          'Special Weapon Interactions': false,
          'Potential Drawbacks and Curses': false,
          'Notable Wielders': false,
          'Myths and Legends': false,
          'Legendary Fights': false,
          'History and Origin': false,
          'Hidden Features': false,
          'Famed Battles and Wars': true,
          'Cultural Symbolism': false,
          'Consequences of Misuse': false,
          'Weapon Sentience and Personality': false,
          'Attunement Rituals': false,
          'Artistic and Aesthetic Details': false
        });
      break;

    case "Clothing":
        setOutputOptions({
          'Appearance and Craftsmanship': true,
          'Benefits and Drawbacks': true,
          'Clothing Function and Utility': true,
          'Color Pattern and Style': false,
          'Concealed Features': false,
          'Cultural Significance': false,
          'Famous Wearers': false,
          'Fashion Influences': false,
          'Garment Description': false,
          'Historical Context': true,
          'Material and Construction': false,
          'Place of Origin': false,
          'Size and Fit': false,
          'Stories and Legends': false,
          'Traditional Symbolism': false,
          'Value and Acquisition Methods': false,
          'Wear and Durability': false,
        });
      break;

    case "Armor":
        setOutputOptions({
          'Appearance and Craftsmanship': true,
          'Benefits and Drawbacks': true,
          'Cultural and Historical Significance': true,
          'Design and Decorations': false,
          'Function and Utility': false,
          'Materials and Construction': false,
          'Origin and History': true,
          'Protection and Performance': false,
          'Symbolism and Significance': false,
          'Value and Acquisition': false,
          'Wear and Durability': false
        });
      break;

  case "Equipment":
        setOutputOptions({
          
          'Design and Mechanics': true,
          'Efficacy and Adaptability': true,
          'Importance and Legends': true,
          'Innovation and Rarity': false,
          'Materials and Craftsmanship': false,
          'Operational Complexity': false,
          'Origin and Importance': false,
          'Purpose and Function': false,
          'Reliability and Maintenance': false,
          'Users and Context': true,
        });
      break;
    case "Vehicle":
      setOutputOptions({
        'Historical Significance and Legacy': true,
        'User Experience and Interface': true,
        'Engineering and Design': true,
        'Operational and Deployment History': true,
        'Armor and Defences': false,
        'Customization and Personalization': false,
        'Engine Design and Performance': false,
        'Famous Past Drivers': false,
        'Tactical and Strategic Use': false,
        'Technological Innovations and Anomalies': false,
        'Weapons and Special Features': false,
        'Anecdotes and Legends': false
         });
      break;


    case "Micro Location":
        setOutputOptions({
          'Unique Aspects and Features':true,
          'Surrounding Context': true,
          'Structural and Design Details': true,
          'Status and Conditions': false,
          'Security and Accessibility': false,
          'Purpose and Function': false,
          'Notable Objects and Elements': false,
          'Environment and Atmosphere': false,
          'Dangers and Threats': false,
          'Environmental Effects': false,
          'Items and Loot': false,
          'Interactive Elements': true,
          'Cultural and Historical Significance': false
        });
      break;
      case "Macro Location":
        setOutputOptions({
          'Cultural and Historical Significance': false,
          'Demographics and Society': true,
          'Economic and Industrial Aspects': false,
          'Education and Technology': false,
          'Geographic and Natural Features':true,
          'Governance and Politics': false,
          'Health and Environment': false,
          'Infrastructure and Architecture':true,
          'Leisure and Entertainment': false,
          'Religion and Beliefs': true,
          'Dangers and Threats': false,
          'Environmental Effects':false,
          'Interactive Elements': false,
          'Items and Loot':false
        });
      break;
    case "Wrestling Arena":
        setOutputOptions({
          'Accessibility and Location': false,
          'Audience and Attendance': false,
          'Backstage and Technical Aspects': false,
          'Events and Traditions': true,
          'Future Prospects and Plans': false,
          'Historical Significance': true,
          'Physical Structure and Design': true,
          'Security and Safety Measures': false,
          'Unique Aspects and Features': true
        });
      break;
      default:
        setOutputOptions({
          'Memorable Quote': true,
          'First Person Description': false,
          'Third Person Description': true,
          'Battle Cry': false,
          'Past Experiences': true,
          'Relationships and Social Interactions': false,
          'Reputation and Influence': false,
          'Strengths and Weaknesses': false,
          'Taunts and Threats': false,
          'Goals and Aspirations': false,
          'Important Items': true,
          'Interests and Lifestyle': false, 
          'DnD Stats': false
        });
      break;
    }
}

function setInputField(name, value, customField = false, heading = '') {
  setCInputFields(prevFields => {
    const fieldExists = prevFields.some(field => field.name === name);
    
    // Initialize newFields with prevFields
    let newFields = [...prevFields];
      
    if (fieldExists) {
      newFields = prevFields.map(field => 
        field.name === name 
          ? { ...field, value: value } 
          : field
      );
    } else if(customField){
      newFields = [...prevFields, { name: name, value: '', custom: true, heading: '' }];
    }
    saveCustomFieldsToLocal(newFields);
    return newFields;
  });
}


function removeInputField(fieldToRemove){
  setCInputFields(prevFields => {
    const fieldExists = prevFields.some(field => field.name === fieldToRemove);

    let newFields;
    if (fieldExists) {
      newFields = prevFields.filter(field => field.name !== fieldToRemove);
    } else {
      newFields = prevFields;
    }

    saveCustomFieldsToLocal(newFields);
    return newFields;
  });
}


  useEffect(() => {
   
}, [c_inputfields]);

function saveCustomFieldsToLocal(customFields){
  if (!customFields) {
    return;
  }

  const filteredCustomFields = customFields.filter(field => field.custom);
  localStorage.setItem('c_customfields', JSON.stringify(filteredCustomFields));
}

  var headings = {};
  c_inputfields.forEach(input => {

    if(input && input.heading !== ""){
      if(Object.values(inputMode)[0] == 'Life Path'){
        /*
        let lpName = input.intructions;
        let lpHeading = input.name;
        input.name = lpName;
        input.heading = lpHeading;
      */
        
      }
      if (!headings[input.heading] ) {
          headings[input.heading] = [];
      }
      headings[input.heading].push(input);
    }
  });

  
  const handleButtonClickShowOutputs = (event) => {
    setOutputModalMaxOutputs(4);
    event.preventDefault(); // prevent default action of <a> tag
    setModalMode('outputs');
    setModalOpen(true);
  };

  const showInputsModal = (event) => {
    event.preventDefault(); // prevent default action of <a> tag
    setModalMode('inputs');
    setModalOpen(true);
  };

  

  const handleButtonClickShowOutputsChangeMax = (event) => {
    loadCharacterFromLocalStorage();
    let trueCount = 0;
    for(let key in outputOptions) {
        if(outputOptions[key] === true) {
            trueCount++;
        }
    }
    var maxOutputs = trueCount + 4;
    setOutputModalMaxOutputs(maxOutputs);
    event.preventDefault();
    setModalMode('outputs createdCharacter');
    setModalOpen(true);
  };
  
  const closeModal = (e) => {
    if(e)
    e.preventDefault(); // prevent default action of <a> tag
    setModalOpen(false);
    setModalMode('');
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation(); // this will stop the click event from propagating to the parent
  };




  const [overwriteRandom, setOverwriteRandom] = useState(false);

  const [characterAttributes, setCharacterAttributes] = useState('');

  useEffect(() => {
    const charInfoFromLocalStorage = localStorage.getItem('currentCharacter');
    if (charInfoFromLocalStorage) {
      setCharacterInfo(JSON.parse(charInfoFromLocalStorage));
    }
  }, []);


  var [customFieldName, setCustomFieldName] = useState('');

  const [history, setHistory] = useState([]);
  const [lastImagePrompt, setLastImagePrompt] = useState("");


const [openAccordions, setOpenAccordions] = useState([0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

function storeTokenDetails(tokenCount, tokenReset) {
    localStorage.setItem('tokenCount', tokenCount);
    localStorage.setItem('tokenReset', tokenReset);
}

function shouldContinueRequest() {
    const tokenCount = parseInt(localStorage.getItem('tokenCount'), 10);
    const tokenReset = new Date(localStorage.getItem('tokenReset'));
    const now = new Date();
    if (now <= tokenReset && tokenCount < 25) {
        return false;
    }
    return true;
}

function fetchWithTimeout(url, options = {}, timeout = 45000) {
    return new Promise((resolve, reject) => {
        if (!shouldContinueRequest()) {
            const mock429Response = new Response(null, {
                status: 429,
                statusText: 'Too Many Requests'
            });
            resolve(mock429Response);  // Note that we're resolving (not rejecting) with the mock response
            return;
        }

        // Set up the timeout
        const timer = setTimeout(() => {
            reject(new Error('Request timed out'));
            alert('Something went wrong...');
            window.location.reload();
        }, timeout);

        // Make the fetch request
        fetch(url, options).then(response => {
            const tokenCount = response.headers.get('X-Token-Count');
            const tokenReset = response.headers.get('X-Token-Reset');

            // Store the token details in localStorage
            storeTokenDetails(tokenCount, tokenReset);

            clearTimeout(timer);  // If the request completes successfully, clear the timer
            resolve(response);
        }).catch(err => {
            clearTimeout(timer);  // If an error occurs, clear the timer
            reject(err);
            alert('Something went wrong...');
            window.location.reload();
        });
    });
}




const saveCharacter = async (event) => {
  event.preventDefault();
  setIsSaving(true);
  const token = localStorage.getItem('token');
  var isPlusMember = isPlusMemberCheck();

  if (!token) { //if not logged in show modal
    toggleLoginModal();
    setIsSaving(false);
  } else {
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/save/';

    if (url.indexOf('charactercomposer.com') > -1) {
      apiUrl = 'https://charactercomposer.com/save/';
    }
    const formValues = history[history.length - 1];
    const requestBody = {
      formValues: characterInfo.attributes,
      description: characterInfo.description,
      imageUrl,
      characterId,
      imagePrompt: lastImagePrompt,
      type: JSON.stringify(inputMode),
      simpleMode: isSimpleMode
    };

    try {
      const res = await fetchWithTimeout(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324",
          'Authorization': `Bearer ${token}`,
         ...(isPlusMember ? { 'plus-member': `true` } : {})
        },
        body: JSON.stringify(requestBody),
      });

      if (res.status === 400) {
        const result = await res.json();
        setErrorMessage(result.error);
        setIsSaving(false);
        return;
      }

      const result = await res.json();

      if (result.error) {

      } else if (result._id) {
        setCharacterId(result._id);
        toggleSaveSuccessModal();
      }
      setIsSaving(false);
    } catch (error) {
      alert('Something went wrong...');
      window.location.reload();
      console.error('Error in fetch:', error);
    }
  }
};

if(localStorage.viewType === undefined)
  localStorage.setItem('viewType', 'false');
var storageView = false;
if(localStorage.viewType === 'true')
  storageView = true;
const [singleColumn, setSingleColumn] = useState('true');



const toggleColumns = (e) => {
  if(e){
    e.preventDefault();
    e.stopPropagation();
  }
  localStorage.setItem('viewType', !singleColumn); 
  setSingleColumn(prevState => !prevState);
   
};
const columnClass = singleColumn
    ? 'inputs-columns single-column'
    : 'inputs-columns';


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAccordionClick = (e, index) => {
  e.preventDefault();
  e.stopPropagation();

  setOpenAccordions((prevOpenAccordions) => {
    generateRandomFieldsForAccordion(index)
    if (window.innerWidth < 60000) {
      // If the screen size is less than 600px, only the most recently clicked accordion should be open
      return [index];
    } else {
      // Otherwise, continue with the previous behavior
      if (prevOpenAccordions.includes(index)) {
        if (prevOpenAccordions.length > 0) {
          return prevOpenAccordions.filter((i) => i !== index);
        }
      } else {
        return [...prevOpenAccordions, index];
      }
    }
    return prevOpenAccordions; // Keep the current state if no changes are made.
  });
};

const generateRandomFieldsForAccordion = async (accordionIndex) => {
  // Check if at least 3 other fields are filled in
   const filledFieldsCount = c_inputfields.filter(field => field.value).length;
  if (filledFieldsCount < 2) {
    return; // Exit if less than 3 fields are filled
  }
  
  const accordionKeys = Object.keys(processedHeadings);

  if (accordionIndex < 0 || accordionIndex >= accordionKeys.length) {

    if(accordionIndex == 11 && c_inputfields[0].value =='')
      await handleGetProperty(false);
  }
  let headingName = accordionKeys[accordionIndex]

  var fieldsToUpdate = false;
  //get all with current fields under than heading (consider simple mode or not)
  if (isSimpleMode) {
    fieldsToUpdate = c_inputfields
      .filter((field) => field.simple === true)
      .map((field) => field.name);
  } else {
    fieldsToUpdate = c_inputfields
      .filter((field) => field.heading === headingName || field.heading == 'Basic')
      .map((field) => field.name);
  }

  fieldsToUpdate = fieldsToUpdate.filter(fieldName => {
    const fieldObject = c_inputfields.find(f => f.name === fieldName);
    return !fieldObject.value; // filter fields that are empty (i.e., value is falsy)
  });

  //for each fieldsToUpdate, get random field
   const randomFields = await Promise.all(fieldsToUpdate.map(field => handleGetRandom(false, field, false)));

  for (let i = 0; i < fieldsToUpdate.length; i++) {
    setInputField(fieldsToUpdate[i], randomFields[i], true);
  }
};

function objectToArray(obj) {
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
}
useEffect(() => {
  hideDiceIfAllFilled();
  areAllInputsBlank();
  areAllInputsSet();
}, [ c_inputfields ]);

  function hideDiceIfAllFilled(){
    if(areAllInputsSet()){
      setFormFilled(true);
    }
    else{
      setFormFilled(false);
    }
    return true;
  }

function areAllInputsSet() {
  return (
    //if isSimpleMode then only check fields with simple:true
    isSimpleMode ? c_inputfields.filter(field => field.simple === true).every((field) => field.value !== "") : c_inputfields.every((field) => field.value !== "")
  );
}

function areAllInputsBlank() {
  return (
    c_inputfields.every((field) => field.value === "")
  );
}

function setActiveTag(tag){
  if(activeTags.includes(tag)){
    
  }
  else{
    setActiveTags([...activeTags, tag]);
  }
}

  const resetForm = async (event) => {
    setRandomLoading(true);
    setEditingSavedCharacterId(false);
    setDescriptionInputText('');
    setActiveTags([]);
    handleClear(event);
    await delay(200);
    setRandomLoading(false);
  };

  const handleSettingsOpen = (event = false) => {
    setModalMode('settings');
    setModalOpen(true);
  };

  const handleClear = (event = false) => {
    return new Promise((resolve) => {
    if(event)
      event.preventDefault();
    setDalle3(false);
    setShownInputWarning(false);
    setFormFilled(false);
    const clearedInputFields = c_inputfields.map((field) => {
      return { ...field, value: '' };
    });
    setCInputFields(clearedInputFields);
    setOutput('');
    setImageStyle('Digital Art');
    setOutputsBasedOnLayout(inputMode);
    // Add a setTimeout to ensure all state updates are queued before resolving the promise
    setTimeout(() => {
      resolve();
    }, 0);
    });
  };

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleDescriptionParent = async (event) => {
  setModalOpen(false);
  let  randomResult = await handleRandomParent(event);

};

const handleLifePath = async (event = false, narrative = false, stage = 1, userChoices = false, initialInputs = false, regenerate = false, optionsPrompt = false) => {
  console.log('optionsPrompt: ' + optionsPrompt);
  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/lifepath/';

  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/lifepath/';
  }

  const token = localStorage.getItem('token');
  var isPlusMember = isPlusMemberCheck();

  var optionsOnly = false;
  if(narrative && userChoices == false && regenerate == false)
    optionsOnly = true;
  if(userChoices)
    setUserChoices(userChoices);
  var inputs = initialInputs ? initialInputs : process_form_inputs(c_inputfields);
  if(!inputs['Life Path Story Description'] && descriptionInputText){
    inputs['Life Path Story Description'] = descriptionInputText;
  }
  const requestBody =  {
        inputs: inputs,
        type: Object.values(inputMode)[0],
        simpleMode: isSimpleMode,
        lifePathStage:stage,
        userChoices: userChoices,
        narrative,
        optionsOnly,
        characterId: lifePathId,
        optionsPrompt
      };
      
  let retryCount = 0;
  const maxRetryCount = 3; // Maximum retry attempts
  let parsedResult = null;

  while (!parsedResult && retryCount < maxRetryCount) {
    const res = await fetchWithTimeout(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
         ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      },
      body: JSON.stringify(requestBody),
    });
    //if run out of tokens
    if (res.status === 429) {
        setGenericMessage('Token limit reached');
        toggleLoginModal();
        setLoading(false);
        setRandomLoading(false);
        return;
      }

    const result = await res.json();

    if (result && result.message) {
    parsedResult = parseGptOutputToJson(result.message);

    if(!optionsOnly && parsedResult){
      //check for options and rerun if none
      var getChoicesAgain = true;
      if(parsedResult['Option 1'] && parsedResult['Option 1'].length > 0)
        getChoicesAgain = false;

      if (stage < 6 && getChoicesAgain && result.concludeStory == false) {
        if(result.concludeStory == false){
          console.log('missing options when we should have some...');
          await handleLifePath(false, lifePath, stage, false, initialInputs);
        }
      }
      if(stage == 6 || result.concludeStory == true){
        console.log('making image now at stage: ' + stage);
        setCharacterInfo(false);
        localStorage.setItem('currentCharacter', JSON.stringify(false));
        var currentCharacter = {};
        currentCharacter.attributes = initialInputs;

        if(lifePath["Life Path Stage 1"]?.Narrative)
          currentCharacter.description = lifePath["Life Path Stage 1"].Narrative;
        if(userChoices["lifePathStage_answer_1"])
          currentCharacter.description +=  " " + userChoices["lifePathStage_answer_1"];

        if(lifePath["Life Path Stage 2"]?.Narrative)
          currentCharacter.description += " " + lifePath["Life Path Stage 2"].Narrative;
        if(userChoices["lifePathStage_answer_2"])
          currentCharacter.description += " " + userChoices["lifePathStage_answer_2"];

        if(lifePath["Life Path Stage 3"]?.Narrative)
          currentCharacter.description += " " + lifePath["Life Path Stage 3"].Narrative;
        if(userChoices["lifePathStage_answer_3"])
          currentCharacter.description += " " + userChoices["lifePathStage_answer_3"];

        if(lifePath["Life Path Stage 4"]?.Narrative)
          currentCharacter.description += " " + lifePath["Life Path Stage 4"].Narrative;
        if(userChoices["lifePathStage_answer_4"])
          currentCharacter.description += " " + userChoices["lifePathStage_answer_4"];

        if(lifePath["Life Path Stage 5"]?.Narrative)
          currentCharacter.description += " " + lifePath["Life Path Stage 5"].Narrative;
        if(userChoices["lifePathStage_answer_5"])
          currentCharacter.description += " " + userChoices["lifePathStage_answer_5"];

        if(lifePath["Life Path Stage 6"]?.Narrative)
          currentCharacter.description += " " + lifePath["Life Path Stage 6"].Narrative;  

        //setCharacterId(result._id);
        currentCharacter.id=result._id;
        console.log('short life path');
        console.log(result.lifePathSummary);
        console.log('using life path id: ' + currentCharacter.id);
        currentCharacter.lifePath = currentCharacter.description;
        currentCharacter.description = {};
        currentCharacter.description['Life Story Summary'] = result.lifePathSummary;
        localStorage.setItem('lifePathStage', 'conclude');
          //imageRefreshSubmit(false,false,currentCharacter, false,false, true, true);
        saveCharacterToLocalStorage(currentCharacter);
      }
    }
    
    if (parsedResult) {
        setLoading(false);
        setRandomLoading(false);
        if(stage == 1){
          setDescriptionInputText('');
          setActiveTags([]);
        }
        setLifePath(prevLifePath => {
          // If lifePath is not initialized, create a new object
          const updatedLifePath = prevLifePath ? { ...prevLifePath } : {};

          // Define the key for the current stage
          const stageKey = `Life Path Stage ${stage}`;

          // Initialize or update the current stage
          updatedLifePath[stageKey] = { 
            ...updatedLifePath[stageKey], // Preserve existing data in this stage
            ...parsedResult // Merge in new data
          };
          return updatedLifePath;
        });
        setLifePathInputs(result.cc_inputs);
        setLifePathId(result._id);
        handleClear();
    }
      else if (!parsedResult) {
        console.log('failed to get description, trying again'); 
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Waiting for 1 second before the next retry
      } else {
        alert("Something went wrong. Please try again.");
        window.location.reload();
      }
    }
  }
};

const handleSubmitParent = async (event) => {
  setModalMode('');
  setModalOpen(false);
  event.preventDefault();
  event.stopPropagation();
  if (event.nativeEvent.key === "Enter") {
    event.preventDefault();
    return;
  }
  setLoading(true);
  setImageLoading(true);
  setResponse('');
  setCharacterInfo({});
  localStorage.setItem('lifePathStage', false);
  localStorage.setItem('lifePath',false);
  setLifePath(false);
  setRandomLoading(true);
  var randomResult = false;

  if(!formFilled){
      randomResult = await handleRandomParent(event);
    }
  if(Object.values(inputMode)[0] == 'Life Path'){
    setLifePathId(false);
    setUserChoices(false);
    localStorage.setItem('currentCharacter',false);
    handleLifePath(false, false, 1, false, randomResult);
  }else{
    handleSubmit(event, randomResult);
  }
  
  if(editingSavedCharacterId == false)
    setCharacterId("");
  setEditingSavedCharacterId(false);

    setTimeout(function(){
      var attributes = document.getElementById("character-descriptions");
        attributes.scrollIntoView({ behavior: "smooth" });
  }, 1000);
    
  setTimeout(function(){
    if(!randomResult){
      var loadingBoxElement = document.getElementById("LoadingBoxContainer");
        loadingBoxElement.scrollIntoView({ behavior: "smooth" });
    }
    
  }, 200);

};

function getRandomFieldsInitial(inputBody = false){
  if(!inputBody)
    inputBody = process_form_inputs();
  for (let key in inputBody) {
        if (inputBody.hasOwnProperty(key)) {
          inputBody[key] = "";
        }
      }
      if(Object.values(inputMode)[0] == "Character"){
          ['Species', 'Goals', 'Genre',].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "NPC"){
          ['Species', 'Narrative Role', 'Genre',].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Monster"){
          ['Monster Type', 'Monster Function', 'Origin'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Pet"){
          ['Pet Type', 'Pet Function', 'Temperament'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Henchman"){
          ['Designation', 'Purpose', 'Hierarchy'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Wrestler"){
          ['Role', 'Style', 'Backstory', 'Height (ft in)'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "RPG Item"){
          ['Setting', 'Type of Object', 'Function'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Weapon"){
          ['Setting', 'Weapon Type', 'Special Effects'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Vehicle"){
          ['Setting', 'Type', 'Function'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Equipment"){
          ['Setting', 'Type of Equipment', 'Origin'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Armor"){
          ['Setting', 'Type of Armor', 'Stories and Legends'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Clothing"){
          ['Setting', 'Type of Clothing', 'Origin'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Micro Location"){
          ['Associated Activities', 'Type of Micro Location', 'Unique Features'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Macro Location"){
          ['Geographic Features', 'Type of Macro Location', 'Unique Aspects'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Wrestling Arena"){
          ['Arena Location', 'Ring Appearance', 'Unique Aspects'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "World"){
          ['General Scale and Scope', 'Status Quo and Historical Context', 'Dominant Species and Cultures'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "Faction"){
          ['Type', 'Faction Goal', 'Traditions'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }
        if(Object.values(inputMode)[0] == "McGuffin"){
          ['Object Type', 'Reasons For Seeking', 'Origin of Object'].forEach(fieldname => {
            let field = c_inputfields.find(field => field.name === fieldname);
            if (field && field.dropDownOptions) {
              inputBody[fieldname] = getRandomElement(field.dropDownOptions);
            }
          });
        }

        return inputBody;
}

const handleRandomParent = async (event, textDescription = false) => {
  if(event)
    event.preventDefault();
  const token = localStorage.getItem('token');
  var inputBody = process_form_inputs();

  var newOutputOptions = limitOutputTo3();
  setOutputOptions(newOutputOptions);

  if(areAllInputsSet() || areAllInputsBlank() && descriptionInputText == ''){
    console.log('all inputs are set/blan');
      for (let key in inputBody) {
        if (inputBody.hasOwnProperty(key)) {
          inputBody[key] = "";
        }
      }
      handleClear(event);
      if(descriptionInputText == '' && activeTags.length == 0){
        inputBody = getRandomFieldsInitial(inputBody);
      }
  }
  if(Object.keys(inputMode)[0] == 'Character'){
    if(inputBody && inputBody.Gender !== null){
      inputBody.Gender = Math.random() < 0.62 ? "Male" : "Female";
    }
  }
  
  var req_body = {};
  req_body.inputs = inputBody;
  req_body.outputOptions = newOutputOptions;
  req_body.mode = Object.values(inputMode)[0];
  req_body.type = Object.values(inputMode)[0];
  if(descriptionInputText != ""){
    req_body.descriptionInputText = descriptionInputText;
  }
  if(activeTags.length > 0)
    req_body.activeTags = activeTags;


  var randomForm = await handleGetRandom(event, false, req_body);

  
    //compare inputBody fields with randomForm and add missing fields/values
    for (let key in inputBody) {
      if (inputBody.hasOwnProperty(key) && inputBody[key] !== "") {
        randomForm[key] = inputBody[key];
      }
    }
    for (let key in randomForm) {
        if (randomForm.hasOwnProperty(key)) {
            setInputField(key, randomForm[key]);
        }
    }
  

  
  setRandomLoading(false);
  setFormFilled(true);
  return randomForm;
};

function process_form_inputs(){
  var processedInputs = {};
  c_inputfields.forEach(field => {
    if (!isSimpleMode || (isSimpleMode && field.simple) || field.custom) {
        processedInputs[field.name] = field.value;
    }
  });
  return processedInputs;
}


function limitOutputTo3(){
  const keys = Object.keys(outputOptions);
    let trueCount = 0;

    var newOutputOptions = { ...outputOptions };
    for (let i = 0; i < keys.length; i++) {
      if (newOutputOptions[keys[i]] === true) {
        trueCount++;
      }
      if (trueCount > 4) {
        newOutputOptions[keys[i]] = false;
      }
    }

    return newOutputOptions;
}

function getBaseURL() {
  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/saved/images/';
    if(url.indexOf('charactercomposer.com') > -1)
       apiUrl = 'https://charactercomposer.com/saved/images/';
  return apiUrl;
}

const handleSubmit = async (event, randomResult = null) => {
  event.preventDefault();
  setResponse('');
  setImageUrl('');
  setImagePrompt('');
  setDescriptionPrompt('');
  setLoading(true);
  setRandomLoading(true);

  var url = window.location.href;
  var apiUrl = 'http://localhost:3001/api/';

  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/api/';
  }

  const token = localStorage.getItem('token');
  var isPlusMember = isPlusMemberCheck();

  var newOutputOptions = limitOutputTo3();

  setOutputOptions(newOutputOptions);

  const requestBody = randomResult
    ? {
        inputs: randomResult,
        descriptionInputText,
        activeTags,
        outputOptions: newOutputOptions,
        type: Object.values(inputMode)[0],
        simpleMode: isSimpleMode,
        editingId: editingSavedCharacterId ? characterId : false
    }
    : {
        inputs: process_form_inputs(c_inputfields),
        descriptionInputText,
        activeTags,
        outputOptions: newOutputOptions,
        type: Object.values(inputMode)[0],
        simpleMode: isSimpleMode,
        editingId: editingSavedCharacterId ? characterId : false
      };

    var currentCharacter = {
      attributes: requestBody.inputs,
    }
    saveCharacterToLocalStorage(currentCharacter);
    loadCharacterFromLocalStorage(false, true);
      
  let retryCount = 0;
  const maxRetryCount = 3; // Maximum retry attempts
  let parsedResult = null;

  while (!parsedResult && retryCount < maxRetryCount) {
    const res = await fetchWithTimeout(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
         ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      },
      body: JSON.stringify(requestBody),
    });
    //if run out of tokens
    if (res.status === 429) {
        setGenericMessage('Token limit reached');
        toggleLoginModal();
        setLoading(false);
        setRandomLoading(false);
        return;
      }

    const result = await res.json();

    if(result && result.message){
      parsedResult = parseGptOutputToJson(result.message);
      if(parsedResult){
        var currentCharacter = {
          image: result.image,
          description: parsedResult,
          attributes: result.cc_inputs,
          imagePrompt: result.prompt_image,
          tags: result.activeTags
        }
        setResponse(result.message);
        saveCharacterToLocalStorage(currentCharacter);

        imageRefreshSubmit(false,false,currentCharacter, false,false, true);
        //save all old form inputs to a object and add that to an array called 'history'
        const oldFormValues = randomResult
        ? randomResult
        : c_inputfields;
        setHistory([...history, oldFormValues]);
        setDescriptionInputText('');
        setActiveTags([]);
        loadCharacterFromLocalStorage();
        handleClear();

      } 
      else if (!parsedResult) {
        console.log('failed to get description, trying again'); 
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Waiting for 1 second before the next retry
      } else {
        alert("Something went wrong. Please try again.");
        window.location.reload();
      }
    }
  }
     // Handle the case when parsedResult is still null after all retries
      if (!parsedResult) {
        alert("Something went wrong. Please try again.");
        window.location.reload();
      }
    setLoading(false);
    setRandomLoading(false);
    const createdCharacterElement = document.getElementById("created-character");
    createdCharacterElement.scrollIntoView({ behavior: "smooth" });
  };

  function parseGptOutputToJson(rawOutput) {
    const firstBrace = rawOutput.indexOf('{');
    const lastBrace = rawOutput.lastIndexOf('}');
    if (firstBrace === -1 || lastBrace === -1 || firstBrace >= lastBrace) {
        console.error('No valid JSON object found in the input');
        return null;
    }
    var jsonStr = rawOutput.slice(firstBrace, lastBrace + 1);
    try {
        const parsedResult = JSON.parse(jsonStr);
        return parsedResult;
    } catch (error) {
        console.error('Failed to parse GPT message:', error);
        return null;
    }
}


const setImageVisualStyle = async(event = false, noRefresh = false) => {
  event.preventDefault();
  if(noRefresh == false)
    setModalMode('imageStyle')
  else
    setModalMode('imageStyleNoRefresh')
  setModalOpen(true);
}

const handleChangeVisualStyle = (event) => {
  console.log(event);
    if(event?.target?.value){
      setImageStyle(event.target.value);
    }
  }

const handleCloseVisualStyleModal = (reload = false) => {
      if(reload)
        imageRefreshSubmit(false,false,false,imageStyle);
      setModalMode('')
      setModalOpen(false);
  }

const handleChangeImageModel = async (event) => {
  event.stopPropagation();
  console.log(event.target.checked);
  var isPlusMember = isPlusMemberCheck();
  console.log('is plus: ' + isPlusMember);
    if(event.target.checked){
      if(isPlusMember)
        setDalle3(event.target.checked);
      else{
        setDalle3(false);
        await setTimeout(function(){},200);
        var token = localStorage.getItem('token');
        if(token){
          toggleUpsellVisible();
        }
        else{
          console.log('SHOWING LOGIN');
          setGenericMessage('Plus account required for Dalle-3');
          toggleLoginModal();
        }
        
      }
    }
    else{
      setDalle3(event.target.checked);
    }
  }


const TIME_LIMIT = 2 * 60 * 1000; // 2 minutes in milliseconds
const MAX_CALLS = 5;

const isCallAllowed = () => {
    const now = Date.now();
    let calls = localStorage.getItem('imageRefreshCalls');

    if (!calls) {
      calls = [];
    } else {
      calls = JSON.parse(calls);
    }

    // Filter out timestamps older than 2 minutes
    calls = calls.filter(timestamp => now - timestamp <= TIME_LIMIT);

    if (calls.length >= MAX_CALLS) {
      return false;
    }

    // Add current timestamp and store back to localStorage
    calls.push(now);
    localStorage.setItem('imageRefreshCalls', JSON.stringify(calls));
    return true;
  };

const imageRefreshSubmit = async (event = false, randomResult = false, currentCharacter = false, visualStyle = false, redoPrompt = false, noMax = false, lifePathImage = false) => {
  if (!isCallAllowed() && !noMax) {
    alert('Please wait 2 minutes before refreshing the image again.');
    return;
  }
  if(event)
    event.preventDefault();
  setImageLoading(true);
  var apiUrl = 'http://localhost:3001/image/';
  var url = window.location.href;
  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/image/';
  }
  const token = localStorage.getItem('token');

  var isPlusMember = isPlusMemberCheck();

  if(JSON.parse(localStorage.currentCharacter).image == 'invalid_request_error' || redoPrompt)
    currentCharacter = JSON.parse(localStorage.currentCharacter);

  if(currentCharacter == false && lastImagePrompt == undefined){
    currentCharacter = JSON.parse(localStorage.currentCharacter);
  }
  if(lastImagePrompt || currentCharacter){
    var requestBody = {
        cc_inputs: JSON.parse(localStorage.currentCharacter).attributes,
          currentCharacter: JSON.parse(localStorage.currentCharacter),
          prompt: lastImagePrompt,
          visual_style: visualStyle || imageStyle,
          dalle3,
          activeTags,
          type: inputMode,
          simpleMode: isSimpleMode,
          id:currentCharacter.id,
          editingId: editingSavedCharacterId ? characterId : false
        };
    if(currentCharacter){
      console.log('cc inputs for image: ' );
      console.log(currentCharacter.attributes);
        requestBody = {
          cc_inputs: currentCharacter.attributes,
          currentCharacter: currentCharacter,
          visual_style: visualStyle || imageStyle,
          dalle3,
          activeTags,
          type: inputMode,
          simpleMode: isSimpleMode,
          id:currentCharacter.id,
          editingId: editingSavedCharacterId ? characterId : false
        };
      }
    const res = await fetchWithTimeout(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
         ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      },
      body: JSON.stringify(requestBody),
    });
    if (res.status === 429) {
        setGenericMessage('Token limit reached');
        toggleLoginModal();
        setImageLoading(false);
        setLoading(false);
          setRandomLoading(false);
        return;
      }
      const result = await res.json();
      if(result){
        if(result.openai_image_link !== 'invalid_request_error'){
            let characterData = JSON.parse(localStorage.getItem('currentCharacter'));
            characterData.image = getBaseURL() + result.image; 
            characterData.imagePrompt = result.prompt_image; 
            characterData.id = result._id;
            setCharacterId(result._id);
            saveCharacterToLocalStorage(characterData);
            loadCharacterFromLocalStorage();
            setImageUrl(result.openai_image_link);
        }
        const createdCharacterElement = document.getElementById("created-character");
        createdCharacterElement.scrollIntoView({ behavior: "smooth" });
        await delay(200);
      }
      else{
        alert("Something went wrong. Please try again.");
        window.location.reload();
      }
    }
    setImageLoading(false);

  };

const handleAddCustomField = (event) => {
  event.preventDefault();

  if (customFieldName.trim() !== '') {
    // Check for duplicates
    var isDuplicate = false;

    c_inputfields.forEach(input => {
      if(input.name.toLowerCase() === customFieldName.toLowerCase()){
        isDuplicate = true;
      }
    });

    if (!isDuplicate) {
      //update inputs here
      setInputField(customFieldName,"", true);  
      setCustomFieldName('');
    } else {
      alert('A field with this name already exists. Please use a different name.');
    }
  }
};

function handleInputChange(event, stateSetter) {
  stateSetter(event.target.value);
}

function CheckNumberOfInputsFilled(event){
  event.preventDefault();
  event.stopPropagation();
  if(shownInputWarning == true)
    return;

  let count = 1;
  let totalCount = 1;
  for(let i = 0; i < c_inputfields.length; i++){
    if(((isSimpleMode && c_inputfields[i].simple) || !isSimpleMode)){
      totalCount++;
      if(!c_inputfields[i].custom && c_inputfields[i].value !== ''){
        count++;
      }
    }
  }
  /*
  if(count > 4 && count !== totalCount){
    setTimeout(function(){setModalMode('inputWarning');
    setModalOpen(true);}, 400)
    setShownInputWarning(true);
  }
  */
}


const handleCustomFieldChange = async (event, field) => {
  if(field)
    setInputField(field, event.target.value);  
};

const handleRemoveCustomField = async (event, field) => {
  if(event){
    event.preventDefault();
    event.stopPropagation()
  }
  await removeInputField(field);
};

const handleGetTextDescriptionInput = async (event) => {
  if(event){
    event.preventDefault();
    event.stopPropagation()
  }
  setRandomLoading(true);
  await handleGetProperty(event, true);
  setRandomLoading(false);
};



const handleGetProperty = async (event = false, textDescribe = false) => {
    if(event)
      event.preventDefault();
    const token = localStorage.getItem('token');
    var isPlusMember = isPlusMemberCheck();
    setNameLoading(true);
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/generate/';
    if(url.indexOf('charactercomposer.com') > -1)
       apiUrl = 'https://charactercomposer.com/generate/';
  
    var inputBody = process_form_inputs(c_inputfields);

      if(areAllInputsSet() || areAllInputsBlank()){
        inputBody = getRandomFieldsInitial();
      }
    
    
    const req_body = {
      inputs: inputBody,
      descriptionInputText: descriptionInputText,
      activeTags,
      type: Object.values(inputMode)[0],
      simpleMode: isSimpleMode,
      textDescribe: textDescribe
    };
    const res = await fetchWithTimeout(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
         ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      },
      body: JSON.stringify(req_body),
    });

    if (res.status === 429) {
        setGenericMessage('Token limit reached');
        toggleLoginModal();
        setNameLoading(false);
        setLoading(false);
          setRandomLoading(false);
        return;
      }
    const result = await res.json();
    if(result && result.message){
      if(textDescribe){
        setDescriptionInputText(result.message);
        setNameLoading(false);
      }
      else{
        setNameLoading(false);
        setInputField("Name",result.message);
        
      }
      return result.message;
    }
    else{
      alert("Something went wrong. Please try again.");
      window.location.reload();
    }
    setNameLoading(false);
  };

function getRandomElement(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function cleanAttributes(attributes) {
  if (!attributes || typeof attributes !== 'object' || Array.isArray(attributes)) {
    return {};
  }

  const cleanedAttributes = {};

  for (const key in attributes) {
    const value = attributes[key];
    if (value !== null && typeof value !== 'undefined' && typeof value !== 'object') {
      cleanedAttributes[key] = value;
    }
  }

  return cleanedAttributes;
}

const handleGetRandom = async (event, field = false, req_body = false) => {
  if(event)
    event.preventDefault();
  if (!field) 
    setRandomLoading(true);
  const token = localStorage.getItem('token');
  var isPlusMember = isPlusMemberCheck();
  if(req_body == false){
    var inputBody = process_form_inputs();
    req_body = {
        inputs: inputBody, 
        descriptionInputText:descriptionInputText,
        activeTags,
        field: field,
        type: Object.values(inputMode)[0],
        simpleMode: isSimpleMode
      };
    }

    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/random/';
    if(url.indexOf('charactercomposer.com') > -1)
       apiUrl = 'https://charactercomposer.com/random/';

    const res = await fetchWithTimeout(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
         ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      },
      body: JSON.stringify(req_body),
    });
    if (res.status === 429) {
        setGenericMessage('Token limit reached');
        toggleLoginModal();
        setLoading(false);
          setRandomLoading(false);
        return;
      }
    const result = await res.json();
    if(result && result.message){
      if(field && result){
        return result.message;
      }
      else{
        const resultObj = parseAndSetState(result.message);
        return resultObj;
      }
    }
    else{
      alert("Something went wrong. Please try again.");
      window.location.reload();
    }
  };

function isSplittable(input, delimiter = '\n') {
  if (typeof input !== 'string') {
    console.error('Input must be a string');
    return false;
  }
  return input.includes(delimiter);
}

function parseAndSetState(input) {
  if(!isSplittable(input)){
    alert("Something went wrong :(")
    setLoading(false);
    setRandomLoading(false);
    return false;
  }

  const lines = input.split('\n');
  const resultObj = {};

  // Helper function to remove punctuation except spaces
  const removePunctuation = (str) => {
    return str.replace(/[^\w\s]|_/g, '');
  };

  lines.forEach((line) => {
    const [key, value] = line.split(':').map((s) => s.trim());
    if (!value) return false;
    const cleanValue = value.endsWith(',') ? value.slice(0, -1) : value;
    //map values back to form
    var nameToSet = key;
    if(nameToSet == 'Age (number)')
      nameToSet = 'Age';
    resultObj[nameToSet] = cleanValue;  
  });

  return resultObj;
}



function cleanKey(key) {
    // Remove extra spaces and colons
    return key.trim().replace(':', '').replace('-', ' ');
}

function saveCharacterToLocalStorage(currentCharacter){
      if(!currentCharacter.mode){
        console.log('no input mode, setting to: ' + inputMode);
        currentCharacter.mode = inputMode;
      }
      if(!currentCharacter.simpleMode)
        currentCharacter.simpleMode = isSimpleMode;
      localStorage.setItem('currentCharacter', JSON.stringify(currentCharacter));
}

const loadCharacterFromLocalStorage = (isEditing = false, partialLoad = false) => {
    var isEditingSavedCharacter = false;
    if (localStorage.characterToEdit) {
        isEditing = true;
        isEditingSavedCharacter = true;
        var characterToEdit = JSON.parse(localStorage.characterToEdit);
        delete characterToEdit.html;
        delete characterToEdit.title;
        delete characterToEdit.userId;

        characterToEdit.simpleMode = JSON.parse(characterToEdit.simpleMode);
        saveCharacterToLocalStorage(characterToEdit);
        localStorage.removeItem('characterToEdit');
        setEditingSavedCharacterId(true);
        setIsFormVisible(true);
        setTimeout(function(){loadCharacterFromLocalStorage(true)},100);
    }
    
    if (localStorage.currentCharacter) {
      const currentCharacter = JSON.parse(localStorage.currentCharacter);
      setCharacterAttributes(currentCharacter.attributes);
      setCharacterId(currentCharacter.id);

      if(isEditing){
        if(currentCharacter.simpleMode)
          setSimpleMode(currentCharacter.simpleMode);
        if(currentCharacter.mode){
          setInputMode(currentCharacter.mode);
        }
        if(currentCharacter.attributes['User provided description']){
          setDescriptionInputText(currentCharacter.attributes['User provided description']);
        }
        setTimeout(function(){
          Object.entries(currentCharacter.attributes).forEach(([name, value]) => {
              setInputField(name, value);
          });
          setRandomLoading(true);
          const createdCharacterElement = document.getElementById("formElement");
          if(createdCharacterElement)
            createdCharacterElement.scrollIntoView({ behavior: "smooth" });
          if(setEditingSavedCharacterId !== "")
            setEditingSavedCharacterId(true);
          setRandomLoading(false);
          setFormFilled(true);
          setIsFormVisible(true);
        },100);
      }
      if(partialLoad === false){
        for (let key in outputOptions) {
          outputOptions[key] = false;
        }
        for (let key in currentCharacter.description) {
                // Clean up the key
                let cleanedKey = cleanKey(key);
                // Check if the cleaned key is in outputOptions
                if (cleanedKey in outputOptions) {
                    // If it is, set the corresponding value in outputOptions to true
                    outputOptions[cleanedKey] = true;
                }
          }
      }
      if(currentCharacter){
        currentCharacter.description = removeNestedKeys(currentCharacter.description);
        currentCharacter.description = orderDescriptionsObject(currentCharacter.description);
        setOutputOptions(outputOptions);
        setResponse(currentCharacter.description);
        setCharacterInfo(currentCharacter);
        if(currentCharacter.image !== 'invalid_request_error')
          setImageUrl(currentCharacter.image);
        
        setLastImagePrompt(currentCharacter.imagePrompt);
        setImagePrompt(currentCharacter.imagePrompt);
        //set outputOptions based on de
      }
    }
  };

function removeNestedKeys(obj) {
  for (var key in obj) {
    if (obj[key] !== null && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
      delete obj[key];
    }
  }
  
  return obj;
}


  useEffect(() => {
    loadCharacterFromLocalStorage();
    handleClear();
  }, []);

    const handleSubmitOutput = (finalOptions) => {
      closeModal();
      setModalMode('');
        if(outputOptions !== finalOptions){
          setOutputOptions(finalOptions);
          if(finalOptions.length > 4)
            handleUpdateOutput(finalOptions);
      }
    };

    const handleUpdateOutput = async (finalOptions) => {
    console.log('yo');
    closeModal();
    setModalMode('');
    let updatedOptions = {...finalOptions};
    for (let key in updatedOptions) {
        if (outputOptions[key]) {
            updatedOptions[key] = false;
        }
    }
    console.log(JSON.stringify(outputOptions));
    console.log(JSON.stringify(finalOptions));
    if (JSON.stringify(outputOptions) !== JSON.stringify(finalOptions)) {

      setOutputOptions(finalOptions);

      setLoading(true);
      setRandomLoading(true);
      var loadingBoxElement = document.getElementById("LoadingBoxContainer");
      loadingBoxElement.scrollIntoView({ behavior: "smooth" });

      var url = window.location.href;
      var apiUrl = 'http://localhost:3001/api/';

      if (url.indexOf('charactercomposer.com') > -1) {
        apiUrl = 'https://charactercomposer.com/api/';
      }

      const token = localStorage.getItem('token');
      var isPlusMember = isPlusMemberCheck();


      var currentCharacter = JSON.parse(localStorage.currentCharacter);

      const requestBody =  {
            currentCharacter: JSON.stringify(currentCharacter),
            outputOptions: updatedOptions,
            type: Object.values(inputMode)[0],
            simpleMode: isSimpleMode
          };

      const res = await fetchWithTimeout(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324",
           ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})

        },
        body: JSON.stringify(requestBody),
      });
      //if run out of tokens
      if (res.status === 429) {
          setGenericMessage('Token limit reached');
          toggleLoginModal();
          setLoading(false);
          setRandomLoading(false);
          return;
        }

        const result = await res.json();
        if(result && result.message){
          const descriptionsToAddObj = parseGptOutputToJson(result.message);
          updateDescriptionsInLocalStorage(descriptionsToAddObj);

          loadCharacterFromLocalStorage();
          setLoading(false);
          setRandomLoading(false);

          const createdCharacterElement = document.getElementById("created-character");
          createdCharacterElement.scrollIntoView({ behavior: "smooth" });
        }
        else{
          alert("Something went wrong. Please try again.");
          window.location.reload();
        }
    }
};

const refreshDescription = async (title) => {
  if(activeTitle !== null)
    return;
  setActiveTitle(title);

  var descriptionToGet = {
      'Memorable Quote': false,
      'First Person Description': false,
      'Third Person Description': false,
      'Battle Cry': false,
      'Past Experiences': false,
      'Relationships and Social Interactions': false,
      'Reputation and Influence': false,
      'Strengths and Weaknesses': false,
      'Taunts and Threats': false,
      'Goals and Aspirations': false,
      'Important Items': false,
      'Interests and Lifestyle': false,
      'DnD Stats': false,

      'Object Description': false,
      'Appearance and Craftsmanship': false,
      'Beliefs and Superstitions': false,
      'Cultural Significance': false,
      'Curses and Side Effects': false,
      'Effects on Owner': false,
      'Famous Past Owners': false,
      'Give Immersive Response': false,
      'Hidden Powers': false,
      'Historical Events': false,
      'Legends and Myths': false,
      'Materials and Function': false,
      'Origin and Creation Process': false,
      'Requirements and Rituals': false,
      'Rumours and Legends': false,
      'Sentience, Personality and Quirks': false,
      'Special Abilities and Features': false,
      'Special Interactions': false,
      'Value and Acquisition Methods': false,

      'Unique Aspects and Features': false,
      'Surrounding Context': false,
      'Structural and Design Details': false,
      'Status and Conditions': false,
      'Security and Accessibility': false,
      'Purpose and Function': false,
      'Notable Objects and Elements': false,
      'Environment and Atmosphere': false,
      'Cultural and Historical Significance': false,
      'Dangers and Threats': false,
      'Environmental Effects': false,
      'Items and Loot': false,
      'Interactive Elements': false,

      'Cultural and Historical Significance': false,
      'Demographics and Society': false,
      'Economic and Industrial Aspects': false,
      'Education and Technology': false,
      'Geographic and Natural Features':false,
      'Governance and Politics': false,
      'Health and Environment': false,
      'Infrastructure and Architecture':false,
      'Leisure and Entertainment': false,
      'Religion and Beliefs': false,
      'Dangers and Threats': false,
      'Environmental Effects':false,
      'Interactive Elements': false,
      'Items and Loot':false,

      'Describe Appearance': false,
      'Entrance and Promos': false,
      'Goals and Aspirations': false,
      'Introduce Yourself': false,
      'Introduction by Ring Official': false,
      'Promos and Catchphrases': false,
      'Reputation and Influence': false,
      'Wrestling Journey': false,

      'Accessibility and Location': false,
      'Audience and Attendance': false,
          'Backstage and Technical Aspects': false,
          'Events and Traditions': false,
          'Future Prospects and Plans': false,
          'Historical Significance': false,
          'Physical Structure and Design': false,
          'Security and Safety Measures': false,
          'Unique Aspects and Features': false,

      'Weapon-Specific Abilities': false,
          'Weapon Type and Function': false,
          'Weapon Sentience and Personality': false,
          'Weapon Maintenance': false,
          'Weapon Craftsmanship': false,
          'Value, Rarity, and Acquisition': false,
          'Special Weapon Interactions': false,
          'Potential Drawbacks and Curses': false,
          'Notable Wielders': false,
          'Myths and Legends': false,
          'Materials and Forge Method': false,
          'Legendary Fights': false,
          'History and Origin': false,
          'Hidden Features': false,
          'Famed Battles and Wars': false,
          'Cultural Symbolism': false,
          'Consequences of Misuse': false,
          'Combat Tactics': false,
          'Attunement Rituals': false,
          'Artistic and Aesthetic Details': false,

          'Physical Characteristics': false,
          'Psychological Profile': false,
          'Legends and Sightings': false, 
          'Mythology and Symbolism': false,
          'Relationship with Other Species': false,
          'Special Phenomena': false,
          'Threat Assessment': false,
          'Artistic Representations': false,
          'Combat and Survival': false,
          'Conservation Status': false,
          'Containment and Control Measures': false,
          'Cultural and Historical Context': false,
          'Ecological Impact': false,

          'Appearance and Aesthetics': false,
          'Communication and Behavior': false,
          'Description': false, 
          'Function and Role': false,
          'Important Accessories': false,
          'Interaction with Owner': false,
          'Memorable Experiences': false,
          'Origins and Background': false,
          'Preferences': false,
          'Restrictions and Limitations': false,
          'Social Behavior and Relationships': false,
          'Special Skills and Abilities': false,
          'Training and Abilities': false,


          'Design and Mechanics': false,
          'Efficacy and Adaptability': false,
          'Importance and Legends': false,
          'Innovation and Rarity': false,
          'Materials and Craftsmanship': false,
          'Operational Complexity': false,
          'Origin and Importance': false,
          'Purpose and Function': false,
          'Reliability and Maintenance': false,
          'Users and Context': false,

          'Appearance and Craftsmanship': false,
          'Benefits and Drawbacks': false,
          'Clothing Function and Utility': false,
          'Color Pattern and Style': false,
          'Concealed Features': false,
          'Cultural Significance': false,
          'Famous Wearers': false,
          'Fashion Influences': false,
          'Garment Description': false,
          'Historical Context': false,
          'Material and Construction': false,
          'Place of Origin': false,
          'Size and Fit': false,
          'Stories and Legends': false,
          'Traditional Symbolism': false,
          'Value and Acquisition Methods': false,
          'Wear and Durability': false,

          'Appearance and Craftsmanship': false,
          'Benefits and Drawbacks': false,
          'Cultural and Historical Significance': false,
          'Design and Decorations': false,
          'Function and Utility': false,
          'Materials and Construction': false,
          'Origin and History': false,
          'Protection and Performance': false,
          'Symbolism and Significance': false,
          'Value and Acquisition': false,
          'Wear and Durability': false,

          'Communication and Information': false,
          'Conflict and Cooperation': false,
          'Cultural and Societal Convergence': false,
          'Economic Systems and Resource Management': false,
          'Ethical and Philosophical Perspectives': false,
          'Historical and Future Trajectories': false,
          'Influential Figures and Leadership': false,
          'Interplay of Power and Governance': false,
          'Mysteries and Discoveries': false,
          'Technology and Magic': false,
          'Transport and Logistics': false,

          'Purpose/History': false,
          'Operational Framework': false, 
          'Leadership/Hierarchy': false,
          'Headquarters/Hangouts': false,
          'Equipment/Tactics': false,
          'Appearance': false,
          'Training/Conditioning': false,
          'Communication/Symbology': false,

          'Historical Significance and Legacy': false,
        'User Experience and Interface': false,
        'Engineering and Design': false,
        'Operational and Deployment History': false,
        'Armor and Defences': false,
        'Customization and Personalization': false,
        'Engine Design and Performance': false,
        'Famous Past Drivers': false,
        'Tactical and Strategic Use': false,
        'Technological Innovations and Anomalies': false,
        'Weapons and Special Features': false,
        'Anecdotes and Legends': false,

        'Backstory and History': false,
          'Cultural Background': false,
          'Daily Routine': false,
          'Favorite Locations': false,
          'Fears and Phobias': false,
          'Goals and Aspirations': false,
          'Hobbies and Interests': false,
          'Likes and Dislikes': false,
          'Personal Challenges and Conflicts': false,
          'Political Affiliations': false,
          'Religious Beliefs': false,
          'Reputation and Influence': false,
          'Role Within Faction': false,
          'Secrets and Mysteries': false,
          'Services on Offer': false,
          'Skills and Services': false,
          'Types of Items for Sale': false,
          'Types of Quest OfferedWealth and Status': false,

          'Ideological Overview': false,
          'Faction Origins': false,
          'Purpose': false,
          'Culture': false,
          'Internal Conflicts': false,
          'Membership Demographics': false,
          'Alliances': false,
          'Current Leader': false, 
          'Daily Life': false,
          'Faction Quirks': false,
          'Feuds': false,

          "Appearance/Value":false,
          "Consequences of Use":false,
          "Creation/History":false,
          "Cultural Significance":false,
          "Mystery/Intrigue":false,
          "Negative Effects on User":false,
          "Positive Effects on User":false,
          "Purpose/Function":false,
          "Quest to Destroy Item":false,
          "Quest to Find Item":false,
          "Quest to Use Item":false,
  }

  let cleanedKey = cleanKey(title);
  if (cleanedKey in descriptionToGet) {
      descriptionToGet[cleanedKey] = true;
  }
      
  var url = window.location.href;
    var apiUrl = 'http://localhost:3001/api/';
  if (url.indexOf('charactercomposer.com') > -1) {
    apiUrl = 'https://charactercomposer.com/api/';
  }

  const token = localStorage.getItem('token');
 var isPlusMember = isPlusMemberCheck();

  var currentCharacter = JSON.parse(localStorage.currentCharacter);
  var removedDescriptionCharacter = currentCharacter;
  //remove description from character
  delete removedDescriptionCharacter.description[cleanedKey];
  const requestBody =  {
        currentCharacter: JSON.stringify(removedDescriptionCharacter),
        outputOptions: descriptionToGet,
        type: Object.values(inputMode)[0],
        simpleMode: isSimpleMode
      };
  const res = await fetchWithTimeout(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'API-Key': "2(yY62.mG#6UI324324",
       ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})

    },
    body: JSON.stringify(requestBody),
  });
  //if run out of tokens
  if (res.status === 429) {
      setGenericMessage('Token limit reached');
      toggleLoginModal();
      setLoading(false);
      setRandomLoading(false);
      return;
  }

  const result = await res.json();
  if(result && result.message){
    const descriptionsToAddObj = parseGptOutputToJson(result.message);
    updateDescriptionsInLocalStorage(descriptionsToAddObj);
        
    //localStorage.setItem('currentCharacter', JSON.stringify(currentCharacter));
    setTimeout(function(){
      currentCharacter = JSON.parse(localStorage.currentCharacter);
      loadCharacterFromLocalStorage();
    },50);
  }
  else{
    alert("Something went wrong. Please try again.");
    window.location.reload();
  }
  

  setActiveTitle(null);
}

// Custom function to format an object into a readable string
function formatObjectForDisplay(obj) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');
}

// Function to update the descriptions and maintain the desired order
// Function to update the descriptions and maintain the desired order
// Function to update the descriptions and maintain the desired order
function updateDescriptionsInLocalStorage(descriptionsToAddObj) {
  // Parse the current character from localStorage
  const currentCharacter = JSON.parse(localStorage.getItem('currentCharacter')) || {};

  // Ensure each property in descriptionsToAddObj is stringified and formatted
  Object.keys(descriptionsToAddObj).forEach(key => {
    if (typeof descriptionsToAddObj[key] === 'object' && descriptionsToAddObj[key] !== null) {
      let formattedString = JSON.stringify(descriptionsToAddObj[key], null, 2);
      // Remove curly braces, square brackets, double quotes, and colons
      formattedString = formattedString.replace(/[\{\}\[\]]/g, '').replace(/\"/g, '').replace(/\:/g, ' -');
      // Additional formatting can be done here if needed
      descriptionsToAddObj[key] = formattedString;
    }
  });

  // Merge the descriptions with the current character's descriptions
  descriptionsToAddObj = {...currentCharacter.description, ...descriptionsToAddObj};

  // Get the current descriptions in order
  var descriptionsInOrder = orderDescriptionsObject(descriptionsToAddObj);

  // Update the currentCharacter's descriptions
  currentCharacter.description = descriptionsInOrder;

  // Store the updated currentCharacter object in localStorage
  saveCharacterToLocalStorage(currentCharacter);
}





function orderDescriptionsObject(descriptionsToAddObj){
  var inputModeTemp = JSON.parse(localStorage.getItem('inputMode'));
  if(descriptionsToAddObj){
  const currentCharacter = JSON.parse(localStorage.getItem('currentCharacter')) || {};
  var descriptionOrder = [
    'Memorable Quote',
    'Battle Cry',
    'First Person Description',
    'Third Person Description',
    'Past Experiences',
    'Relationships and Social Interactions',
    'Reputation and Influence',
    'Strengths and Weaknesses',
    'Goals and Aspirations',
    'Interests and Lifestyle',
    'Taunts and Threats',
    'Important Items',
    'Life Story Summary',
  ];
  
  if(Object.values(inputModeTemp)[0] == 'NPC'){
   descriptionOrder = [
        'Backstory and History',
          'Cultural Background',
          'Daily Routine',
          'Favorite Locations',
          'Fears and Phobias',
          'Goals and Aspirations',
          'Hobbies and Interests',
          'Likes and Dislikes',
          'Personal Challenges and Conflicts',
          'Political Affiliations',
          'Religious Beliefs',
          'Reputation and Influence',
          'Role Within Faction',
          'Secrets and Mysteries',
          'Services on Offer',
          'Skills and Services',
          'Types of Items for Sale',
          'Types of Quest OfferedWealth and Status' ]
  }
  
  if(Object.values(inputModeTemp)[0] == 'Henchman'){
   descriptionOrder = [
      'Purpose/History',
          'Operational Framework', 
          'Leadership/Hierarchy',
          'Headquarters/Hangouts',
          'Equipment/Tactics',
          'Appearance',
          'Training/Conditioning',
          'Communication/Symbology',
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'Faction'){
   descriptionOrder = [
      'Ideological Overview',
      'Purpose',
      'Faction Origins',
      'Culture',
          'Internal Conflicts',
          'Membership Demographics',
          'Alliances',
          'Current Leader', 
          'Daily Life',
          'Faction Quirks',
          'Feuds',
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'McGuffin'){
   descriptionOrder = [
      "Appearance/Value",
      "Consequences of Use",
      "Purpose/Function",
      "Quest to Use Item",
      "Quest to Find Item",
      "Quest to Destroy Item",
      "Creation/History",
      "Cultural Significance",
      "Mystery/Intrigue",
      "Negative Effects on User",
      "Positive Effects on User",
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'World'){
   descriptionOrder = [
      'Economic Systems and Resource Management',
      'Interplay of Power and Governance',
      'Conflict and Cooperation',
      'Cultural and Societal Convergence',
      'Ethical and Philosophical Perspectives',
      'Historical and Future Trajectories',
      'Influential Figures and Leadership',
      'Communication and Information',
      'Mysteries and Discoveries',
      'Technology and Magic',
      'Transport and Logistics',
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'RPG Item'){
   descriptionOrder = [
      'Object Description',
      'Appearance and Craftsmanship',
      'Beliefs and Superstitions',
      'Cultural Significance',
      'Curses and Side Effects',
      'Effects on Owner',
      'Famous Past Owners',
      'Give Immersive Response',
      'Hidden Powers',
      'Historical Events',
      'Legends and Myths',
      'Materials and Function',
      'Origin and Creation Process',
      'Requirements and Rituals',
      'Rumours and Legends',
      'Sentience, Personality and Quirks',
      'Special Abilities and Features',
      'Special Interactions',
      'Value and Acquisition Methods',
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'Pet'){
    descriptionOrder = [
     'Appearance and Aesthetics',
          'Communication and Behavior',
          'Description', 
          'Function and Role',
          'Important Accessories',
          'Interaction with Owner',
          'Memorable Experiences',
          'Origins and Background',
          'Preferences',
          'Restrictions and Limitations',
          'Social Behavior and Relationships',
          'Special Skills and Abilities',
          'Training and Abilities',]
  }
  if(Object.values(inputModeTemp)[0] == 'Monster'){
   descriptionOrder = [
      'Physical Characteristics',
      'Psychological Profile',
      'Legends and Sightings', 
      'Mythology and Symbolism',
      'Relationship with Other Species',
      'Special Phenomena',
      'Threat Assessment',
      'Artistic Representations',
      'Combat and Survival',
      'Conservation Status',
      'Containment and Control Measures',
      'Cultural and Historical Context',
      'Ecological Impact',
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'Weapon'){
   descriptionOrder = [
      'Weapon-Specific Abilities',
          'Weapon Type and Function',
          'Combat Tactics',
          'Artistic and Aesthetic Details',
                    'Weapon Craftsmanship',
          'Materials and Forge Method',
          'Value, Rarity, and Acquisition',
          'Special Weapon Interactions',
          'Potential Drawbacks and Curses',
          'Notable Wielders',
          'Myths and Legends',
          'Legendary Fights',
          'History and Origin',
          'Hidden Features',
          'Famed Battles and Wars',
          'Cultural Symbolism',
          'Consequences of Misuse',
          'Attunement Rituals',
          'Weapon Sentience and Personality',
          'Weapon Maintenance',
          
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'Equipment'){
   descriptionOrder = [
      'Design and Mechanics',
          'Efficacy and Adaptability',
          'Importance and Legends',
          'Innovation and Rarity',
          'Materials and Craftsmanship',
          'Operational Complexity',
          'Origin and Importance',
          'Purpose and Function',
          'Reliability and Maintenance',
          'Users and Context',
          
      ]
  }
   if(Object.values(inputModeTemp)[0] == 'Armor'){
   descriptionOrder = [
      'Appearance and Craftsmanship',
          'Benefits and Drawbacks',
          'Cultural and Historical Significance',
          'Design and Decorations',
          'Function and Utility',
          'Materials and Construction',
          'Origin and History',
          'Protection and Performance',
          'Symbolism and Significance',
          'Value and Acquisition',
          'Wear and Durability'
      ]
  }
if(Object.values(inputModeTemp)[0] == 'Vehicle'){
   descriptionOrder = [
      'Historical Significance and Legacy',
        'User Experience and Interface',
        'Engineering and Design',
        'Operational and Deployment History',
        'Armor and Defences',
        'Customization and Personalization',
        'Engine Design and Performance',
        'Famous Past Drivers',
        'Tactical and Strategic Use',
        'Technological Innovations and Anomalies',
        'Weapons and Special Features',
        'Anecdotes and Legends'
      ]
  }
  
  if(Object.values(inputModeTemp)[0] == 'Clothing'){
   descriptionOrder = [
      'Appearance and Craftsmanship',
          'Benefits and Drawbacks',
          'Clothing Function and Utility',
          'Color Pattern and Style',
          'Concealed Features',
          'Cultural Significance',
          'Famous Wearers',
          'Fashion Influences',
          'Garment Description',
          'Historical Context',
          'Material and Construction',
          'Place of Origin',
          'Size and Fit',
          'Stories and Legends',
          'Traditional Symbolism',
          'Value and Acquisition Methods',
          'Wear and Durability',
          
      ]
  }
  if(Object.values(inputModeTemp)[0] == 'Micro Location'){
    descriptionOrder = [
      'Unique Aspects and Features',
      'Surrounding Context',
      'Structural and Design Details',
      'Dangers and Threats',
          'Environmental Effects',
          'Items and Loot',
          'Interactive Elements',
      'Status and Conditions',
      'Security and Accessibility',
      'Purpose and Function',
      'Notable Objects and Elements',
      'Environment and Atmosphere',
      'Cultural and Historical Significance',
    ];
  }
  if(Object.values(inputModeTemp)[0] == 'Macro Location'){
    descriptionOrder = [
      'Geographic and Natural Features',
      'Infrastructure and Architecture',
      'Cultural and Historical Significance',
      'Demographics and Society',
      'Economic and Industrial Aspects',
      'Education and Technology',
      'Governance and Politics',
      'Health and Environment',
      'Leisure and Entertainment',
      'Religion and Beliefs',
      'Dangers and Threats',
          'Environmental Effects',
          'Interactive Elements',
          'Items and Loot'
      ];
  }
  if(Object.values(inputModeTemp)[0] == 'Wrestler'){
    descriptionOrder = [
      'Describe Appearance',
      'Entrance and Promos',
      'Goals and Aspirations',
      'Introduce Yourself',
      'Introduction by Ring Official',
      'Promos and Catchphrases',
      'Reputation and Influence',
      'Wrestling Journey',
    ]
  }
  if(Object.values(inputModeTemp)[0] == 'Wrestling Arena'){
    descriptionOrder = [
      'Physical Structure and Design',
      'Unique Aspects and Features',
      'Events and Traditions',
      'Audience and Attendance',
      'Backstage and Technical Aspects',
      'Future Prospects and Plans',
      'Historical Significance',
      'Security and Safety Measures',
      'Accessibility and Location',        
    ];
  }
  // Create an object to store the descriptions in the desired order
  const descriptionsInOrder = {};
  // Iterate over the description order
  for (const key of descriptionOrder) {
    // Check if the key exists in the descriptionsToAddObj
    if (descriptionsToAddObj.hasOwnProperty(key)) {
      // Add the description to the descriptionsInOrder object
      descriptionsInOrder[key] = descriptionsToAddObj[key];
    } 
    // Check if the key exists in the currentCharacter's descriptions
    if (currentCharacter.description && currentCharacter.description.hasOwnProperty(key)) {
      // If the key doesn't exist in descriptionsToAddObj or it already exists in descriptionsInOrder,
      // add it to the descriptionsInOrder object
      if(!descriptionsInOrder.hasOwnProperty(key)){
        descriptionsInOrder[key] = currentCharacter.description[key];
      }
    }
  }
  return descriptionsInOrder;
}
}

const handleFormLayoutChange = async (value) => {
  setInputMode(value);
  };

const enterTextDescription = (e) => {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setModalMode('text');
    setModalOpen(true);
  };

  const handleFormLayoutSubmit  = async  (value) => {
    setInputMode(value);
  setRandomLoading(true);
  await setTimeout(function(){closeModal();setModalMode('');},200);
  await setTimeout(function(){setRandomLoading(false);},400);
    
  };
const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

var processedHeadings = headings;
if (isSimpleMode && Object.values(inputMode)[0] !== 'Life Path') {
    // Create a new object with only 'basic' and 'details'
    processedHeadings = {
        'Basic': headings['Basic'],
        'Details': headings['Details'] ? headings['Details'] : [],
    };

    // Iterate over the headings and add fields not in 'basic' to 'details'
    for (let [heading, inputs] of Object.entries(headings)) {
        if (heading !== 'Basic' && heading !== 'Details') {
            processedHeadings['Details'] = [...processedHeadings['Details'], ...inputs];
        }
    }
}


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}
const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [fade, setFade] = useState(false);

const [placeholders, setPlaceholders] = useState(shuffleArray([
    "A warrior with fire-red hair, carrying a sword that shines like the moon...",
    "Mysterious stranger in a hooded cloak, with eyes that change color with mood...",
    "Sci-fi engineer, missing her left arm, replaced with a high-tech gadget-filled prosthetic...",
    "A mermaid with shimmering silver scales, singing songs that control the tides...",
    "Post-apocalyptic survivor, with tattoos mapping safe routes, always accompanied by a robotic dog...",
    "Child prodigy detective, wears oversized glasses, and has a pet raven that delivers messages...",
    "Space pirate captain, with cybernetic legs and a heart of gold, searching for a lost planet...",
    "A librarian by day, but by night she becomes a shadowy thief skilled in ancient magics...",
    "Desert wanderer, blindfolded, sees visions of the future and rides a giant sandworm...",
    "Gothic inventor in a Victorian mansion, builds clockwork creatures and communicates with ghosts...",
    "Ballet dancer, carrying a locket that holds the secret to another world...",
    "Time-traveling historian, always seen in vintage attire, seeks to right the wrongs of the past...",
    "Vampire botanist, cultivates magical plants, only comes out during full moons...",
    "Steampunk aviator, with mechanical wings and a penchant for skywriting love notes...",
    "Forest guardian, with skin like bark and a crown of intertwined flowers and vines...",
    "Champion gladiator from an alien race, fights with fluid elegance, guided by a code of honor...",
    "Ice queen, lonely in her crystalline palace, wields magic that can freeze or mend hearts...",
    "Mad scientist, obsessed with merging magic and technology, has a talking cat as an assistant...",
    "Urban witch, brews potions in a coffee shop, rides a skateboard instead of a broomstick...",
    "Ghostly sailor, cursed to wander the shores, searching for his lost love with a lantern that never dims...",
    "Martial artist from the future, fights using holographic weapons, seeking enlightenment...",
    "Cybernetic DJ, creates beats that can manipulate emotions, known throughout the galaxy...",
    "Nomadic healer, travels the world curing diseases with a mix of medicine and old-world rituals...",
    "Robot philosopher, questioning its own existence, writes poetry that moves souls...",
    "Moon priestess, draped in shimmering robes, can summon lunar creatures...",
    "Former assassin turned baker, uses unique skills to craft the deadliest pastries...",
    "Dragon-riding journalist, reports from the skies, always chasing the next big story...",
    "Dimension-hopping teenager, collects artifacts from different universes, never stays in one place too long...",
    "Ethereal artist, paints prophecies with starlight, every artwork tells a fate...",
    "Orchard spirit, with skin of apple red, turns fallen fruits into magical elixirs...",
    "Cursed prince, turned into a raven by day, seeks to break the enchantment by night...",
    "Desert djinn, trapped in an ancient lamp, tells tales of civilizations long forgotten...",
    "Alien florist, arranges bouquets that hum intergalactic tunes, carries memories of a dying star...",
    "Masked ballerina, dances to unlock hidden realms, every pirouette reveals another mystery...",
    "Aquatic archaeologist, explores sunken cities, guarded by a loyal octopus companion...",
    "Urban shaman, paints graffiti that comes to life, bridge between modern and spirit worlds...",
    "Mutant journalist, captures memories with a touch, writes stories that can be relived...",
    "Medieval hacker, uses a quill to decode secrets, accompanied by a digital falcon...",
    "Space-faring bard, plays a guitar made of comet tails, legends say his songs birthed galaxies...",
    "Haunted seamstress, stitches dreams into garments, clothed in memories of those passed away...",
    "Celestial chef, prepares dishes from stardust, every meal evokes a celestial event...",
    "Goblin entrepreneur, trades in rare magical objects, wears spectacles that see true value...",
    "Cyberpunk herbalist, grows plants in a neon-lit greenhouse, herbs that can heal or hack minds...",
    "Rainforest guardian, with tattoos that glow in the dark, speaks the language of animals...",
    "Android poet, seeks to understand human emotion, ink made of liquid crystal...",
    "Polar explorer, rides a sled of auroras, searches for a city made of ice...",
    "Twilight fisherman, catches dreams in his net, sells bottled hopes at dawn...",
    "Dragon librarian, guards manuscripts of ancient wisdom, riddles as a checkout system...",
    "Clockwork dancer, winds up to perform, enchants viewers with her timeless grace...",
    "Zookeeper of mythical creatures, nurtures unicorns and griffins, has a pet phoenix...",
    "Underground racer, drives a car powered by shadows, undefeated on moonlit tracks...",
    "Moss-covered druid, awakens stones to life, carries the ancient wisdom of forests...",
    "Solar sorceress, draws power from sunspots, can weave sunbeams into armor...",
    "Pandimensional detective, solves inter-reality mysteries, uses a magnifying glass that peers through dimensions...",
    "Beachcomber mage, finds magic in washed-up trinkets, can command the tides with a wave...",
    "Molecular chef from Mars, crafts dishes that defy gravity, every bite a cosmic journey...",
    "Dreamcatcher smith, forges nets that safeguard sleepers, battles nightmares in the astral plane...",
    "Rune-tattooed gladiator, every mark a tale of victory, seeks the ultimate opponent...",
    "Antique shopkeeper, every item holds a curse or blessing, has a cat with nine lives... literally."
]));


 const [placeholderText, setPlaceholderText] = useState("Describe its unique features or leave blank to generate a random creation...");
  
var placeholderIndexRef = useRef(0);

const timeoutRef = useRef(null);
useEffect(() => {
    timeoutRef.current = setInterval(() => {
      // Start by fading out
      setFade(true);

      // After fade-out duration (300ms), empty the placeholder and wait for 200ms
      setTimeout(() => {
        setPlaceholderText("");
        const placeholders = getPlaceHolderTextsForMode();
        // After the 200ms delay, set the new placeholder
        setTimeout(() => {
          placeholderIndexRef.current = (placeholderIndexRef.current + 1) % placeholders.length;
          setPlaceholderText(placeholders[placeholderIndexRef.current]);

          // Add a tiny delay to ensure the new placeholder is set, then fade it in
          setTimeout(() => {
            setFade(false);
          }, 10);
        }, 200);
      }, 300);
    }, 5000);  // Change placeholder every 6 seconds

    return () => clearInterval(timeoutRef.current);  // Cleanup on component unmount
  }, [inputMode]);

 const [isFormVisible, setIsFormVisible] = useState(false);


const advancedToggle = (visible) => {
    setIsFormVisible(visible);
    if((descriptionInputText.length > 0 || activeTags.length > 0) && visible == true && areAllInputsBlank()){
      handleDescriptionParent();
    }
    if(shownAttributesTip !== true && visible == true){
      setTimeout(function(){
        //setModalMode('shownAttributesTip');
        //setModalOpen(true);
        //localStorage.setItem('shownAttributesTip', true);
        //setShownAttributesTip(true);
      },3500);
      
    }
  }

useEffect(() => {
    if(descriptionInputText.length > 1000)
      setDescriptionInputText(descriptionInputText.substring(0, 1000));
  }, [descriptionInputText]);
  

  const handleSortingClick = (type) => {
    setAttributeSorting(type);
  };

  const handleDefaultClick = (type) => {
    setAttributeDefault(type);
  };


  function copyToClipboard(text) {
  // Create a temporary input element
  var tempInput = document.createElement("input");
  tempInput.value = text;

  // Append the input element to the body
  document.body.appendChild(tempInput);

  // Select the input element's text
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the temporary input element
  document.body.removeChild(tempInput);
  alert('URL has been copied to clipboard ready for sharing');
}

function setActiveTag(tag){
  setActiveTags([...activeTags, tag]);
}

function handleTagClick(tag){
  // Determine if the tag is already active
    const isTagActive = activeTags.find(activeTag => activeTag.name === tag.name);

  // Toggle the tag's active state
    if (isTagActive) {
      console.log('tag is now inctive');
      // Remove the tag from activeTags
      setActiveTags(activeTags.filter(activeTag => activeTag.name !== tag.name));
    } else {
      console.log('tag is now active');
      // Add the tag to activeTags
      setActiveTags([...activeTags, tag]);
    }
}

function handleTagsChange(tags){
  setTags(tags);
}

function toggleAdvancedForm(){advancedToggle(!isFormVisible);}
  return (
  <>
  
  <div className="topButtonContainer">
      <p>
            Generate worlds, characters and objects with the press of a button
          </p>
      <div className="modeSelectionContainer">
      <div className="topContainer">
        {Object.keys(formLayouts).map((mode, index) => (
          <button
            key={index}
            className={mode === Object.keys(inputMode)[0] ? 'active' : ''}
            onClick={() => handleModeClick(mode)}
          >
            {mode}
          </button>
        ))}
        </div>
      </div>
      <div className="typeSelectionContainer">
        <div className="typeSelectionScrollContainer">
          {inputMode && formLayouts[Object.keys(inputMode)[0]].map((type, index) => (
            <button
              key={index}
              className={type === Object.values(inputMode)[0] ? 'active' : ''}
              onClick={() => handleTypeClick(type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
    </div>
  <div className="composer-container">
  <div className="leftColumn">
    <div className="leftContainer">
    
    <div className="descriptionTextInput">
      <h4 className="textAreaHeader">
        Describe {Object.values(inputMode)[0]} 
        <button className="random-character reset" onClick={resetForm}>
          RESET
        </button>
      </h4>
      
      <div className={`describeContainer ${randomLoading ? 'random-loading' : ''}`}>
        <textarea
          className={`form-input ${fade ? "fade" : ""}`}
          placeholder={placeholderText}
          value={descriptionInputText}
          onChange={e => setDescriptionInputText(e.target.value)}
        />
        <FaRedoAlt className="textDice" onClick={handleGetTextDescriptionInput}/>
      </div>
      <div className={`tagsSettings inputs-column-full-width ${tags.length === 0 ? 'empty' : ''}`}>
              <div className="tags">
                <TagsDisplay
                  tags={tags}
                  activeTags={activeTags}
                  showAddTag={true}
                  onClickHandler={(tag) => {
                    handleTagClick(tag);

                  }}
                  setActiveTag={setActiveTag}
                  onAddTag={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setModalMode('tags');
                    setModalOpen(true);
                  }}
                />
              </div>
              </div>
      <button 
                type="button"
                className="advancedEditToggle"
                onClick={toggleAdvancedForm}
            >
                {Object.keys(inputMode)[0]} {isFormVisible ? 'Attributes' : 'Attributes'}
                {isFormVisible ? <FaAngleUp/> : <FaAngleDown/>}
            </button>
    </div>
    <form id="formElement" onSubmit={handleSubmitParent} className={`${randomLoading ? 'random-loading' : ''}`}>

        {isFormVisible && (
          <>          
          {randomLoading && <div class="formSpinnerContainer"><Spinner /></div> }
        <div id="cc_inputs" className='column-container'>

          <div className="clearContainer">
              <button className="clearForm" onClick={handleClear}>
                RESET
              </button>
            </div>
            {!randomLoading && 
          <div className="flexMe">
          
              <button className="randomize-container" onClick={handleRandomParent}>
              <FaDiceFive/>
                <label>
                  <b>Re-generate<br/>Attributes</b>
                </label>
              </button>
            
            </div>
          }
            <FaCog onClick={handleSettingsOpen} className="settingsCog" />
            
            <div>

            {Object.entries(processedHeadings).map(([heading, inputs], index) => (
                <Accordion 
                    key={index}
                    label={heading} 
                    onClick={(e) => handleAccordionClick(e, index)}
                    isOpen={openAccordions.includes(index)}
                >
                    <div className="toggle-section">
                        <div className={columnClass}>
                            {inputs.map(input => {
                                if ((isSimpleMode && input.simple) || !isSimpleMode) {
                                  if (input.dropDownOptions && !input.inputType) {
                                      return (
                                          <FormSection 
                                              key={input.name}
                                              onRandomClick={handleGetRandom} 
                                              changeParentValue={setInputField}
                                              label={input.name} 
                                              name={input.name.toLowerCase()} 
                                              options={input.dropDownOptions}
                                              value={input.value}
                                              CheckNumberOfInputsFilled={CheckNumberOfInputsFilled}
                                              attributeDefault={attributeDefault}
                                              attributeSorting={attributeSorting}
                                          />
                                      )
                                  } else {
                                      return (
                                          <SimpleInput
                                            key={input.name}
                                            changeParentValue={setInputField}
                                            label={input.name}
                                            name={input.name.toLowerCase()}
                                            placeholder='Enter value...'
                                            value={input.value}
                                            onChange={(event) => {
                                              setInputField(input.name, event.target.value);
                                            }}
                                          />
                                      )
                                  }
                                }
                            })}
                        </div>
                    </div>
                </Accordion>
            ))}
        </div>
        {!isSimpleMode &&(
          <Accordion 
              label="Custom Fields" 
              onClick={(e) => handleAccordionClick(e, 10)}
              isOpen={openAccordions.includes(10)}
            >
            
              <div className="custom-field-input-container">

                  <input
                    type="text"
                    className="custom-field-input form-input"
                    placeholder="Enter name of field you want to add..."
                    value={customFieldName}
                    onChange={(event) => {
                      setCustomFieldName(event.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    onClick={handleAddCustomField}
                    >
                    Add Field
                  </button>
                </div>
              <div className="toggle-section">
              <div className="inputs-columns custom">
              {c_inputfields.map(input => {
                  if (input.custom) {
                        return(
                           <div className="custom-field-wrapper">
                            <SimpleInput
                              onRandomClick={handleGetRandom} 
                              label={input.name}
                              name={input.name}
                              placeholder={`Enter ${input.name}`}
                              value={input.value}
                              onChange={(event) => {
                                  handleCustomFieldChange(event, input.name);
                                  hideDiceIfAllFilled();
                                }}
                            />
                            <button
                              className="remove-custom-field-btn"
                              onClick={(event) => handleRemoveCustomField(event, input.name)}
                            >
                              X
                            </button>
                            </div>
                        )
                      }
                  }
                )}
                </div>
            </div>
          </Accordion>
                )}
            <Accordion 
              label={Object.values(inputMode)[0] === 'Life Path' ? 'Name' : Object.values(inputMode)[0] + ' Name'}
              onClick={(e) => handleAccordionClick(e, 11)}
              isOpen={openAccordions.includes(11)}
            >
          <div className={`inputs-column-full-width flex ${nameLoading ? 'name-loading' : ''}`}>

              <SimpleInput
                label="Name or description"
                name="name"
                placeholder={nameLoading ? 'Generating name...' : 'Generate name last for best results...'}
                value={c_inputfields.find(field => field.name === 'Name').value}
                onChange={(event) => {

                  const newValue = event.target.value.substr(0,45);
                  setInputField('Name', newValue);
                }}
              />
              <FaDiceFive
                onClick={handleGetProperty}
                className={nameLoading ? 'get-property disabled' : 'get-property'}
              />
            </div>
            <div className="visibilitySettings">
              <label>
                <input disabled type="checkbox" checked/>
                Public
              </label>
              <label>
                <input disabled type="checkbox" checked/>
                Anonymous 
              </label>
            </div>
            </Accordion>
            
          </div>
          </>)}
          <div className="output-settings-container">
             {Object.values(inputMode)[0] !== 'Life Path' && <a className="choose-output-options" href="" onClick={handleButtonClickShowOutputs}>
                <FaStream className="cog"/> Descriptions
              </a>}
              <a className="choose-output-options" href="" onClick={(event) => setImageVisualStyle(event, true)}>
                <FaPaintBrush className="cog"/> Style ({imageStyle})
              </a>
            </div>
          <div
              className={`button-container ${randomLoading ? 'random-loading' : ''}`}
            >
              <button id="submit-cc" className="form-button" type="submit" disabled={loading}>
                {loading ? 'Composing ' + Object.values(inputMode)[0] + '...' : 'Compose ' + Object.values(inputMode)[0]} <FaDiceFive/>
              </button>
            </div>
      </form>
      </div>
      </div>

      <div id="created-character" className={isSaving ? 'response-section disable' : 'response-section'}>
        {characterInfo.attributes && (!(localStorage.getItem('lifePathStage') == 'conclude' && Object.values(inputMode)[0] == 'Life Path')) &&
          <div className="response-inner">
          {characterId && <span id="copyButton" onClick={() => copyToClipboard("https://charactercomposer.com/characters?id="+characterId)}><FaShare/></span>}
          <h3 className="character-name">{characterInfo.attributes.Name}</h3>
          <div className={`character-image-container ${imageLoading ? 'image-loading' : ''}`}>
            {characterInfo.image && characterInfo.image !== 'invalid_request_error' &&
              <>
                <FaRedoAlt
                    className="image-refresh"
                    onClick={imageRefreshSubmit}
                  />
                <FaPaintBrush
                    className="image-style"
                    onClick={setImageVisualStyle}
                  />
                <ImageComponent imageUrl={imageUrl} exspanded={true}/>  
                {
                    characterInfo.mode && 
                    <span className="modeTitle">
                        {Object.values(characterInfo.mode)[0]}
                    </span>
                }

                {imageLoading &&
                  <div id="imageLoadingSpinner"><Spinner /></div>
                }
              </>
            }
            {!characterInfo.image && (loading||imageLoading) &&
              <div className="invalid-image">
                <img className="portrait" src={`${process.env.PUBLIC_URL}/potion.png`}/>
                <p><Spinner /></p>
              </div>
              
            }
            {!characterInfo.image && !loading && !imageLoading &&
            <>
              <FaTrash
                    className="image-trash"
                    onClick={imageRefreshSubmit}
                    onClick={(event) => imageRefreshSubmit(event, false, false,false, true)}
                  />
              <div className="invalid-image">
                <img className="portrait" src={`${process.env.PUBLIC_URL}/potion.png`}/>
                <p>Image generation failed, click the bin icon to try again</p>
              </div>
             </> 
            }
            {characterInfo.image == 'invalid_request_error' &&
            <>
              <FaTrash
                    className="image-trash"
                    onClick={imageRefreshSubmit}
                    onClick={(event) => imageRefreshSubmit(event, false, false,false, true)}
                  />
              <div className="invalid-image">
                <img className="portrait" src={`${process.env.PUBLIC_URL}/potion.png`}/>
                <p>Image generation failed, click the bin icon to try again</p>
              </div>
              </>
            }
            </div>
            {response && 
                  <button className="add-more-details top" onClick={() => { localStorage.removeItem('currentChatCharacter'); window.location.href = "/chat"; }}>
                    Chat with {characterInfo.attributes.Name}
                  </button>
            }
            {characterInfo.tags && <div className="displayTags"><TagsDisplay tags={characterInfo.tags}/></div>}
            <div className="response-box" id="character-descriptions">
               {characterInfo.description && Object.entries(characterInfo.description).map(([key, value]) => (
                  <CharacterParagraph 
                    title={key} 
                    handleClick={refreshDescription}
                    isActive={activeTitle === key}
                    >
                    {value}
                  </CharacterParagraph>
                ))}
                {!characterInfo.description && loading &&
                  <div className="character-paragraph">
                    <h3>Descriptions</h3>
                    <LoadingBox loading={true} show_tips={false} />
                  </div>
                }
                {!loading && 
                  <div className="detailsButtonContainer">
                  <button className="add-more-details top" onClick={handleButtonClickShowOutputsChangeMax}>
                    Add More Descriptions
                  </button>
                  </div>
            }

              {characterInfo.attributes && <AttributeList attributes={cleanAttributes(characterInfo.attributes)} />}

            </div>
            {!loading && Object.values(inputMode)[0] !== 'Life Path' &&
            <div>
              <button className="edit-character" onClick={() => loadCharacterFromLocalStorage(true)}>
                &#8592; Reroll
              </button>
              <button className="save-character" onClick={saveCharacter}>Save</button>
            </div>
            }
            {!loading && Object.values(inputMode)[0] !== 'Life Path' &&
            <div>
         
            <button className="edit-character bottom" onClick={() => loadCharacterFromLocalStorage(true)}>
                &#8592; Reroll
              </button>
              <button className="save-character bottom" onClick={saveCharacter}>Save</button>
      
            
            </div>
            }
            
          </div>
      }
      {lifePath && Object.values(inputMode)[0] == 'Life Path'  &&
          <div className="response-inner">
            <LifePathComponent imageRefreshSubmit={imageRefreshSubmit} lifePath={lifePath} lifePathInputs={lifePathInputs} userChoices={userChoices} handleLifePath={handleLifePath}/>
          </div>
        }
      <div id="LoadingBoxContainer">
        <LoadingBox loading={loading} />
      </div>
      
      </div>
      {isModalOpen && (
      <div className="add-more-modal show" onClick={closeModal}>
         <div className="add-more-modal-content" onClick={handleModalContentClick}>
          <a className="outputModalCloseButton" onClick={closeModal} href="">close</a>
          {modalMode == 'outputs' && (
            <>
              <h4>{Object.values(inputMode)[0]} Descriptions</h4>
              <CheckBoxForm outputOptions={outputOptions} onSubmit={handleSubmitOutput} onUpdate={handleUpdateOutput} maxOutputs={outputModalMaxOutputs}/>
            </>
          )}
          {modalMode == 'outputs createdCharacter' && (
            <>
              <h4>{Object.values(inputMode)[0]} Descriptions</h4>
              <CheckBoxForm outputOptions={outputOptions} onSubmit={handleSubmitOutput} onUpdate={handleUpdateOutput} maxOutputs={outputModalMaxOutputs} onCreatedCharacter={true}/>
            </>
          )}
          {modalMode == 'inputs' && (
            <>
              <RadioButtonForm currentMode={inputMode} formLayouts={formLayouts} onUpdate={handleFormLayoutChange} onSubmit={handleFormLayoutSubmit} toggleLayout={toggleColumns} singleColumn={singleColumn} toggleSimpleMode={toggleSimpleMode} simpleMode={isSimpleMode}/>
            </>
          )}
          {modalMode == 'modeWarning' && (
            <div className="modeWarningContent">
              <h3>Detailed Mode</h3> <p>Gives more details and complexity but takes longer to generate.</p>
              <a className="dismissButton" onClick={closeModal} href="">Okay</a>
            </div>
          )}
          {modalMode == 'inputWarning' && (
            <div className="inputWarning">
              <h4>Composing Tip</h4>
              <p>
                We recommened only entering <b>2</b> to <b>5</b> inputs and letting the generator do the rest for the <b>best results</b>.
              </p>
              <p>
                You can dismiss this message but may find the generated results to be less creative.
              </p>
              <p>
                Pressing the <b>dice icon</b> next to each field will allow you generate a relevant field one at a time.
              </p>
              
                
              <button className="modalGen" onClick={handleSubmitParent}>Generate rest of details <FaDice/></button><br/>
              <a className="dismissButton" onClick={closeModal} href="">continue editing</a>
            </div>
          )}
          {modalMode == 'imageStyle' && (
            <>
              <ImageStyleOptionSelect visualOptions={visualOptions} imageStyle={imageStyle} handleChangeVisualStyle={handleChangeVisualStyle} dalle3={dalle3} handleChangeImageModel={handleChangeImageModel} refreshImage="true" handleCloseVisualStyleModal={handleCloseVisualStyleModal} />
            </>
          )}
          {modalMode == 'text' && (
            <div className="descriptionTextInput">
              <h4>Generate from text description</h4>
              <textarea
                className="form-input"
                placeholder="Add a description to fill in the form for you"
                value={descriptionInputText}
                onChange={e => setDescriptionInputText(e.target.value)}
              />
              <button className="modalGen" onClick={handleDescriptionParent}>Generate<FaDice/></button><br/>
            </div>
          )}
          {modalMode == 'imageStyleNoRefresh' && (
            <>
              <ImageStyleOptionSelect visualOptions={visualOptions} imageStyle={imageStyle} handleChangeVisualStyle={handleChangeVisualStyle} dalle3={dalle3} handleChangeImageModel={handleChangeImageModel} refreshImage="false" handleCloseVisualStyleModal={handleCloseVisualStyleModal}/>
            </>
          )}
          {modalMode == 'shownAttributesTip' && (
            <div className="shownAttributesTip">
              <h4>Not sure what to enter for attribute?</h4>
              <p>
                Click the dice to generate attributes<br/>based on what <b>you</b> have entered so far
              </p>
              <button className="dismissButton" onClick={closeModal} href="">Im ready!</button>
            </div>
          )}
          {modalMode == 'tags' && (
            <div>
              <TagsModal tagsList={tags} onTagsChange={handleTagsChange} closeModal={closeModal} setActiveTag={setActiveTag} />
            </div>
          )}
          {modalMode == 'settings' && (
            <div className="settings-container">
              <h3>Settings</h3><br/>
              <label>
                Granularity
              </label>
              <div className="quickModeContainer">
                      {Object.values(inputMode)[0] !== 'Life Path' &&  
                      <>
                               <button 
                                  className={`${isSimpleMode ? "grayedOut" : "highlighted" } buttonBase`} 
                                  onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if(isSimpleMode){
                                          //setModalMode('modeWarning');
                                          //setModalOpen(true);
                                      }
                                      setSimpleMode(false);
                                  }}>
                                  {isSimpleMode ? <FaCircle/> : <FaCheckCircle/>}
                                   &nbsp;&nbsp;Detailed
                              </button>
                      
                            <button 
                                className={`${isSimpleMode ? "highlighted" : "grayedOut" } buttonBase`} 
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSimpleMode(true);
                                }}>
                                {isSimpleMode ? <FaCheckCircle/> : <FaCircle/>}
                                 &nbsp;&nbsp;Simple
                            </button>
                </>
              }
              </div>
              <label>
                Attribute list sorting
              </label>
              <div className="settings-btn-container">
                <button
                    className={attributeSorting !== 'Alphabetical' ? 'active' : ''}
                    onClick={() => handleSortingClick('Importance')}
                  >
                    Importance
                </button>
                <button
                  className={attributeSorting === 'Alphabetical' ? 'active' : ''}
                  onClick={() => handleSortingClick('Alphabetical')}
                >
                  Alphabetical
                </button>
              </div>
              <label>
                Attribute default value
              </label>
              <div className="settings-btn-container">
                <button
                    className={attributeDefault !== 'None' ? 'active' : ''}
                    onClick={() => handleDefaultClick('Generate')}
                  >
                    Generate
                  </button>
                  <button
                    className={attributeDefault === 'None' ? 'active' : ''}
                    onClick={() => handleDefaultClick('None')}
                  >
                    None
                  </button>
              </div>
              
              <button className="dismissButton" onClick={closeModal} href="">Save</button>
            </div>
          )}
        </div>
      </div>
      )}
    </div>
  </>);
}
 
export default CharacterComposer;
