export const FieldsItemVehicle
 = [
    {name: 'Name', value: '', order: 0, heading: '', simple:true, custom: false},
    {name: 'Setting', value: '', order: 0, heading: 'Basic', simple:true, custom: false,
      dropDownOptions: ['Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric','Setting','Fantasy','Steampunk','Science-Fiction','Space Opera','Cyberpunk','Post-Apocalyptic','Modern Day','Wild West','Lovecraftian','Folklore','Superhero','Supernatural','Historical','Magical Realism','Dystopian','Alternate History','Speculative Fiction','Detective','Toyland','Spiritual','Urban Fantasy','Space Western','Biopunk','Solarpunk','Dieselpunk','Mythology','Fairytale','Anthropomorphic','Hard Sci-Fi','Martial Arts','Weird Fiction','Pulp Fiction','Dark Fantasy','Apocalyptic','Prehistoric']
    },
    {name: 'Type', value: '', order: 0, heading: 'Basic', simple:true, custom: false,
      dropDownOptions: ["Car", "Motorcycle", "Bicycle", "Scooter", "Bus", "Truck", "Train", "Tram", "Trolley", "Monorail", "Hovercraft", "Tank", "Armored Vehicle", "ATV", "Snowmobile", "Skateboard", "Roller Skates", "Segway", "Cart", "Carriage", "Stagecoach", "Rickshaw", "Buggy", "Chariot", "Plane", "Helicopter", "Amphibious Vehicle", "Balloon", "Dirigible", "Ship", "Submersible", "Hoverbike", "Tricycle", "Monowheel", "Boat", "Jet Ski", "Spaceship", "Mech Walker", "Magic Carpet", "Flying Broomstick", "Teleporter", "Hoverboots", "Jetpack", "Armored Personnel Carrier", "Howdah", "Airboat", "Flying Castle", "Fighter", "Steed", "Flying Steed"]
    },
    {name: 'Function', value: '', order: 0, heading: 'Basic', simple:true, custom: false,
      dropDownOptions: ["Personal Transport", "Cargo Haulage", "Long-Distance Travel", "Public Transportation", "Emergency Services", "Assault", "Defense", "Reconnaissance", "Construction", "Agriculture", "Racing", "Off-Road", "Commuting", "Airport Shuttle", "School Transport", "Sightseeing", "Police Patrol", "Ambulance", "Firefighting", "Snow Plowing", "Street Cleaning", "Waste Collection", "Postal Services", "Courier Services", "Medical Services", "VIP Transport", "Diplomatic Transport", "Research", "Exploration", "Utility Services", "Towing", "Recovery", "Mining", "Limousine Services", "Media Production", "Taxi Services", "Ride-Sharing", "Environmental Monitoring", "Food Delivery", "Beverage Delivery", "Advertising", "Marketing", "Wedding Hire", "Event Hire", "Underwater Exploration", "Weather Tracking", "Workshop", "Repair Services", "Security Patrol", "Animal Transport"]
    },
    {name: 'Materials', value: '', order: 0, heading: 'Basic', simple:true, custom: false,
      dropDownOptions: ["Steel", "Aluminum", "Carbon Fiber", "Fiberglass", "Titanium", "Wood", "Iron", "Brass", "Copper", "Leather", "Canvas", "Rubber", "Plastic", "Glass", "Kevlar", "Graphene", "Ceramic", "Bronze", "Zinc", "Nickel", "Silver", "Gold", "Platinum", "Diamond", "Liquid Crystal Polymer", "Bamboo", "Animal Hide", "Bone", "Mythril", "Adamantium", "Vibranium", "Unobtainium", "Durasteel", "Corundum", "Dragon Skin", "Star Metal", "Orichalcum", "Elvenwood", "Ghost Iron", "Etherium", "Darksteel", "Shadowcloth", "Moonstone", "Sunsteel", "Sky Iron", "Frost Metal", "Elemental Crystal", "Voidstone", "Thunder Iron", "Lightweave Cloth"]
    },
    {name: 'Structure and Shape', value: '', order: 0, heading: 'Design and Appearance', simple:true, custom: false,
      dropDownOptions: ["Aerodynamic", "Streamlined", "Compact", "Bulky", "Rectangular", "Oval", "Spherical", "Cylindrical", "Wedge-shaped", "Boxy", "Flat", "Curved", "Angular", "Asymmetrical", "Symmetrical", "Modular", "Teardrop", "Triangular", "Diamond-shaped", "Low-profile", "High-rise", "Tapered", "Elongated", "Squat", "Rugged", "Sleek", "Rigid", "Flexible", "Collapsible", "Inflatable", "Articulated", "Expandable", "Foldable", "Layered", "Honeycomb", "Geodesic", "Lattice", "Dodecahedral", "Icosahedral", "Tubular", "Conical", "Spiral", "Helical", "Zigzag", "Wavy", "Scalloped", "Interlocking", "Meshed", "Paneled", "Ribbed"]
    },
    {name: 'Colour and Finish', value: '', order: 0, heading: 'Design and Appearance', simple:false, custom: false,
      dropDownOptions: ["Glossy Red", "Matte Black", "Metallic Silver", "Shimmering Gold", "Rustic Bronze", "Polished Mahogany", "Neon Green", "Antique Brass", "Plasma Blue", "Solar Orange", "Mystic Purple", "Starfield Black", "Iridescent Pearl", "Worn Leather Brown", "Lava Red", "Sun Yellow", "Translucent White", "Stone Gray", "Space Chrome", "Oil Slick Rainbow", "Frosted Crystal", "Nebula Purple", "Forest Green", "Metallic Rust", "Neon Pink", "Time-worn Brass", "Moonlight Silver", "Oceanic Turquoise", "Matte Gray", "Velvet Burgundy", "Lightning Blue", "Void Black", "Cosmic Rose Gold", "Desert Beige", "Iridescent Violet", "Vintage Cream", "Electric Lime", "Midnight Navy", "Titanium White", "Copper Patina", "Sapphire Blue", "Jade Green", "Quartz Pink", "Charcoal Black", "Amber Orange", "Sky Blue", "Emerald Green", "Pearl White", "Ruby Red", "Silver Frost"]
    },
    {name: 'Emblem and Markings', value: '', order: 0, heading: 'Design and Appearance', simple:false, custom: false,
      dropDownOptions: ["Eagle Emblem", "Bullet Holes", "Scratches", "Scorch Marks", "Rust Spots", "Dents", "Mud Splashes", "Paint Chips", "Graffiti Tags", "Burn Marks", "Racing Stripes", "Camouflage Patterns", "Neon Underglow", "Flame Decals", "Checkerboard Trim", "Polka Dots", "Barcode Markings", "Diagonal Hazard Stripes", "Splatter Paint", "Airbrushed Murals", "LED Light Patterns", "Reflective Tape Accents", "Pinstriping", "Ghost Flames", "Chrome Detailing", "Lightning Bolt Decals", "Star Emblem", "Rune Engravings", "Cross Emblem", "Etched Lines", "Crest Symbol", "Skull and Crossbones", "Glyph Emblem", "Blossom Decals", "Streaks", "Pirate Flag", "Indicator Markings", "Horus Eye Emblem", "Gear Patterns", "Circle Symbols", "Camouflage", "Helmet Emblem", "Crest", "Wings Emblem", "Circuit Patterns", "Rune Symbol", "Shield Emblem", "Star Emblem", "Cross", "Axe Emblem"]
    },
    {name: 'Aesthetic Style', value: '', order: 0, heading: 'Design and Appearance', simple:false, custom: false,
      dropDownOptions: ["Minimalist", "Gothic", "Art Deco", "Industrial", "Racing", "Luxury", "Urban", "Off-road", "Nautical", "Vintage", "Sporty", "Eco-friendly", "High-tech", "Bohemian", "Punk", "Oriental", "Tribal", "Romantic", "Brutalist", "Avant-Garde", "Abstract", "Monochrome", "Minimalistic Luxury", "Geometric", "Cubist", "Surrealist", "Eccentric", "Contemporary", "Psychedelic", "Exotic", "Tribal Fusion", "Noir", "Post-Industrial", "Boho Chic", "Pop Art", "Cyber Chic", "Vintage Glam", "Cyberwave", "Diesel", "Crystal"]
    },
    {name: 'Speed and Maneuverability', value: '', order: 0, heading: 'Performance and Capabilities', simple:true, custom: false,
      dropDownOptions: ["Fast and agile", "Slow and steady", "Quick and nimble", "Swift and precise", "Rapid and flexible", "Speedy and responsive", "Brisk and maneuverable", "Casual and adaptable", "Moderate and acrobatic", "High-speed and versatile", "Supersonic and agile", "Hypersonic and precise", "Ludicrous speed and nimble", "Warp speed and quick", "Lightspeed and tight", "Time-warping and acrobatic", "Slow and rigid", "Sluggish and unresponsive", "Hasty and erratic", "Cautious and hesitant", "Methodical and deliberate", "Plodding and stiff", "Unhurried and unwieldy", "Patient and ponderous", "Adaptable and quick", "Nimble and responsive", "Precise and maneuverable", "Flexible and versatile", "Tight and quick-turning", "Agile and rapid", "Quick and precise", "Rapid and maneuverable", "Responsive and versatile", "Nimble and tight", "Adaptable and agile", "Precise and quick-turning", "Versatile and maneuverable", "Tight and agile", "Quick and acrobatic", "Agile and versatile", "Responsive and quick-turning", "Nimble and acrobatic", "Precise and versatile", "Agile and maneuverable", "Quick and versatile", "Nimble and quick-turning", "Responsive and acrobatic", "Tight and maneuverable", "Versatile and quick-turning", "Agile and acrobatic"]
    },
    {name: 'Power Source and Fuel', value: '', order: 0, heading: 'Performance and Capabilities', simple:false, custom: false,
      dropDownOptions: ["Gasoline", "Diesel", "Natural gas", "Propane", "Hydrogen", "Electricity", "Coal", "Biomass", "Nuclear fission", "Nuclear fusion", "Solar power", "Wind power", "Geothermal energy", "Hydropower", "Biofuel", "Methane", "Jet fuel", "Kerosene", "Oil", "LPG (Liquefied Petroleum Gas)", "CNG (Compressed Natural Gas)", "Hydrogen fuel cell", "Lithium-ion battery", "Capacitors", "Thorium", "Methanol", "Ethanol", "Vegetable oil", "Coal-to-liquid (CTL)", "Synthesis gas (syngas)", "Steam power", "Alcohol fuel", "Liquid nitrogen", "Flywheel energy storage", "Radioisotope thermoelectric generator (RTG)", "Redox flow battery", "Natural gas hydrates", "Biogas", "Compressed air", "Biodiesel", "Butane", "Ethylene", "Ammonia", "Charcoal", "Wood", "Peat", "Synthetic diesel", "Hydrogen peroxide", "Methane clathrates", "Carbon nanotube supercapacitors"]
    },
    {name: 'Capacity and Storage', value: '', order: 0, heading: 'Performance and Capabilities', simple:false, custom: false,
      dropDownOptions: ["Spacious", "Roomy", "Ample", "Generous", "Abundant", "Expansive", "Capacious", "Vast", "Large", "Wide", "Plentiful", "Extensive", "Commodious", "Bountiful", "Enormous", "Grand", "Immense", "Huge", "Gigantic", "Oversized", "Tiny", "Compact", "Cozy", "Cramped", "Limited", "Restricted", "Narrow", "Minimal", "Small", "Petite", "Sufficient", "Adequate", "Moderate", "Average", "Standard", "Balanced", "Abundant", "Excessive", "Copious", "Sparse", "Insufficient", "Scant", "Barely enough", "Overflowing", "Overflow", "Teeming", "Packed", "Crowded", "Jam-packed", "Stuffed"]
    },
    {name: 'Terrain Adaptability', value: '', order: 0, heading: 'Performance and Capabilities', simple:false, custom: false,
      dropDownOptions: ["Off-road", "All-terrain", "Off-roading", "Mud-ready", "Trail-capable", "Rock-crawling", "Snow-worthy", "Sand-ready", "Water-crossing", "Swamp-capable", "Jungle-friendly", "Desert-ready", "Gravel-resistant", "Marsh-navigating", "Mountain-climbing", "Urban-friendly", "City-savvy", "Highway-ready", "Pavement-friendly", "Track-ready", "Race-capable", "Rough terrain", "Forest-adaptive", "Dune-traversing", "Arctic-ready", "Tundra-tackling", "Cliff-climbing", "Canyon-conquering", "River-crossing", "Urban jungle", "Off-grid", "Grassland-friendly", "Savanna-ready", "Bush-ready", "Swamp-venturing", "Wetland-capable", "Bog-traversing", "Sandy terrain", "Rocky landscape", "Hilly terrain", "Slope-friendly", "Marshy ground", "Woodland-ready", "Rainforest-capable", "Volcano-proof", "Ice-capable", "Sleet-resistant", "Creek-crossing", "Treetop-navigating", "Underground-capable"]
    },
    {name: 'Unique Features', value: '', order: 0, heading: 'Special Features', simple:true, custom: false,
      dropDownOptions: ["Energy shield", "Radar system", "Crane attachment", "Stealth mode", "Night vision", "Infrared sensors", "Autonomous driving", "Voice command interface", "Solar panels", "Hybrid engine", "Self-healing body", "Amphibious capability", "Jet propulsion", "Submersible mode", "Hovercraft functionality", "Vertical takeoff and landing (VTOL)", "360-degree camera system", "Augmented reality windshield", "Biometric authentication", "Ejection seat", "Convertible roof", "Modular seating", "All-wheel drive", "Four-wheel steering", "Adaptive suspension", "Heads-up display (HUD)", "Remote control operation", "Bulletproof windows", "Fire suppression system", "Magnetic levitation", "Holographic dashboard", "Retractable wings", "Underwater propulsion", "Pop-up headlights", "Self-parking feature", "Emergency parachute", "Off-road tires", "Electric charging station locator", "Bullet-resistant armor", "Auto-dimming rearview mirror", "Breathalyzer ignition lock", "Built-in refrigerator", "Air purifier", "Massage seats", "Bioluminescent exterior lighting", "Heads-up pedestrian warning", "Wi-Fi hotspot", "Rear-seat entertainment system", "Autonomous parking", "Remote start and climate control"]
    },
    {name: 'Modifications and Upgrades', value: '', order: 0, heading: 'Special Features', simple:false, custom: false,
      dropDownOptions: ["Custom paint job", "Tinted windows", "Body kit installation", "Lowered suspension", "Lifted suspension", "Performance exhaust system", "Engine tuning and remapping", "Turbocharger or supercharger installation", "Cold air intake", "High-performance brakes", "Upgraded suspension components", "Aftermarket wheels and tires", "Roll cage installation", "Racing seats", "Custom interior upholstery", "Steering wheel upgrade", "Sound system enhancement", "Subwoofer installation", "LED lighting upgrades", "HID or LED headlights", "Off-road bumpers and winch", "Roof rack and cargo accessories", "Winch installation", "Snorkel kit", "Skid plates", "Performance chip tuning", "Custom decals and graphics", "Spoiler or wing addition", "Bull bar installation", "Fender flares", "Lift kit", "Air suspension", "Roll bar or roll cage", "Hydraulic suspension", "Custom grille", "Differential locker", "Roof tent", "Tow hitch and trailer accessories", "Bed liner", "Winch bumper", "Auxiliary lighting", "Suspension leveling kit", "Performance brake rotors", "Performance brake pads", "Performance shocks and struts", "Performance sway bars", "Custom badges and emblems", "Window visors", "Body armor", "Custom steering wheel cover"]
    },
    {name: 'Maintenance and Durability', value: '', order: 0, heading: 'Special Features', simple:true, custom: false,
      dropDownOptions: ["Low-maintenance and sturdy", "Simple and durable", "Trouble-free and rugged", "Reliable and tough", "Easy and enduring", "Adaptable and hardy", "Complex and resilient", "High-maintenance and delicate", "Challenging and fragile", "Demanding and sensitive", "Intricate and rugged", "Finicky and adaptable", "Sensitive and well-constructed", "Complicated and versatile", "Delicate and well-designed", "Fragile and hardwearing", "High-maintenance and efficient", "Complex and dependable", "Demanding and adaptable", "Intricate and well-optimized", "Sensitive and low-maintenance", "Finicky and resilient", "Challenging and efficient", "Complicated and sturdy", "Delicate and long-lasting", "Fragile and adaptable", "High-maintenance and versatile", "Complex and well-engineered", "Demanding and well-optimized", "Intricate and trouble-free", "Low-maintenance and long-lasting", "Easy and rugged", "Trouble-free and tough", "Reliable and enduring", "Simple and hardy", "Adaptable and resilient", "Complex and sturdy", "High-maintenance and rugged", "Challenging and tough", "Demanding and hardy", "Intricate and rugged", "Finicky and tough", "Sensitive and hardy", "Complicated and hardy", "Delicate and rugged", "Fragile and hardy", "High-maintenance and rugged", "Complex and rugged", "Demanding and rugged", "Intricate and rugged"]
    },
    {name: 'Safety and Security Features', value: '', order: 0, heading: 'Special Features', simple:false, custom: false,
      dropDownOptions: ["Self-locking doors", "Biometric identification", "Dead man's switch", "Airbags", "Anti-lock braking system (ABS)", "Electronic stability control (ESC)", "Lane departure warning system", "Blind-spot monitoring", "Collision avoidance system", "Adaptive cruise control", "Traction control system (TCS)", "Emergency brake assist", "Tire pressure monitoring system (TPMS)", "Automatic emergency braking (AEB)", "Rearview camera", "Side-impact airbags", "Anti-theft alarm", "Keyless entry", "Child safety locks", "Vehicle tracking system", "Roll stability control (RSC)", "Lane-keeping assist", "Pedestrian detection system", "Forward collision warning", "Security window film", "GPS tracking", "Impact-absorbing steering column", "Central locking system", "Immobilizer", "Vehicle recovery system", "Electronic key fob", "Anti-hijack system", "Electronic door locks", "Remote engine immobilizer", "Fuel cutoff system", "Automatic door unlock in case of a collision", "Panic button", "Vehicle anti-rollback system", "Remote start system", "Anti-carjacking system", "Reinforced door hinges", "Emergency trunk release", "Backup camera", "Side curtain airbags", "Anti-theft tracking system", "Crash sensor system", "Biometric start system", "Vehicle stability assist (VSA)", "Impact-resistant bumpers", "Fire suppression system"]
    },
    
    {name: 'Additional Details', value: '', order: 0, heading: 'Background', custom: false},
  ];
