import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaRegComment, FaRegCommentDots, FaEllipsisV, FaTrash, FaEdit, FaImage,FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Import UUID to generate unique IDs for chats
import './Chat.css';
import ReactSelect from 'react-select';

const Chat = ({ genericMessage, setGenericMessage, toggleLoginModal , toggleUpsellVisible }) => {
  const chatHistoryLocal = JSON.parse(localStorage.getItem('chatHistory'));


  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messageRef = useRef();
  const sideNavRef = useRef(null);
  const [showChatDetails, setShowChatDetails ]  = useState(false);

  const [characterInfo, setCharacterInfo] = useState({});
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isUserAllowedToSend, setIsUserAllowedToSend] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);
  const [userNickname, setUserNickname] = useState('Unknown Stranger');
  const [chatContext, setChatContext] = useState('');
  const [chatLocation, setChatLocation] = useState({});
  const [chatHistory, setChatHistory] = useState([]);
  const [isModalOpen, setModalOpen] = useState(true);
  const [modalMode, setModalMode] = useState('');
  const [chatMode, setChatMode] = useState('Story');

  const [isEditing, setIsEditing] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [editMessageContent, setEditMessageContent] = useState("");

  const editMessageRef = useRef(null);
  const textareaRef = useRef(null);
  const [isMakingImage, setIsMakingImage] = useState(false);
  const [recentLocations, setRecentLocations] = useState([]);

  const validRecentLocations = Array.isArray(recentLocations) ? recentLocations : [];
   let selectOptions = validRecentLocations
        .filter(location => location.formValues && location.formValues.Name)
        .map(location => ({
            value: location._id,  // Use _id as the unique value
            label: location.formValues.Name,
            imageUrl: getBaseURL() + location.imageUrl
        }));
    selectOptions.unshift({ value: 'own', label: 'Enter location' });
    const ddHeight = 24;
    const customStyles = {
  option: (provided) => ({
    ...provided,
    padding: '6px 3px',
    textAlign: 'right',
    color: 'black',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow:'hidden'
  }),
  control: (provided) => ({
    ...provided,
    padding: '0px 0px',
    height:ddHeight,
    minHeight:ddHeight,
    linHeight : '24px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0px',   // Adjust the vertical padding here
    height: ddHeight,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: ddHeight,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0px',  // Adjust the padding to vertically center the dropdown arrow
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px',  // Adjust the padding for the clear icon as well
  }),
};

    // Custom option rendering to include the image
    const CustomOption = React.forwardRef(({ data, innerProps, getStyles, ...props }, ref) => (
    <div 
        {...innerProps} 
        ref={ref} 
        style={{
            ...getStyles('option', props),
            ...innerProps.style, 
            display: 'flex', 
            alignItems: 'center'
        }}
    >
        {data.imageUrl && <img src={data.imageUrl} alt={data.label} style={{ width: '30px', marginRight: '10px' }} />}
        {data.label}
    </div>
));

    
  const defaultOption = selectOptions.find(option => option.value === 'own');
  const [showChatLocation, setShowChatLocation] = useState(defaultOption.value === 'own');


  const handleGetRecentLocations = async () => {
      // You should create a URL object from the window.location.href string
      var url = new URL(window.location.href);
      var apiUrl = 'http://localhost:3001/characters_endpoint/';
      
      if (url.hostname === 'charactercomposer.com') {
        apiUrl = 'https://charactercomposer.com/characters_endpoint/';
      }
  
      const requestBody = {
        limit: 10,
        page: 1,
        orderBy: 'recent',
        modeKey:'Location',
      };

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-Key': "2(yY62.mG#6UI324324"
        },
        body: JSON.stringify(requestBody),
      });

      const result = await res.json();
      return result;
  };

  const updateChatHistory = (newChat) => {
    // Prepend newChat to the existing chatHistory
    setChatHistory(prevHistory => [newChat, ...prevHistory]);
  };

  function getBaseURL() {
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/saved/images/';
      if(url.indexOf('charactercomposer.com') > -1)
         apiUrl = 'https://charactercomposer.com/saved/images/';
    return apiUrl;
  }

  function isPlusMemberCheck(){
    var isPlusMember = localStorage.getItem('isPlusMember');
    if(isPlusMember == 'true')
      return true;
    else 
      return false;
  };


  useEffect(() => {
      const fetchData = async () => {
        const storedCharacterInfo = localStorage.getItem('currentChatCharacter') || localStorage.getItem('currentCharacter');
        if(recentLocations.length === 0){
          const locations = await handleGetRecentLocations();
          if (locations && Array.isArray(locations)) {
            setRecentLocations(locations);
          }
        }
      if (storedCharacterInfo) {
          const currentCharacter = JSON.parse(storedCharacterInfo);
          setCharacterInfo(currentCharacter);
          const chatHistoryLocal = JSON.parse(localStorage.getItem('chatHistory'));
          if(chatHistoryLocal){
            const existingChat = chatHistoryLocal.find(ch => ch.characterId === currentCharacter.id);
            if (existingChat) {
                setMessages(existingChat.messages);
                setChatLocation(JSON.parse(existingChat.chatLocation));
              setChatContext(existingChat.chatContext);
              setShowChatLocation(true);
                setModalOpen(false);  // Close the modal if chat history exists
            }
          }
          else{
            setChatLocation('');
            setChatContext('');
          }
      }
      else{
        setModalOpen(false);
      }
    };
  fetchData();
  }, []);


  useEffect(() => {
      if (!isModalOpen && characterInfo.attributes && messages.length === 0 && isUserAllowedToSend) {
          sendMessage(false, true);
      }
  }, [isModalOpen]);

  const startNewChat = () => {
    // Clear current messages in state
    setShowChatLocation(true);
      setChatContext('');
      setChatLocation('');
      setModalOpen(true);
      setMessages([]);
  
    // Optionally, send a new initial message from the bot
    //sendMessage(false, true);
  };

  const closeModal = (e) => {
    if(e)
    e.preventDefault(); // prevent default action of <a> tag
    setModalOpen(false);
    setModalMode('');
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation(); // this will stop the click event from propagating to the parent
  };


  useEffect(() => {
      const storedChatHistory = localStorage.getItem('chatHistory');
      if (storedChatHistory) {
          setChatHistory(JSON.parse(storedChatHistory));
      }
  }, []);

  useEffect(() => {
      if(newMessage.length > 400)
        setNewMessage(newMessage.substr(0,400));
  }, [newMessage]);


   useEffect(() => {
    if (messages.length > 0 && characterInfo && messages[0] !== 'new') {
      // Check if the messages array contains at least one message from the user
      const userHasSentMessage = messages.some(message => message.type === 'user');
      if (userHasSentMessage) {
        const currentChatIndex = chatHistory.findIndex(ch => ch.characterId === characterInfo.id);
        const parsedChatLocation = JSON.stringify(chatLocation);
        const chatData = {
          id: currentChatIndex === -1 ? uuidv4() : chatHistory[currentChatIndex].id,
          characterId: characterInfo.id,
          characterInfo,
          messages,
          chatMode,
          userNickname,
          chatContext,
          chatLocation: parsedChatLocation
        };
        let newChatHistory = [...chatHistory];
        // Remove existing chat from the array if it exists
        if (currentChatIndex !== -1) {
          newChatHistory.splice(currentChatIndex, 1);
        }
        // Add chat to the beginning of the array
        newChatHistory = [chatData, ...newChatHistory];
        setChatHistory(newChatHistory);
        localStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
      }
    }
    if (messages[0] === 'new') {
      setMessages([]);
    }
  }, [messages, characterInfo]);



  const loadChat = (chatId) => {
    const chatToLoad = chatHistory.find(ch => ch.id === chatId);
    if (chatToLoad) {
      for(let i = 0; i < chatToLoad.messages.length; i++){
        delete chatToLoad.messages[i].imageUrl;
      }
      setMessages(chatToLoad.messages);
      setCharacterInfo(chatToLoad.characterInfo);
      localStorage.setItem('currentChatCharacter', JSON.stringify(chatToLoad.characterInfo));
      if(chatToLoad.chatMode)
        setChatMode(chatToLoad.chatMode);
      if(chatToLoad.userNickname)
        setUserNickname(chatToLoad.userNickname);
      if(chatToLoad.chatContext)
        setChatContext(chatToLoad.chatContext);
      if(chatToLoad.chatLocation)
        setChatLocation(JSON.parse(chatToLoad.chatLocation));
      setIsFocused(false);
      toggleSideNav();
    }
  };

  const toggleSideNav = () => {
    setIsSideNavVisible(!isSideNavVisible);
  };

  const handleNicknameChange = (e) => {
    const newNickname = e.target.value;
    setUserNickname(newNickname);
    localStorage.setItem('userNickname', newNickname);
  };

  const handleChatContextChange = (e) => {
    const newContext = e.target.value;
    setChatContext(newContext);
    localStorage.setItem('chatContext', newContext);
  };

  const handleChatLocationChange = (e) => {
    const newLocation = e.target.value;
    setChatLocation({"formValues":{"Name": newLocation}});
    localStorage.setItem('chatLocation', JSON.stringify({"formValues":{"Name": newLocation}}));
  };

  useEffect(() => {
    const storedNickname = localStorage.getItem('userNickname');
    if (storedNickname) {
      setUserNickname(storedNickname);
    }

    const storedChatMode = localStorage.getItem('chatMode');
    if (storedChatMode) {
      setChatMode(storedChatMode);
    }
  }, []);



  useEffect(() => {
    document.documentElement.classList.add('chat-page');

    return () => {
      document.documentElement.classList.remove('chat-page');
    };
  }, []);





  useEffect(() => {
    if(messageRef?.current?.scrollHeight)
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
  }, [messages]);

  const deleteChatHistory = (indexToDelete) => {
    if(window.confirm("Are you sure you want to delete this chat history?")){
      const updatedChatHistory = chatHistory.filter((_, index) => index !== indexToDelete);
      setChatHistory(updatedChatHistory);
      localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistory));
    }
  };

  const handleChatModeChange = (mode) => {
    setChatMode(mode);
  };

  const removeQuotes = (str) => {
      if (str.startsWith('"') && str.endsWith('"')) {
        return str.slice(1, -1);
      }
      return str;
    };

  const makeConversationImage = async (e) => {

    const token = localStorage.getItem('token');
    const isPlusMember = isPlusMemberCheck();
    if(isPlusMember !== true){
      //if(token){
      //  toggleUpsellVisible();
      //}
      setGenericMessage('You must be a Plus member to generate conversation images');
      toggleLoginModal();
      return false;
    }
    // Fetch bot response from server
    setIsMakingImage(true);
    setTimeout(() => {
      if(messageRef?.current?.scrollHeight)
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
    },10);
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/image/';
    if (url.indexOf('charactercomposer.com') > -1) {
      apiUrl = 'https://charactercomposer.com/image/';
    }

    await axios.post(apiUrl, 
    {
      conversationImage: true, 
      message: messages[messages.length - 1].text, 
      character: JSON.stringify(characterInfo),
      userNickname, 
      chatMode,
      chatContext,
      chatLocation
    }, 
    {
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      }
    })
    .then(response => {
      const imageUrl = response.data.image; // Assuming response.data contains the image URL
      const updatedMessages = [...messages];
      updatedMessages[updatedMessages.length - 1].imageUrl = imageUrl;
      setMessages(updatedMessages);
    })
    .catch(error => {
      console.error('There was an error fetching the bot response:', error);
    });
    setIsMakingImage(false);
  }

  const sendMessage = async (e = false,firstLoad = false, editedMessages = false) => {
    if(e){
      e.preventDefault();
    }
    if (firstLoad == false && (newMessage.trim() === "" || !isUserAllowedToSend)) return;
    setTimeout(() => {window.scrollTo(0, 0);},50);
    
    setIsUserAllowedToSend(false);



     // Create a local variable containing all the messages + the new one from the user
    let allMessages = [...messages, { text: newMessage, type: "user" }];
    if(firstLoad)
      allMessages = messages;
    if(editedMessages)
      allMessages = editedMessages;

    // Add user's message to state
    await setTimeout(() => {
      setMessages(allMessages);
    }, 400);
    

    // Calculate random delay
    const randomDelay = Math.floor(Math.random() * 1601) + 200;

    await setTimeout(() => {
      setIsBotTyping(true);
    }, randomDelay);

    // Fetch bot response from server
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/chat-bot/';
    if (url.indexOf('charactercomposer.com') > -1) {
      apiUrl = 'https://charactercomposer.com/chat-bot/';
    }
    const token = localStorage.getItem('token');
    const isPlusMember = isPlusMemberCheck();
    setTimeout(() => {
    axios.post(apiUrl, { messages: allMessages, character: JSON.stringify(characterInfo), userNickname, chatMode, chatContext, chatLocation }
      ,{
      headers: {
        'Content-Type': 'application/json',
        'API-Key': "2(yY62.mG#6UI324324",
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
         ...(isPlusMember ? { 'plus-member': `true` } : {})
      }})
      .then(response => {
        const botResponse = response.data.text;
        const cleanedBotResponse = removeQuotes(botResponse);
        setMessages(prevMessages => [...prevMessages, { text: cleanedBotResponse, type: "bot" }]);
        setIsBotTyping(false);
        setIsUserAllowedToSend(true);
      })
      .catch(error => {
        console.log(error.response.status);
        if (error.response.status === 429) {
          setGenericMessage('Token limit reached');
          toggleLoginModal();
          return;
        }
        console.error('There was an error fetching the bot response:', error);
    });
    }, randomDelay + 500);

    setNewMessage("");

    await setTimeout(() => {
    if (textareaRef.current) {
        autoResize({ target: textareaRef.current }, true);
        if(window.innerWidth < 700){
          textareaRef.current.blur();
          setIsFocused(false);
      }
    }
    }, 50);
  };

  useEffect(() => {
    setTimeout(() => {
    if(messageRef?.current?.scrollHeight)
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
  },110);
    if (isFocused) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isFocused]);

  useEffect(() => {
    localStorage.setItem('chatMode', chatMode);
  }, [chatMode]);

  useEffect(() => {
    if(userNickname !== 'Unknown Stranger')
      localStorage.setItem('userNickname', userNickname);
  }, [userNickname]);


  useEffect(() => {
    if (isEditing && editMessageRef.current) {
      editMessageRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    // Function to check if the click was outside the side navigation
    function handleClickOutside(event) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
        setIsSideNavVisible(false);
      }
    }

    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  // Empty dependency array means this useEffect runs once on mount and cleanup runs on unmount


  function setCursorToEnd(textarea) {
      const valueLength = textarea.value.length;
      textarea.setSelectionRange(valueLength, valueLength);
  }

  const handleTextareaFocus = (event) => {
      setCursorToEnd(event.target);
  }

  const autoResize = (e, blur = false) => {
    if (window.innerWidth < 600) {
      e.target.style.height = 'inherit'; // Reset the height to auto
      const computed = window.getComputedStyle(e.target); // Get the computed styles of the textarea
      const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                     + parseInt(computed.getPropertyValue('border-bottom-width'), 10)
                     + e.target.scrollHeight - 12;

      e.target.style.height = `${height}px`; // Set the height based on content

      let heightDifference = 525 + (height - 40);
      if (messageRef.current) {
          if(blur)
            messageRef.current.style.maxHeight = `calc(100vh - 48px)`;
          else
            messageRef.current.style.maxHeight = `calc(100vh - ${heightDifference}px)`;
      }
    }
  };

const toggleShowDetails = async () => {
    setShowChatDetails(!showChatDetails);
  };


 const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === 'own') {
        setChatLocation({});
        setShowChatLocation(true);
    } else {
        setShowChatLocation(false);
        // Find the location object from recentLocations that matches the selected option's _id
        const matchingLocation = validRecentLocations.find(location => location._id === selectedOption.value);
        setChatLocation(matchingLocation);
        localStorage.setItem('chatLocation', JSON.stringify(matchingLocation));
    }
};



  return (
  <>
    <div className="section dashboard">
      <div className="center-text">
        <div className={`chat-container ${isSideNavVisible ? 'darken':''}`}>
          <div className="chat-header">
            <div className="chat-name">
              {characterInfo?.attributes?.Name}
            </div>
            {isBotTyping ? <FaRegCommentDots /> : <FaRegComment />}
            <button className="startNewChat" onClick={startNewChat}>New Chat</button>
            <FaEllipsisV  onClick={toggleSideNav} className="menu-button"/>
          </div>
          <div className={`chat-history ${isFocused ? 'focusedMobile' : ''}`} ref={messageRef}>
            {chatLocation && chatLocation.formValues && chatLocation.formValues.Name && (
              <div className="locationMessage">
                Chat location<br/><b>{chatLocation.formValues.Name}</b>
                {chatLocation && chatLocation.formValues && chatLocation.imageUrl && (
                  <a href={`/characters?id=${chatLocation._id}`} target="_blank">
                    <img className="locationImage" src={getBaseURL() + chatLocation.imageUrl} alt="Location" />
                  
                  </a>
                )}
              </div>
            )}
            {messages.map((message, index) => (
              <div key={index} className={message.type}>
                {message.type === 'bot' && <img src={characterInfo.image} alt="Bot" />}
                <div>
                  <h4>
                    {message.type === 'bot' && characterInfo.attributes.Name}
                    {message.type === 'user' && userNickname}
                  </h4>
                  <div className="edit-container">
                  {isEditing && currentEditIndex === index ? (
                    <textarea 
                      ref={editMessageRef}
                      className="inline-edit"
                      value={editMessageContent} 
                      onChange={(e) => setEditMessageContent(e.target.value)} 
                      onFocus={handleTextareaFocus}
                    />

                  ) : (
                    message.text
                  )}
                  {!isEditing &&
                    <>
                     <FaEdit className="editIcon" onClick={() => {
                        setIsEditing(true);
                        setCurrentEditIndex(index);
                        setEditMessageContent(message.text);

                      }}/>
                      {index === messages.length - 1 && isMakingImage === false && message.type === 'bot' && !message.imageUrl && <FaImage className="imageIcon" onClick={makeConversationImage}/>}
                      {index === messages.length - 1 && isMakingImage && message.type === 'bot' && <span className="generating-text">Generating image...</span>}
                    </>
                    }
                      {message.imageUrl && <img className="conversationInlineImage" src={message.imageUrl} alt="Conversation" />}
                  {isEditing && currentEditIndex === index && (
                    <>
                      <button  className="cancel-edit" onClick={() => {
                        setIsEditing(false);
                      }}>
                        cancel
                      </button>
                      <button className="save-edit" onClick={() => {
                        const updatedMessages = [...messages];
                        updatedMessages[index].text = editMessageContent;
                        setMessages(updatedMessages);
                        setIsEditing(false);
                        setCurrentEditIndex(null);
                        setEditMessageContent("");
                        // Remove all messages after the edited one and get new bot response
                        const truncatedMessages = messages.slice(0, index + 1);
                        setMessages(truncatedMessages);
                        if(message.type === 'user')
                          sendMessage(false, true, truncatedMessages);

                      }}>
                        Save
                      </button>
                    </>
                  )}
                  </div>
                </div>
                {message.type === 'user' && <div className="dpCircle"/>}
                  
              </div>
            ))}
          </div>
          <div className="chat-footer">
            {isBotTyping && characterInfo.attributes && <div className="typing-indicator">{characterInfo?.attributes?.Name} is typing...</div>}
            <div className="message-input">
              <textarea
                ref={textareaRef}
                type="text"
                value={newMessage}
                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                    autoResize(e);
                                  }}
                placeholder="Type a message"
                onFocus={(e) => {
                  e.preventDefault();
                  setIsFocused(true);
                  setIsEditing(false);
                  
                  setTimeout(() => {
                    autoResize(e);
                    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
                  }, 100);
                }}
                onBlur={(e) => {
                  e.preventDefault();
                  
                  setTimeout(() => {
                    setIsFocused(false);
                    autoResize(e, true);
                    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
                  }, 100);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
              />
              <button onClick={(e) => sendMessage(e)}>
                <svg stroke="currentColor" fill="currentColor"  viewBox="0 0 24 24" ><path fill="none" d="M0 0h24v24H0z"></path><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path></svg>
              </button>
            </div>
          </div>
        </div>
        {(isSideNavVisible || !characterInfo.attributes) && (
          <div className="side-nav" ref={sideNavRef}>
            <button onClick={toggleSideNav} className="close-button">Close</button>
            <h3>Previous Chats</h3>
            {chatHistory.length < 1 &&
              <>
                <span>No chats found</span>
                <a className="choose-char-chat" href="/characters">Choose a character to chat with</a>
              </>
            }
            <ul>
              {chatHistory.map((chat,index) => (
                <li key={chat.id} >
                  <div onClick={() => loadChat(chat.id)}>
                    <img src={chat.characterInfo.image}/>
                    {chat.characterInfo.attributes.Name}
                  </div>
                  <button onClick={() => deleteChatHistory(index)}><FaTrash/></button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
    {isModalOpen && (
      <div className="add-more-modal show" onClick={closeModal}>
         <div className="add-more-modal-content" onClick={handleModalContentClick}>
          <a className="outputModalCloseButton" onClick={closeModal} href="">close</a>
          {modalMode == '' && (
            <>
              <h3>Chat with {characterInfo && characterInfo?.attributes?.Name}</h3>
              <h5>Who are you?</h5>
              <input type="text" value={userNickname} onChange={handleNicknameChange} />
              <h5>Interaction Mode</h5>
              <div className="chat-mode-buttons">
                {['Chat', 'Story', 'Quest'].map(mode => (
                  <button 
                    key={mode}
                    onClick={() => handleChatModeChange(mode)}
                    className={`chat-mode-button ${chatMode === mode ? 'highlighted' : ''}`}
                  >
                    {mode}
                  </button>
                ))}
              </div>
              <button className="chatDetailToggle" onClick={toggleShowDetails}>
                details{showChatDetails ? <FaAngleUp/>:<FaAngleDown/>}  
              </button>
              {showChatDetails && 
                  <div className="detailedChatContainer">
                      <h5>Backstory</h5>
                      <textarea 
                        className="chatContext"
                         value={chatContext}
                          onChange={handleChatContextChange}
                           placeholder="e.g rivals from a chess tournament..."
                      />
                      <h5>Location</h5>
                      <ReactSelect 
                          className="reactSelect"
                          options={selectOptions}
                          components={{ Option: CustomOption }}
                          onChange={handleSelectChange}
                          defaultValue={defaultOption}
                          styles={customStyles}
                      />
                      {showChatLocation && 
                        <textarea 
                            className="chatLocation"  
                            onChange={handleChatLocationChange} 
                            value={chatLocation.formValues?.Name || ''}
                            placeholder="e.g a warm and welcoming tavern"
                        />
                    }

                  </div>
              }
              <button className="dismissButton" onClick={closeModal} href="">Chat with {characterInfo && characterInfo?.attributes?.Name}</button>
            </>
          )}
        </div>
      </div>
      )}
    </>
  );
};

export default Chat;
