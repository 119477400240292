import React from 'react';
import { FaShare, FaHeart, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import styles from '../Sections/Characters.module.css';
import ImageComponent from './ImageComponent.js';
import TagsDisplay from './TagsDisplay';

const CardComponent = ({
  card,
  recentCreationsMode,
  likedCards,
  revealFullHeight,
  singleView,
  onCopy,
  onDelete,
  onEdit,
  onToggleLike,
  onChat,
  onRevealFullHeight,
  getModeTitle,
  cleanAttributes,
  AttributeList, // If AttributeList is a component, it should be passed as a prop or imported directly
}) => (
  <div key={card.id} className={`${styles.card} ${singleView ? styles.inModal : ''}`}>
    {/* Share button */}
    <span
      id="copyButton"
      className={recentCreationsMode ? "" : ""}
      onClick={() => onCopy(card.shareUrl)}
    >
      <FaShare />
    </span>

    {/* Conditional rendering based on username */}
    {localStorage.username === 'wpauserQ5eJB2e4' && (
      <p>User ID: {card.userId}</p>
    )}

    {/* Edit and Delete buttons for non-recent creations */}
    {!recentCreationsMode && (
      <>
        <button className={styles.deleteButton} onClick={() => onDelete(card.id)}>
          Delete
        </button>
        { getModeTitle(card) !== 'Life Path' &&
        <button className={styles.editButton} onClick={() => onEdit(card)}>
          Edit
        </button>
        }
      </>
    )}

    {/* Like section for recent creations */}
    {recentCreationsMode && (
      <div className={`likesContainer ${likedCards.includes(card.id) ? 'liked' : ''}`}>
        <span>{card.upVotes}</span> 
        <FaHeart onClick={() => onToggleLike(card)} /> 
      </div>
    )}

    {/* Card Title */}
    <h3>{card.title}</h3>

    {/* Character Image and Mode Title */}
    <div className="character-image-container2" onClick={() => onRevealFullHeight(card)}>
      <ImageComponent imageUrl={card.image} exspanded={singleView}/> 
      {getModeTitle(card) && (
        <span className="modeTitle">{getModeTitle(card)}</span>
      )}
    </div>

    {/* Chat Button */}
    <button
      className="chatButton"
      onClick={() => onChat(card)}
    >
      Chat with {card.title}
    </button>

    {/* Reveal Full Height Toggle */}
    {!singleView && (
      <button
        className={styles.toggleHeightButton}
        onClick={() => onRevealFullHeight(card)}
      >
        {revealFullHeight[card.id] ? <FaAngleUp /> : <FaAngleDown />}
      </button>
    )}

    {/* Card Content */}
    <div
      className={`${styles.cardContent} ${revealFullHeight[card.id] || singleView ? styles.fullHeight : ''}`}
    >
    {card.tags && <div className="displayTags"><TagsDisplay tags={card.tags}/></div>}
      <div dangerouslySetInnerHTML={{ __html: card.html }} />

      {card.attributes && (
        <AttributeList attributes={cleanAttributes(card.attributes)} header={false} />
      )}
    </div>

    {/* Conditional Edit Button */}
    {revealFullHeight[card.id] && !recentCreationsMode && (
      <button className={styles.editButtonBottom} onClick={() => onEdit(card)}>
        Edit
      </button>
    )}
  </div>
);

export default CardComponent;
